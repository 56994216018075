import {
  ADD_GOOD
} from '../constants/Goods'

const stateNewsGoods = [
  {
    name: 'Alba',
    price: '1390',
    img: '/img/png/img1.png',
    type: 'флок',
    status: 'news'
  },
  {
    name: 'Alba',
    price: '1390',
    img: '/img/png/img2.png',
    type: 'флок',
    status: 'news'
  },
  {
    name: 'Alba',
    price: '1390',
    img: '/img/png/img3.png',
    type: 'флок',
    status: 'news'
  },
  {
    name: 'Alba',
    price: '1390',
    img: '/img/png/img4.png',
    type: 'флок',
    status: 'news'
  },
  {
    name: 'Alba',
    price: '1390',
    image: '/img/png/img5.png',
    type: 'флок',
    status: 'news'
  }
]
export default function newsGoods (state = stateNewsGoods, action) {
  if (action.type === ADD_GOOD) {
    return [
      ...state,
      action.payload
    ];
  }
  return state;
}
