import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Row, Col } from "reactstrap";

// список резинок
const elastic = [
  {
    name: "Y 140",
    lineCount: 4,
    shir: "50 мм",
    dlina: "100 м",
    ras: "40%",
    color: "Зелёный",
  },
  {
    name: "S 250",
    lineCount: 2,
    shir: "50 мм",
    dlina: "100 м",
    ras: "60%",
    color: "Синий",
  },
  {
    name: "S 650",
    lineCount: 3,
    shir: "60 мм",
    dlina: "100 м",
    ras: "50%",
    color: "Синий",
  },
  {
    name: "S 850",
    lineCount: 3,
    shir: "80 мм",
    dlina: "100 м",
    ras: "50%",
    color: "Синий",
  },
  {
    name: "S 850",
    lineCount: 3,
    shir: "80 мм",
    dlina: "100 м",
    ras: "50%",
    color: "Синий",
  },
  {
    name: "BS 208",
    color: "Синий",
    shir: "80 мм",
    lineCount: 3,
    ras: "50%",
    dlina: "100 м",
  },
  {
    name: "BLS 840",
    color: "Чёрный",
    shir: "80 мм",
    lineCount: 1,
    ras: "20%",
    dlina: "100 м",
  },
  {
    name: "BS 1015",
    color: "Чёрный",
    shir: "50 мм",
    lineCount: 2,
    ras: "60%",
    dlina: "100 м",
  },
  {
    name: "YS 140",
    color: "Зелёный",
    shir: "50 мм",
    lineCount: 4,
    ras: "20%",
    dlina: "100 м",
  },
  {
    name: "BLC 540",
    color: "Чёрный",
    shir: "50 мм",
    lineCount: 1,
    ras: "5%",
    dlina: "100 м",
  },
];

class Elastic extends Component {
  render() {
    return (
      <div className="thread-page">
        <Helmet>
          <title>
            Купить резинки для мебели, каталог с ценами - компания "Вектор"
          </title>
          <meta
            name="description"
            content="Резинки для мебели от компании Вектор — лучшие цены и именитые производители. Опт и доставка по России."
          />
          <link rel="canonical" href="https://vektor.club/goods/elastic" />
        </Helmet>

        <Row className="acce-page-top">
          <Col xs="4" className="acce-page-top-img">
            <img src="/img/jpg/elastic-inner.jpg" alt="" />
          </Col>
          <Col xs="8" className="aces-page-top-text">
            <div className="aces-page-top-title">
              <h1 className="h1">Резинка</h1>
            </div>
            <div className="aces-page-top-table">
              <table>
                <thead>
                  <tr>
                    <td>Наименование</td>
                    <td>Цвет</td>
                    <td>Ширина</td>
                    <td>Растяжение</td>
                    <td>Кол-во полос</td>
                    <td>Длина</td>
                  </tr>
                </thead>
                <tbody>
                  {elastic.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td>{item.color}</td>
                        <td>{item.shir}</td>
                        <td>{item.ras}</td>
                        <td>{item.lineCount}</td>
                        <td>{item.dlina}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        <div className="acce-page-bottom">
          {/*<h1 className="h1"/>*/}
          <div className="acce-page-color opora" />
        </div>
      </div>
    );
  }
}

export default Elastic;
