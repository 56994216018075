import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { connect } from 'react-redux'
import { browserHistory, Link } from 'react-router'
import Swiper from 'react-id-swiper'
import Item from '../../containers/GoodItem/Item'
import { statusSearch, addGlobalNewsGood } from '../../actions/filter/filter'
// import Loading from 'react-loading'
import Scroll from 'react-scroll'
import remoteServer from '../../remoteServer'
import { Helmet } from 'react-helmet'
import sofa from './img/jpg/sofa.png'

const nbsp = '\u00A0'
const slides = ['img/jpg/slider1_new.jpg', 'img/jpg/slider2_new.jpg']
// const sofa = "./img/jpg/sofa.png"

class Home extends Component {
   constructor(props) {
      super(props)
      this.state = {
         title: `обивочные материалы и${nbsp}комплектующие для${nbsp}производства мягкой${nbsp}мебели`,
         activeSlide: 0,
         timer: 0,
      }
      this.arrowHover = this.arrowHover.bind(this)
      // this.arrow
   }

   // автоматическое перелистывание слайда, получение данных инициализации с сервера
   componentDidMount() {
      this.arrow = document.querySelector('.index-scroll')
      this.arrow.addEventListener('mouseover', this.arrowHover)

      const timer = setInterval(() => {
         //next slide
         this.setState({
            activeSlide: (this.state.activeSlide + 1) % slides.length,
         })
      }, 7000)
      this.setState({ timer })

      remoteServer.get('/news').then(({ data }) => this.props.addNews(data.data))

      remoteServer.get('/articles').then(({ data }) => this.props.addPost(data.data))

      remoteServer.get('/get_new_collections/limit/10').then(({ data }) => this.props.addGlobalNewsGood(data))
   }

   componentWillUnmount() {
      // Clear the interval right before component unmount
      clearInterval(this.state.timer)
   }

   // скролл страницы на конкретную позицию (новые коллекции)
   scrollPage() {
      Scroll.animateScroll.scrollTo(window.outerHeight - 200)
   }

   showDesc() {
      let descBody = document.querySelector('.grow')

      if (descBody.clientHeight) {
         descBody.style.height = 0
      } else {
         let wrapper = document.querySelector('.index-text-body')

         descBody.style.height = wrapper.clientHeight + 'px'
      }
   }

   arrowHover(e) {
      const self = this
      this.arrow.removeEventListener('mouseover', this.arrowHover)
      this.arrow.classList.add('hovered')
      setTimeout(function () {
         self.arrow.addEventListener('mouseover', self.arrowHover)
         self.arrow.classList.remove('hovered')
      }, 400)
   }

   render() {
      // скрывание/отображение элемента при позиции скролла на самом верху и при скролле вниз соответственно
      window.addEventListener('scroll', (event) => {
         let a = document.querySelector('.index-scroll')
         if (a) a.classList.toggle('hidden', window.scrollY > 50)
      })

      // конфиг для модуля слайдера
      const indexSlider = {
         paginationClickable: true,
         slidesPerView: 4,
         spaceBetween: 0,
         breakpoints: {
            2600: {
               slidesPerView: 4,
               spaceBetween: 0,
            },
            1570: {
               slidesPerView: 3,
               spaceBetween: 0,
            },
            1180: {
               slidesPerView: 3,
               spaceBetween: 0,
            },
            768: {
               slidesPerView: 2,
               spaceBetween: 0,
            },
            500: {
               slidesPerView: 1,
               spaceBetween: 10,
            },
         },
      }

      // равнозначно: const wrapStore = this.props.wrapStore
      const { wrapStore } = this.props

      // (!) данные подтягиваются с нескольких таблиц, при проверке только по id возможны коллизии
      // маппинг коллекций для получения массива их id
      let idArr = []
      this.props.wrapStore.favorites.collections.map((el) => {
         return idArr.push(`${el.type}_${el.id}`)
      })

      return (
         // врапер позиционируется от блока news-goods
         <div className='index-wrap'>
            <Helmet>
               <title>Купить мебельные ткани оптом и в розницу — компания "Вектор"</title>
               <meta
                  name='description'
                  content='Продажа обивки и комплектующих для производства мягкой мебели. Все комплектующие мировых производителей, всегда в наличии - компания «Вектор»'
               />
               <meta name='yandex-verification' content='3a1b320f6828d56d' />
               <link rel='canonical' href='https://vektor.club/' />
            </Helmet>

            {/*position: absolute, слайдер вверху (статика) */}
            <div className='myslider-container'>
               {slides.map((srcSlideImage, index) => (
                  <div
                     key={index}
                     className={`myslider-item ${index === this.state.activeSlide ? ' active' : ''}`}
                     style={{ backgroundImage: `url(${srcSlideImage})` }}
                  />
               ))}
               <div className='myslider-shadow'></div>
               <div className='myslider-text'>
                  <Container>
                     <Row>
                        <Col xs='3' className='col-xs-1-5'></Col>
                        <Col xs='9' className='col-xs-1-4'>
                           <p className='slide-title'>{this.state.title}</p>
                        </Col>
                     </Row>
                  </Container>
               </div>
            </div>

            {/*scroll до товаров, hidden, когда листаешь вниз */}
            <div className='index-scroll'>
               <div className='arrow-scroll'></div>
               <p onClick={this.scrollPage.bind(this)}>
                  новые
                  <br />
                  коллекции
               </p>
            </div>

            {/*Goods - плитки товаров (Item) из бд */}
            <div className='news-goods margin-after-slider'>
               <Row className='news-goods-title'>
                  <Col xs='6' className='news-goods-title-p'>
                     <p>Новинки</p>
                  </Col>
                  <Col xs='6' className='news-goods-title-des'>
                     <p>Представляем новые технологии и модные тенденции из мира мягкой мебели.</p>
                  </Col>
               </Row>
               <div className='news-goods-wrap'>
                  {wrapStore.globalNews.length === 0 ? (
                     <div className='loader' />
                  ) : (
                     <Swiper {...indexSlider}>
                        {wrapStore.globalNews.map((item) => (
                           <div className='item' key={`${item.type}_${item.id}`}>
                              <Item
                                 item={item}
                                 favorite={idArr.indexOf(`${item.type}_${item.id}`) !== -1}
                                 {...this.props}
                              />
                           </div>
                        ))}
                     </Swiper>
                  )}
               </div>
            </div>

            {/*Мы - Вектор и Выезжающий диван (статика)*/}
            <div className='index-text'>
               <div className='index-text-wr'>
                  <div className='index-text-title'>
                     <h2>
                        <Link to='/about'>Мы — «Вектор»</Link>
                     </h2>
                  </div>
                  <div className='index-text-head'>
                     <p className='p1'>
                        Ищете надёжного поставщика мебельных тканей и комплектующих для производства мебели? - вы попали
                        по адресу!
                     </p>
                     <p>
                        Компания «Вектор» имеет 10-летний опыт работы на рынке обивочных мебельных материалов. Здесь
                        трудятся профессионалы, люди, по-настоящему увлечённые своим делом. Мы постоянно держим руку на
                        пульсе последних изменений в мебельной индустрии, следим за всеми модными тенденциями и
                        стараемся соответствовать вкусам самых взыскательных клиентов.
                     </p>
                  </div>
               </div>
               <div className='index-text-pich'></div>
            </div>
            <div className='sub-wrap mt-46'>
               <div className='wrap'>
                  <Link to='/goods/3d'>
                     <h2>
                        Попробуйте <span style={{ color: '#d12132' }}>3D&#8211;конструктор</span> онлайн
                     </h2>
                     <div className='sofa-wrapper'>
                        <img src={sofa} alt='sofa' className='sofa' />
                     </div>
                  </Link>
               </div>
               <div className='wrap second'>
                  <h2>Почему «Вектор» — правильный выбор:</h2>
                  <p>
                     <span className='indented'>• большой ассортимент мебельных обивочных тканей;</span>
                     <span className='indented'>• гибкая ценовая политика;</span>
                     <span className='indented'>• индивидуальный подход к каждому клиенту;</span>
                     <span className='indented'>
                        • обслуживание «под ключ»: всё, необходимое для производства мебели можно приобрести в одном
                        месте;
                     </span>
                     <span className='indented'>
                        • удобная логистика: сеть филиалов, наличие собственных складов, транспортная доступность!
                     </span>
                  </p>
                  <p>
                     Соблюдение сроков исполнения договоров и сроков поставки — главный принцип работы компании
                     «Вектор».{' '}
                     <span className='bold' onClick={this.showDesc}>
                        Читать далее
                     </span>
                  </p>
               </div>
            </div>
            <div className='sub-wrap'>
               <div className='index-text-wr inner-wrapper'>
                  <div className='grow mb-20-mobile'>
                     <div className='index-text-body flex'>
                        <div className='index-text-body inner-sub-wrapper'>
                           <h2>Большой выбор мебельных тканей</h2>
                           <p>
                              В компании «Вектор» можно заказать ткани из натуральной и искусственной кожи. В каталоге
                              вы также найдёте широкий выбор таких тканей как: велюр, вельвет, рогожка, искусственная
                              замша, жаккард. Ткани — дышащие, высокого качества, устойчивы к истиранию, не требуют
                              подбора при выкройке. В наличии богатая колор карта. Ткани из разных коллекций гармонично
                              сочетаются друг с другом.
                           </p>
                        </div>
                        <div className='index-text-body inner-sub-wrapper'>
                           <h2>Удобный каталог обивочных материалов</h2>
                           <p>
                              Коллекции тканей в ассортименте компании «Вектор» регулярно пополняются. К услугам
                              заказчика удобный каталог с возможностью сортировки товара по цене, цвету и типу тканей.
                              Прямо сейчас каждый желающий может подобрать и купить оптом со склада необходимый ему
                              товар. Цены приятно удивят: ведь мы покупаем ткани непосредственно у производителя, это
                              гарантия минимальной торговой наценки.
                           </p>
                        </div>
                        <div className='index-text-body inner-sub-wrapper'>
                           <h3>Высокое качество</h3>
                           <p>
                              Приобретая ткани и комплектующие для производства мебели в компании «Вектор», вы можете
                              быть уверены в высоком качестве продукции. Наши специалисты лично проверяют все товары,
                              поступающие с фабрик, поэтому мы гарантируем соответствие приобретаемой у нас продукции
                              стандартам качества.
                           </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {/*??? empty*/}
            <div className='clearfix'></div>
            {/*Всё о тканях, делимся опытом, Новости - всё из бд*/}
            <div className='posts'>
               <Row>
                  <Col xs='6'>
                     <div className='title-block'>
                        <p>
                           <Link to='/news'>Новости</Link>
                        </p>
                     </div>
                     <div className='post-wr'>
                        {wrapStore.news.map((item, index) => {
                           return (
                              <div key={index} className='post'>
                                 <p className='post-data'>{item.attributes.date}</p>
                                 <Link to={'/news/' + item.id} className='post-name'>
                                    {item.attributes.title}
                                 </Link>
                                 <p className='post-des'>{item.attributes.caption}</p>
                              </div>
                           )
                        })}
                     </div>
                  </Col>
               </Row>
            </div>
         </div>
      )
   }
}

export default connect(
   (state, ownProps) => ({
      wrapStore: state,
      browserHistory,
   }),
   (dispatch) => ({
      addPost: (item) => {
         dispatch({ type: 'ADD_POST', payload: item })
      },
      addNews: (item) => {
         dispatch({ type: 'ADD_NEWS', payload: item })
      },
      addGlobalNewsGood: (good) => {
         dispatch(addGlobalNewsGood(good))
      },
      statusFound: (value) => {
         dispatch(statusSearch(value))
      },
   })
)(Home)
