import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Row, Col } from "reactstrap";

// наименование цвета (от заказчика) и его цветовой код
const color = [
  // {name: '97ATT', color: '000'},
  // {name: '0259F', color: 'fff'},
  // {name: '0239F', color: '740202'},
  // {name: '0529F', color: '9b2904'},
  // {name: '710MJ', color: '9c3c02'},
  // {name: '08989', color: '382308'},
  // {name: '0245F', color: '49270e'},
  // {name: '290MJ', color: '51210d'},
  // {name: '445MJ', color: '233c15'},
  // {name: '05138', color: '3e8f4a'},
  // {name: '0521F', color: '2d2d13'},
  // {name: '05767', color: '454521'},
  // {name: '0213F', color: '6a572d'},
  // {name: '240MJ', color: '7a6e5e'},
  // {name: '0212F', color: 'a28e6b'},
  // {name: '0210F', color: 'c0b387'},
  // {name: '07339', color: '1b184f'},
  // {name: '0224F', color: '6b7a99'},
  // {name: '0221F', color: 'b7bbc7'},
  // {name: '596MJ', color: '73025a'},
  // {name: '260MJ', color: 'dcb8a2'},
  // {name: '0400F', color: 'd6cfc7'},

  { name: "11 black", color: "181617" },
  { name: "10 white", color: "ffffff" },
  { name: "130", color: "cebca8" },
  { name: "141", color: "dfdacb" },
  { name: "12", color: "e3dac2" },
  { name: "46", color: "d5c093" },
  { name: "34", color: "cda76f" },
  { name: "142", color: "d3aa77" },
  { name: "15", color: "9c8053" },
  { name: "51", color: "805d38" },
  { name: "143", color: "efd804" },
  { name: "18", color: "edbd02" },
  { name: "244", color: "f1a14c" },
  { name: "19", color: "e66d0e" },
  { name: "245", color: "c32109" },
  { name: "23", color: "b6060a" },
  { name: "92", color: "741716" },
  { name: "246", color: "680b1c" },
  { name: "24", color: "391319" },
  { name: "66", color: "801f4c" },
  { name: "22", color: "c80140" },
  { name: "25", color: "e26697" },
  { name: "62", color: "d87a8e" },
  { name: "58", color: "eac9c5" },
  { name: "347", color: "d2b358" },
  { name: "13", color: "dfb051" },
  { name: "348", color: "e5b113" },
  { name: "349", color: "c18c25" },
  { name: "350", color: "d18c02" },
  { name: "351", color: "c07a11" },
  { name: "352", color: "b17d13" },
  { name: "353", color: "a2721c" },
  { name: "354", color: "7e5218" },
  { name: "355", color: "ba8245" },
  { name: "356", color: "c58046" },
  { name: "357", color: "ae5711" },
  { name: "35", color: "baa573" },
  { name: "458", color: "cfc2a5" },
  { name: "36", color: "aa8b6b" },
  { name: "459", color: "b99668" },
  { name: "460", color: "aa8858" },
  { name: "461", color: "825f38" },
  { name: "462", color: "614227" },
  { name: "54", color: "55381f" },
  { name: "463", color: "47261c" },
  { name: "55", color: "473125" },
  { name: "16", color: "362019" },
  { name: "17", color: "211a17" },
  { name: "564", color: "e6c579" },
  { name: "38", color: "a2562e" },
  { name: "565", color: "85502c" },
  { name: "566", color: "6b4023" },
  { name: "41", color: "804d1f" },
  { name: "42", color: "78573b" },
  { name: "567", color: "694d32" },
  { name: "52", color: "72502f" },
  { name: "568", color: "84642e" },
  { name: "569", color: "6c4d2b" },
  { name: "570", color: "5c4f34" },
  { name: "571", color: "4f493d" },
  { name: "33", color: "35452d" },
  { name: "32", color: "1b4845" },
  { name: "125", color: "265b3d" },
  { name: "128", color: "757b35" },
  { name: "672", color: "a1b134" },
  { name: "30", color: "eae96d" },
  { name: "673", color: "91e568" },
  { name: "674", color: "6cc92d" },
  { name: "31", color: "46b75b" },
  { name: "675", color: "84d1ac" },
  { name: "122", color: "029a7f" },
  { name: "676", color: "027276" },
  { name: "777", color: "0e6369" },
  { name: "27", color: "b7cede" },
  { name: "778", color: "0ca3cb" },
  { name: "779", color: "75d5d9" },
  { name: "780", color: "b1cbdc" },
  { name: "28", color: "0248aa" },
  { name: "781", color: "06357c" },
  { name: "782", color: "3c5975" },
  { name: "783", color: "22243c" },
  { name: "29", color: "1c293e" },
  { name: "784", color: "34383f" },
  { name: "131", color: "22242c" },
  { name: "992", color: "dbd1bc" },
  { name: "109", color: "cccac5" },
  { name: "993", color: "bcbab4" },
  { name: "994", color: "7b665f" },
  { name: "995", color: "ac9690" },
  { name: "113", color: "424447" },
  { name: "996", color: "4f4848" },
  { name: "997", color: "4d4c4c" },
  { name: "998", color: "434751" },
  { name: "999", color: "b64026" },
  { name: "1000", color: "8c101f" },
  { name: "1001", color: "fd2843" },
  { name: "1002", color: "e4bacf" },
  { name: "1003", color: "ccd9e3" },
  { name: "1004", color: "676973" },
  { name: "14", color: "df8c18" },
  { name: "1005", color: "b8cf7d" },
  { name: "119", color: "7bb05e" },
  { name: "1006", color: "029478" },
  { name: "1007", color: "02a287" },
  { name: "1008", color: "659470" },
  { name: "1009", color: "456f43" },
  { name: "1010", color: "2b7e7a" },
  { name: "1011", color: "2b5c5a" },
];

class Thread extends Component {
  componentDidMount() {
    // задание backgroundColor (по цветовому коду) и color (по яркости backgroundColor) для найденных селекторов
    const items = document.querySelectorAll(".acce-page-color .item");
    items.forEach((item) => {
      item.style.cssText = `background:#${item.classList[1]}`;

      // (!) Цветовой код селектора интерпретируется браузером как 'rgb(int, int, int)'
      // массив с числовыми значениями rgb-оттенков: [r, g, b]
      let rgb = item.style.backgroundColor.match(/\d+/g);
      // коэффициент восприимчивости яркости цвета
      let k = 1 - (0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2]) / 255;
      // неяркий цвет - цвет шрифта белый, яркий цвет - цвет шрифта чёрный
      let fontColor = k < 0.5 ? "#000" : "#fff";
      item.style.cssText += `color:${fontColor}`;
    });
  }

  render() {
    return (
      <div className="thread-page">
        <Helmet>
          <link rel="canonical" href="https://vektor.club/goods/thread" />
        </Helmet>

        <Row className="acce-page-top">
          <Col xs="4" className="acce-page-top-img">
            <img src="/img/jpg/thread-inner.jpg" alt="" />
          </Col>
          <Col xs="8" className="aces-page-top-text">
            <div className="aces-page-top-title">
              <h1 className="h1">Нитки</h1>
            </div>
            <div className="aces-page-top-table">
              <table>
                <thead>
                  <tr>
                    <td>Марка</td>
                    <td>Плотность</td>
                    <td>Длина</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Makro Thread</td>
                    <td>10</td>
                    <td>750 м</td>
                  </tr>
                  {/*<tr>*/}
                  {/*<td>Makro Thread</td>*/}
                  {/*<td>15</td>*/}
                  {/*<td>1000 м</td>*/}
                  {/*</tr>*/}
                  <tr>
                    <td>Makro Thread</td>
                    <td>20</td>
                    <td>1500 м</td>
                  </tr>
                  <tr>
                    <td>Makro Thread</td>
                    <td>30</td>
                    <td>2500 м</td>
                  </tr>
                  <tr>
                    <td>Makro Thread</td>
                    <td>40</td>
                    <td>3000 м</td>
                  </tr>
                  <tr>
                    <td>Makro Thread</td>
                    <td>50</td>
                    <td>4000 м</td>
                  </tr>
                  {/*<tr>*/}
                  {/*<td>Makro Thread</td>*/}
                  {/*<td>60</td>*/}
                  {/*<td>4500 м</td>*/}
                  {/*</tr>*/}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        <div className="acce-page-bottom">
          {/*<h1 className="h1"/>*/}
          <div className="acce-page-color opora" />
          <div className="acce-page-color">
            {color.map((item, index) => {
              return (
                <div key={index} className={"item " + item.color}>
                  {item.name}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Thread;
