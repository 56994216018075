import React from 'react'
import { Row, Col } from 'reactstrap'
import { staticStore } from '../../remoteServer'

export const ItemGlue = (props) => (
    <div>
        <Row className="glue-item">
            <Col xs="4">
                <p className="glue-name">{props.item.attributes.name}</p>
                <p className="basis">{props.item.attributes.caption}</p>
                <div className="glue-pich"><img src={staticStore + props.item.attributes.image} alt="" /></div>
                <div className="glue-price">
                    {props.item.attributes.old_price > 0 ? (<p className="priceSale">{props.item.attributes.old_price} ₽</p>) : ''}
                    <p className="price">{props.item.attributes.price} ₽</p>
                </div>
                <p className="def-text">Розничная цена. Оптовые цены — по запросу.</p>
            </Col>
            <Col xs="8">
                <div className="colection-description"><div className="more-text" onClick={props.click}><p>описание</p><span></span></div><p>{props.item.attributes.description}</p></div>
                <div className="glue-parametr">
                    <p>Технические характеристики</p>
                    <div className="glue-parametr-item">
                        <p className="type">Цвет:</p>
                        <p className="value">{props.item.attributes.color}</p>
                    </div>
                    <div className="glue-parametr-item">
                        <p className="type">Вязкость:</p>
                        <p className="value">{props.item.attributes.base_viscosity}±{props.item.attributes.viscosity} МПа(20°C)</p>
                    </div>
                    <div className="glue-parametr-item">
                        <p className="type">Свободное время:</p>
                        <p className="value">{props.item.attributes.free_time}</p>
                    </div>
                    <div className="glue-parametr-item">
                        <p className="type">Термостойкость:</p>
                        <p className="value">{props.item.attributes.heat_resistance}</p>
                    </div>
                    <div className="glue-parametr-item">
                        <p className="type">Срок годности:</p>
                        <p className="value">{props.item.attributes.expiration}</p>
                    </div>
                    <div className="glue-parametr-item">
                        <p className="type">Размер иглы:</p>
                        <p className="value">Ø {props.item.attributes.needle_size}</p>
                    </div>
                    <div className="glue-parametr-item">
                        <p className="type">Давление:</p>
                        <p className="value">{props.item.attributes.pressure} Bar</p>
                    </div>
                    <div className="glue-parametr-item">
                        <p className="type">Удельный вес:</p>
                        <p className="value">~{props.item.attributes.weight} г/мл</p>
                    </div>
                    <div className="glue-parametr-item">
                        <p className="type">Сухой остаток:</p>
                        <p className="value">{props.item.attributes.base_dry_residue} ± {props.item.attributes.dry_residue} %</p>
                    </div>
                    <div className="glue-parametr-item">
                        <p className="type">Упаковка:</p>
                        <p className="value">{props.item.attributes.packaging}</p>
                    </div>
                </div>
            </Col>
        </Row>
    </div>
)