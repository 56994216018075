import React from 'react'
import Header from '../../containers/Header'
import GoodsDescription from './GoodsDescription'

function showDesc() {
   let descBody = document.querySelector('.grow')

   if (descBody.clientHeight) {
      descBody.style.height = 0
   } else {
      let wrapper = document.querySelector('.page-desc-body')

      descBody.style.height = wrapper.clientHeight + 'px'
   }
}

const furniDesc = (
   <div className='page-description'>
      <div className='page-desc-header'>
         Компания «Вектор» предлагает большой выбор мебельных тканей. Информация о товаре представлена в нашем каталоге.
         Каталог мебельных тканей удобно сортировать по цене, цвету и типу тканей.
         <span className='bold' onClick={showDesc}>
            {' '}
            Читать далее
         </span>
      </div>
      <div className='grow'>
         <div className='page-desc-body'>
            <span>
               Весь товар закупается у производителя напрямую. Поэтому в компании «Вектор» вы найдёте оптимальное
               соотношение цены и качества.
            </span>
            <span>
               В каталоге представлены ткани производства ведущих мировых фабрик. Сотрудники «Вектор» лично проверяют
               качество продукции. Компания гарантирует соответствие товара мировым стандартам качества и соблюдение
               сроков поставки товара.
            </span>
            <span>
               Мебельная ткань, доступная в каталоге: искусственная и натуральная кожа, велюр, рогожка, искусственная
               замша, вельвет, жаккард, флок, экокожа рулонная.
            </span>
            <span>
               Доступен широкий выбор цветов. Материалы устойчивы к истиранию, гармонично сочетаются друг с другом, не
               требуют подбора рисунка.
            </span>
            <span>Представлены ткани различной толщины и плотности. Доступны новинки из последних коллекций.</span>
            <span>
               Флок — нетканый материал, широко использующийся в производстве мебели. Флок износостоек, эстетичен,
               приятен на ощупь.
            </span>
            <span>
               Велюр — ворсовая ткань, также отличается высокой устойчивостью к истиранию, по внешнему виду напоминает
               бархат.
            </span>
            <span>
               Наиболее распространён для изготовления обивки жаккард. Материал производится на специальном станке и
               обладает большой прочностью.
            </span>
            <span>
               Заказать мебельную ткань и ткань для обивки можно в розницу и оптом, товар имеется в наличии на складе.
            </span>
         </div>
      </div>
   </div>
)

const decscription = {
   furniture_fabrics: furniDesc,
}

const Goods = ({ children }) => {
   return (
      <div className='catalog-page'>
         <div className='page-title'>
            <span>Каталог</span>
            {children.props.router.params.collectionName ? (
               <h2 className='h2 product-category-title'>{children.props.route.title}</h2>
            ) : (
               <h1 className='h1'>{children.props.route.title}</h1>
            )}
         </div>
         <div className='goods-wr'>{children}</div>
         {decscription[children.props.route.path]}
         <h5 className='feedback__title main_feedback__title'>Нужна помощь в выборе?</h5>
         <div className='feedback main_feedback'>
            <div className='button-mail' role='button' onClick={Header.showPopupForm}>
               Сообщение
            </div>
            <div className='button-phone' role='button' onClick={Header.openOrderPhone}>
               Заказать звонок
            </div>
         </div>
         <GoodsDescription catalog={children.props.location.pathname} />
      </div>
   )
}
export default Goods
