import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Row } from "reactstrap";
import { connect } from "react-redux";
import Item from "../../containers/GoodItem/Item";
import { statusSearch, addGlobalSaleGood } from "../../actions/filter/filter";
// import Loading from 'react-loading'
import { SaleHOC } from "../../composed/composedSale";

class Sale extends Component {
  render() {
    // const Children = this.props.testStore.globalSale;
    return (
      <div>
        <Helmet>
          <link rel="canonical" href="https://vektor.club/goods/sale" />
        </Helmet>

        {this.props.testStore.found === "Expectation" ||
        this.props.testStore.found === undefined ? (
          // <div className="loader"><Loading type='spinningBubbles' color='#d91c29'/></div>
          <div className="loader"></div>
        ) : this.props.testStore.found === "Found" ? (
          <Row className="catalog-block">
            {this.props.testStore.globalSale.map((item, index) => (
              <Item item={item} key={index} {...this.props} />
            ))}
          </Row>
        ) : this.props.testStore.found === "NotFound" ? (
          <div className="not-found-text">Товар не найден</div>
        ) : (
          // <div className="loader"><Loading type='spinningBubbles' color='#d91c29'/></div>
          <div className="loader"></div>
        )}
        {/*{Children.length >= 20 ? <div className="arrow-button">показать ещё</div> : ""}*/}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    testStore: state,
  }),
  (dispatch) => ({
    statusFound: (value) => {
      dispatch(statusSearch(value));
    },
    addGlobalSaleGood: (good) => {
      dispatch(addGlobalSaleGood(good));
    },
  })
)(SaleHOC(Sale));
