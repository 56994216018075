import React, { Component } from 'react'

class PopupPhone extends Component {

  // открытие/закрытие формы сообщения
  close () {
    document.querySelector('.popup-bg-phone').classList.toggle('active');
    document.querySelector('.popup-block-phone').classList.toggle('active');
  }

  render () {

    return (
      <div>
        <div className="popup-bg-phone" onClick={this.close.bind(this)}></div>
        <div className="popup-block-phone">
          <div className="button-close" onClick={this.close.bind(this)}/>
          <div className="header-contact-items popup-c"><p>Москва: <span><a
            href="tel:+74955977686">+7 (495) 597-76-86</a></span></p></div>
          <div className="header-contact-items popup-c"><p>Ростов-на-Дону: <span><a href="tel:+78632693766">+7 (863) 269-37-66</a></span>
          </p></div>
          <div className="header-contact-items popup-c"><p>Краснодар: <span><a href="tel:+78612604758">+7 (861) 260-47-58</a></span>
          </p></div>
        </div>
      </div>
    )
  }
}

export default PopupPhone