import React from "react";
import { connect } from "react-redux";
import Filter from "../../containers/Filter";
import { NaturalHOC } from "../../composed/composedNatural";
import { addNatural, statusSearch } from "../../actions/filter/filter";
import { Helmet } from "react-helmet";

const NaturalLeather = ({ Natural }) => (
  <div>
    <Helmet>
      <title>
        Купить натуральную кожу для мебели, каталог с ценами - компания "Вектор"
      </title>
      <meta
        name="description"
        content="Натуральная кожа  для обивки мебели от компании Вектор — лучшие цены и именитые производители. Кожа для мебели оптом с доставкой по России."
      />
      <link rel="canonical" href="https://vektor.club/goods/natural_leather" />
    </Helmet>
    <Filter
      filter={["price", "color", "tags", "NewsOrRas"]}
      main="NaturalLeather"
      box={Natural}
    />
  </div>
);

export default connect(
  (state) => ({
    priceNatural: state.filter.priceNatural,
    Natural: state.Natural,
  }),
  (dispatch) => ({
    addNatural: (good) => dispatch(addNatural(good)),
    statusFound: (value) => dispatch(statusSearch(value)),
  })
)(NaturalHOC(NaturalLeather));
