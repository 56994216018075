import React from 'react'
import { Range } from 'rc-slider'

// блок фильтра Цвет
export const BlockColor = (props) => {
  return (
    <div className={`filter-block-item filter-block-color ${props.active ? 'is-active' : ''}` } onClick={props.click}>
      <div className="filter-color-hide">
        <p>Цвет<span><svg><g><path fill="#788390" d="M0,6h14v2H0V6z"/><path fill="#788390" d="M6,0h2v14H6V0z"/></g></svg></span>
        </p>
        {props.main === 'FurnitureFabrics'
          ? <p className="list-span">
            {props.testStore.filter.color.map((item, index) => {
              const arrItem = item.split('_');
              return <span key={index}>{arrItem[0]}</span>
            })}
          </p>
          : props.main === 'ArtificialLeather'
            ? <p className="list-span">{props.testStore.filter.colorArti.map((item, index) => {
              const arrItem = item.split('_');
              return <span key={index}>{arrItem[0]}</span>
            })}</p>
            : props.main === 'NaturalLeather'
              ? <p className="list-span">{props.testStore.filter.colorNatural.map((item, index) => {
                const arrItem = item.split('_');
                return <span key={index}>{arrItem[0]}</span>
              })}</p>
              : props.main === 'FurnitureSupports' 
                ? <p className="list-span">{props.testStore.filter.colorSupports.map((item, index) => {
                  const arrItem = item.split('_');
                  return <span key={index}>{arrItem[0]}</span>
                })}</p>
                : <p className="list-span">{props.testStore.filter.colorEco.map((item, index) => {
                  const arrItem = item.split('_');
                  return <span key={index}>{arrItem[0]}</span>
                })}</p>
        }
      </div>
      <div className="opened-block filter-color-show">
        <p>Цвет<span><svg><g><path fill="#788390" d="M0,6h14v2H0V6z"/></g></svg></span></p>
        <div className="filter-color-radio">
          {props.color.map((item) => {
            return (
              <div key={item.id}>
                <input
                  type="radio"
                  name="color"
                  id={'color' + item.id}
                  value={item.attributes.name + '_' + item.id}
                  checked={
                    props.main === 'FurnitureFabrics' ?
                      props.testStore.filter.color.indexOf(item.attributes.name + '_' + item.id) !== -1
                      : props.main === 'ArtificialLeather' ?
                      props.testStore.filter.colorArti.indexOf(item.attributes.name + '_' + item.id) !== -1
                      : props.main === 'NaturalLeather' ?
                        props.testStore.filter.colorNatural.indexOf(item.attributes.name + '_' + item.id) !== -1
                          : props.main === 'FurnitureSupports' ?
                            props.testStore.filter.colorSupports.indexOf(item.attributes.name + '_' + item.id) !== -1
                            : false}/>
                <label
                  value={'color' + item.id}
                  onClick={props.clickCheckbox}
                  htmlFor={'color' + item.id}>
                  <span
                    style={item.attributes.code == 'null'
                      ? {
                        backgroundImage: 'url(/img/png/g.png)',
                        border: '0px'
                      }
                      : {borderColor: item.attributes.code}}
                    className="border"></span>
                  <span
                    style={item.attributes.code == 'null'
                      ? {backgroundImage: 'url(/img/png/g-a.png)'}
                      : {backgroundColor: item.attributes.code}}
                    className="circle"></span>
                  <p>{item.attributes.name}</p>
                </label>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

// блок фильтра Металлические или пластиковые
export const BlockMetallicOrPlastic = ({ filter, clickCheckbox }) => {
  return (
    <div className='filter-block-item filter-block-NewsOrRas'>
      <input
        id="metallic"
        name="metallicOrPlastic"
        type="checkbox"
        checked={filter[0]}/>
      <label onClick={clickCheckbox.bind(this, 'metallic')} htmlFor="metallic">
        <span></span>
        <p>Металлические</p>
      </label>
      <input
        id="plastic"
        name="metallicOrPlastic"
        type="checkbox"
        checked={filter[1]}
      />
      <label onClick={clickCheckbox.bind(this, 'plastic')} htmlFor="plastic">
        <span></span>
        <p>Пластиковые</p>
      </label>
    </div>
  )
}

// блок фильтра с checkbox`ами - для мягкой/корпусной мебели
export const BlockFurnitureType = ({ click, testStore }) => (
  <div className='filter-block-item filter-block-NewsOrRas'>
    <input
      id="upholsteredFur"
      name="furType"
      value="desc"
      type="checkbox"
      checked={testStore.filter.furnitureType[0]}/>
    <label
      onClick={click.bind(this, 'upholstered')}
      htmlFor="upholsteredFur">
      <span></span>
      <p>Для мягкой мебели</p>
    </label>
    <input
      id="cabinetFur"
      name="furType"
      value="asc"
      type="checkbox"
      checked={testStore.filter.furnitureType[1]}/>
    <label onClick={click.bind(this, 'cabinet')}
           htmlFor="cabinetFur">
      <span></span>
      <p>Для корпусной мебели</p>
    </label>
  </div>
)

// блок фильтра с checkbox'ами Новинка и Распродажа
export const BlockNewsOrRas = (props) =>
  <div className='filter-block-item filter-block-NewsOrRas'>
    <input
      id="showNews"
      name="sort"
      value="desc"
      type="checkbox"
      checked={props.main === 'FurnitureFabrics' ?
        props.testStore.filter.newsSaleFurni[0]
        : props.main === 'ArtificialLeather' ?
          props.testStore.filter.newsSaleArti[0]
          : props.main === 'NaturalLeather' ?
            props.testStore.filter.newsSaleNatural[0]
            : false} />
    <label
      onClick={props.click.bind(this, 'news')}
      htmlFor="showNews">
      <span></span>
      <p>Новинка</p>
    </label>
    <input
      id="showRasp"
      name="sort"
      value="asc"
      type="checkbox"
      checked={props.main === 'FurnitureFabrics' ?
        props.testStore.filter.newsSaleFurni[1] :
        props.main === 'ArtificialLeather' ?
          props.testStore.filter.newsSaleArti[1]
          : props.main === 'NaturalLeather' ?
          props.testStore.filter.newsSaleNatural[1]
          : false} />
    <label onClick={props.click.bind(this, 'sale')}
           htmlFor="showRasp">
      <span></span>
      <p>Распродажа</p>
    </label>
  </div>

// блок фильтра Типы механизмов
export const BlockMechanismType = ({ filter, onClick, clickCheckbox, mechanismTypes, active }) => (
  <div className={`filter-block-item filter-block-type ${active ? 'is-active' : ''}` } onClick={onClick}>
    <div className="filter-tipe-wr">
      <div className="filter-tipe-hide">
        <p>Тип механизма<span><svg><g><path fill="#788390" d="M0,6h14v2H0V6z"/><path fill="#788390" d="M6,0h2v14H6V0z"/></g></svg></span>
        </p>
        <p className="list-span">{filter.mechanismTypes.map((item, index) => {
          const arrItem = item.split('_');
          return <span key={index}>{arrItem[0]}</span>
        })}</p>
      </div>
      <div className="opened-block filter-tipe-show">
        <p>Тип механизма<span><svg><g><path fill="#788390" d="M0,6h14v2H0V6z"/></g></svg></span></p>
        <div className="filter-tipe-checkbox">
          {mechanismTypes.map((item) => {
            return (
              <div key={item.id}>
                <input
                  type="checkbox"
                  id={'type' + item.id}
                  name='type'
                  value={item.attributes.name + '_' + item.id}
                  checked={filter.mechanismTypes.indexOf(item.attributes.name + '_' + item.id) !== -1}/>
                <label
                  onClick={clickCheckbox}
                  htmlFor={'type' + item.id}>
                  <span></span>
                  <p>{item.attributes.name}</p></label>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  </div>
)

// блок фильтра Отечественные или импортные
export const BlockDomesticOrImported = ({ filter, clickCheckbox }) => {
  return (
    <div className='filter-block-item filter-block-NewsOrRas'>
      <input
        id="domesticFur"
        name="domesticOrImported"
        type="checkbox"
        checked={filter.domesticOrImported[0]}/>
      <label
        onClick={clickCheckbox.bind(this, 'domestic')}
        htmlFor="upholsteredFur">
        <span></span>
        <p>Отечественные</p>
      </label>
      <input
        id="importedFur"
        name="domesticOrImported"
        type="checkbox"
        checked={filter.domesticOrImported[1]}/>
      <label onClick={clickCheckbox.bind(this, 'imported')}
            htmlFor="importedFur">
        <span></span>
        <p>Импортные</p>
      </label>
    </div>
  )
}

// блок фильтра Состав верха
export const BlockCompositionTop = (props) =>
  <div className={`filter-block-item filter-block-composition ${props.active ? 'is-active' : ''}` } onClick={props.click}>
    <div className="filter-composition-wr">
      <div className="filter-composition-hide">
        <p>Состав верха<span><svg><g><path fill="#788390" d="M0,6h14v2H0V6z"/><path fill="#788390" d="M6,0h2v14H6V0z"/></g></svg></span>
        </p>
        <p className="list-span">{props.testStore.filter.typeArti.map((item, index) => {
          const arrItem = item.split('_');
          return <span key={index}>{arrItem[0]}</span>
        })}</p>
      </div>
      <div className="opened-block filter-composition-show">
        <p>Состав верха<span><svg><g><path fill="#788390" d="M0,6h14v2H0V6z"/></g></svg></span></p>
        <div className="filter-composition-checkbox">
          {props.leatheretteTypes.map((item, index) => {
            return (
              <div key={index}>
                <input
                  id={'top' + item.id}
                  name='top'
                  value={item.attributes.name + '_' + item.id}
                  type="checkbox"
                  checked={props.testStore.filter.typeArti.indexOf(item.attributes.name + '_' + item.id) !== -1}
                />
                <label
                  onClick={props.clickCheckbox}
                  htmlFor={'top' + item.id}>
                  <span></span>
                  <p>{item.attributes.name}</p>
                </label>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  </div>

// блок мобильной версии фильтра Сортировать
export const MobilPanel = (props, {click, sort}) =>
  <div className="filter-mobil-panel">
    {props.main !== 'Glue' &&
    <div className="filter-mobil-sort">
      <p>
        <span>Сортировать:</span>
        <a
          className={props.sortBy === 'name' && !props.sortState ? "active up" : props.sortBy === 'name' && props.sortState ? "active down" : ""}
          onClick={props.sort.bind(this, 'name')}>по названию</a>
        <a
          className={props.sortBy === 'price' && !props.sortState ? "active up" : props.sortBy === 'price' && props.sortState ? "active down" : ""}
          onClick={props.sort.bind(this, 'price')}>по цене</a>
        <a
          className={props.sortBy === 'created_at' && !props.sortState ? "active up" : props.sortBy === 'created_at' && props.sortState ? "active down" : ""}
          onClick={props.sort.bind(this, 'created_at')}>по новизне</a>
      </p>
    </div>}
    <div className="filter-mobil-openFilter" onClick={props.click}></div>
  </div>

// блок фильтра Вязкость
export const Viscosity = (props, {change}) =>
  <div className='filter-block-item filter-block-Viscosity'>
    <div>
      <div className="filter-price-inputs">
        <div className="inputs-text"><span>Вязкость</span></div>
        <div className="inputs-wr">
          <input
            className="min-priceViscosity"
            type="number"
            placeholder={props.min_v}
            value={props.testStore.filter.viscosity[0]}/>
          <span>-</span>
          <input
            className="max-priceViscosity"
            type="number"
            placeholder={props.max_v}
            value={props.testStore.filter.viscosity[1]}/>
          <span className='money-rus'>мПа</span>
        </div>
      </div>
      <div className="filter-price-skider" id="range-Viscosity">
        <Range
          dots
          step={5}
          min={props.min_v}
          max={props.max_v}
          value={props.testStore.filter.viscosity}
          defaultValue={[props.testStore.filter.viscosity[0],
            props.testStore.filter.viscosity[1]]}
          onChange={props.change}
          onAfterChange={props.afterChange}/>
      </div>
    </div>
  </div>

// блок фильтра Сухой остаток
export const DryResidue = (props, {change}) =>
  <div className='filter-block-item filter-block-DryResidue'>
    <div>
      <div className="filter-price-inputs">
        <div className="inputs-text"><span>Сухой остаток</span></div>
        <div className="inputs-wr">
          <input className="min-priceDryResidue" type="number" placeholder={props.residueMin}
                 value={props.testStore.filter.dry_residue[0]}/>
          <span>-</span>
          <input className="max-priceDryResidue" type="number" placeholder={props.residueMax}
                 value={props.testStore.filter.dry_residue[1]}/>
          <span className='money-rus'>%</span>
        </div>
      </div>
      <div className="filter-price-skider" id="range-DryResidue">
        <Range
          dots
          step={5}
          min={props.residueMin}
          max={props.residueMax}
          value={props.testStore.filter.dry_residue}
          defaultValue={[props.testStore.filter.dry_residue[0],
            props.testStore.filter.dry_residue[1]]}
          onChange={props.change}
          onAfterChange={props.afterChange}/>
      </div>
    </div>
  </div>

// блок фильтра с checkbox'ами Горючий\Негорючий
export const Flammability = (props, {changeYes, changeNo}) =>
  <div className='filter-block-item filter-block-flammability'>
    <input id="flammability" name="flammability" type="checkbox" onChange={props.changeNo}/><label
    htmlFor="flammability"><span></span><p>Горючий</p></label>
    <input id="not-flammability" name="flammability" type="checkbox" onChange={props.changeYes}/><label
    htmlFor="not-flammability"><span></span><p>Негорючий</p></label>
  </div>

// блок фильтра Цена
export const CustomizedRangePrice = ({ main, min, max, testStore, change, afterChange }) =>
  <div className='filter-block-item filter-block-price'>
    <div>
      <div className="filter-price-inputs">
        <div className="inputs-text"><span>Цена </span>{{'FurnitureSupports' : '1 ед', default : '1 п/м'}[main]}</div>
        <div className="inputs-wr">
          <input
            className="min-pricePrice"
            type="number"
            placeholder={min}
            value={main === 'FurnitureFabrics'
              ? testStore.filter.priceFurnitire[0]
              : main === 'Mechanisms'
                ? testStore.filter.priceMechanisms[0]
                : main === 'ArtificialLeather'
                  ? testStore.filter.priceArtificial[0]
                  : main === 'NaturalLeather'
                    ? testStore.filter.priceNatural[0] 
                    : main === 'FurnitureSupports' 
                      ? testStore.filter.priceSupports[0]
                      : testStore.filter.priceEco[0]}/>
          <span>-</span>
          <input
            className="max-pricePrice"
            type="number"
            placeholder={max}
            value={main === 'FurnitureFabrics'
              ? testStore.filter.priceFurnitire[1]
              : main === 'Mechanisms'
                ? testStore.filter.priceMechanisms[1]
                : main === 'ArtificialLeather'
                  ? testStore.filter.priceArtificial[1]
                  : main === 'NaturalLeather'
                    ? testStore.filter.priceNatural[1] 
                    : main === 'FurnitureSupports' 
                      ? testStore.filter.priceSupports[1]
                      : testStore.filter.priceEco[1]}/>
          <span className='money-rus'>₽</span>
        </div>
      </div>
      <div className="filter-price-skider" id="rande-price">
        <Range
          dots
          step={10}

          min={main === 'FurnitureFabrics'
            ? testStore.filter.sliderPriceInitFurniture[0]
            : main === 'Mechanisms'
              ? testStore.filter.sliderPriceInitMechanisms[0]
              : main === 'ArtificialLeather'
                ? testStore.filter.sliderPriceInitArtificial[0]
                : main === 'NaturalLeather'
                  ? testStore.filter.sliderPriceInitNatural[0] 
                  : main === 'FurnitureSupports' 
                    ? testStore.filter.sliderPriceInitSupports[0]
                    : testStore.filter.priceEco[0]}

          max={main === 'FurnitureFabrics'
            ? testStore.filter.sliderPriceInitFurniture[1]
            : main === 'Mechanisms'
              ? testStore.filter.sliderPriceInitMechanisms[1]
              : main === 'ArtificialLeather'
                ? testStore.filter.sliderPriceInitArtificial[1]
                : main === 'NaturalLeather'
                  ? testStore.filter.sliderPriceInitNatural[1] 
                  : main === 'FurnitureSupports' 
                    ? testStore.filter.sliderPriceInitSupports[1]
                    : testStore.filter.priceEco[1]}

          value={main === 'FurnitureFabrics'
            ? testStore.filter.priceFurnitire
            : main === 'Mechanisms'
              ? testStore.filter.priceMechanisms
              : main === 'ArtificialLeather'
                ? testStore.filter.priceArtificial
                : main === 'NaturalLeather'
                  ? testStore.filter.priceNatural 
                  : main === 'FurnitureSupports' 
                    ? testStore.filter.priceSupports
                    : testStore.filter.priceEco}

          allowCross={false}
          onChange={change}
          onAfterChange={afterChange}/>
      </div>
    </div>
  </div>



// блок фильтра Тип ткани
export const BlockType = (props) => 
  <div className={`filter-block-item filter-block-type ${props.active ? 'is-active' : ''}` } onClick={props.click}>
    <div className="filter-tipe-wr">
      <div className="filter-tipe-hide">
        <p>Тип ткани<span><svg><g><path fill="#788390" d="M0,6h14v2H0V6z"/><path fill="#788390" d="M6,0h2v14H6V0z"/></g></svg></span>
        </p>
        <p className="list-span">{props.testStore.filter.type.map((item, index) => {
          const arrItem = item.split('_');
          return <span key={index}>{arrItem[0]}</span>
        })}</p>
      </div>
      <div className="opened-block filter-tipe-show">
        <p>Тип ткани<span><svg><g><path fill="#788390" d="M0,6h14v2H0V6z"/></g></svg></span></p>
        <div className="filter-tipe-checkbox">
          {props.typeCloth.map((item) => {
            return (
              <div key={item.id}>
                <input
                  type="checkbox"
                  id={'type' + item.id}
                  name='type'
                  value={item.attributes.name + '_' + item.id}
                  checked={props.testStore.filter.type.indexOf(item.attributes.name + '_' + item.id) !== -1}/>
                <label
                  onClick={props.clickCheckbox}
                  htmlFor={'type' + item.id}>
                  <span></span>
                  <p>{item.attributes.name}</p></label>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  </div>


// блок фильтра Тип ткани
export const BlockTags = (props) => {
    const dict = {
      FurnitureFabrics: props.tags.cloth,
      ArtificialLeather: props.tags.leatherette,
      NaturalLeather: props.tags.leather,
      FurnitureSupports: props.tags.furniture_support,
      Mechanisms: props.tags.transformation_mechanism,
      Glue: props.tags.glue,
    }

    const tagsList = props.main && dict[props.main] ? dict[props.main] : []

    return <div className={`filter-block-item filter-block-tags ${props.active ? 'is-active' : ''}` } onClick={props.click}>
      <div className="filter-tipe-wr">
        <div className="filter-tags-hide">
          <p>Теги<span><svg><g><path fill="#788390" d="M0,6h14v2H0V6z"/><path fill="#788390" d="M6,0h2v14H6V0z"/></g></svg></span>
          </p>
          <p className="list-span">{props.testStore.filter.selectedTags.map((item, index) => {
            const arrItem = item.split('_');
            return <span key={index}>{arrItem[0]}</span>
          })}</p>
        </div>
        <div className="opened-block filter-tags-show">
          <p>Теги<span><svg><g><path fill="#788390" d="M0,6h14v2H0V6z"/></g></svg></span></p>
          <div className="filter-tags-checkbox">
            {tagsList.map((item) => {
              return (
                <div key={item.id}>
                  <input
                    type="checkbox"
                    id={'tag' + item.id}
                    name='tag'
                    value={item.attributes.name + '_' + item.id}
                    checked={props.testStore.filter.selectedTags.indexOf(item.attributes.name + '_' + item.id) !== -1}/>
                  <label
                    onClick={props.clickCheckbox}
                    htmlFor={'tag' + item.id}>
                    <span></span>
                    <p>{item.attributes.name}</p></label>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  }