import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import Filter from "../../containers/Filter";
import { GlueHOC } from "../../composed/composedGlue";
import { addGlue, statusSearch } from "../../actions/filter/filter";

class Glue extends Component {
  render() {
    return (
      <div className="glue-page">
        <Helmet>
          <title>
            Купить клей для мебели, каталог с ценами - компания "Вектор"
          </title>
          <meta
            name="description"
            content="Клей для мебели от компании Вектор — лучшие цены и именитые производители. Опт и доставка по России."
          />
          <link rel="canonical" href="https://vektor.club/goods/glue" />
        </Helmet>

        <Filter
          viscosityMin={10}
          viscosityMax={3000}
          residueMin={5}
          residueMax={100}
          filter={["DryResidue", "viscosity", 'tags', "flammability"]}
          main={"Glue"}
          box={this.props.testStore.glue}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    testStore: state,
  }),
  (dispatch) => ({
    initialGlue: (good) => {
      dispatch(addGlue(good));
    },
    statusFound: (value) => {
      dispatch(statusSearch(value));
    },
  })
)(GlueHOC(Glue));
