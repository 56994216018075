import React from 'react'
import { IndexRoute, Route } from 'react-router'
import AppContainer from '../containers/AppContainer'
import {
   Home,
   Favorites,
   News,
   Posts,
   DetailPosts,
   DetailNews,
   About,
   SoutDocs,
   Contact,
   PDP,
   ErrorPage,
} from '../components'
import goodsRouter from './goodsRouter'

// componentName="..." - используется в AppContainer (react-helmet) для установки title
export default (
   <Route>
      <Route path='/' component={AppContainer}>
         <IndexRoute component={Home} />
         {goodsRouter}
         <Route path='about' component={About} componentName='О компании' />
         <Route path='sout-docs' component={SoutDocs} componentName='Документы по СОУТ' />
         <Route path='contact' component={Contact} componentName='Контакты' />
         <Route path='news' component={News} componentName='Новости' />
         <Route path='news/:id' component={DetailNews} componentName='Новости' />
         <Route path='posts' component={Posts} componentName='Всё о тканях' />
         <Route path='posts/:id' component={DetailPosts} componentName='Всё о тканях' />
         <Route path='favorites' component={Favorites} componentName='Избранное' />
         <Route
            path='personal-data-processing'
            component={PDP}
            componentName='Соглашение на обработку персональных данных'
         />
      </Route>
      <Route path='*' component={ErrorPage} componentName='Страница не найдена' />
   </Route>
)
