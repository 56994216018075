import React from 'react'
import { search } from '../actions/filter/search.js'

export const FurnitureHOC = ComposedComponent => class extends React.Component {
  static displayName = 'ComponentFurnitureHOC';

  componentDidMount () {

    if (this.props.FurnitureFabrics.length === 0)
      setTimeout(() => {search(`/cloths`, this.props.initialGoods, this.props)}, 10)
  }

  render () {
    return <ComposedComponent {...this.props} {...this.state} />;
  }
}