import React, { Component } from 'react'
import MessageFormHOC from './MessageForm'
import CallFormHOC from './CallForm'
import Helper from '../../helper'

class PopupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popUpCity: 1,
      callForm: false
    }
    this.checkForm = this.checkForm.bind(this);
  }
  // (!) изменение state у расширяемого класса Form
  toggleCity (city) {
    this.setState({popUpCity: city})
  }

  // открытие/закрытие формы сообщения и блок/разблок скролла страницы соответственно
  close () {
    Helper.removeClass('.popup-bg', 'active');
    Helper.removeClass('.popup-block', 'active');
    Helper.removeClass('.popup-block', 'message-active');
    Helper.toggleBodyScroll();
  }

  componentDidMount () {
    //initialize DOM observer for mutate callForm state;
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => { mutation.attributeName === 'class' && this.checkForm() });
    });
    // Notify me of class change
    observer.observe(document.querySelector('.popup-block'), { attributes: true, childlist:false });
  }

  checkForm () {
    document.querySelector('.popup-block').classList.contains('message-active') ?
    this.setState({ callForm: true }) : this.setState({ callForm: false });
  }

  render () {
    return (
      <div>
        <div className="popup-bg" onClick={this.close.bind(this)}></div>
        <div className="popup-block">
          {!this.state.callForm ? (
            <p className="popup-block-title">Написать сообщение</p>
          ) : (
            <p className="popup-block-title">Заказать звонок</p>
          )}
          <div className="contact-form-tab">
            <div className={"item-tab" + (this.state.popUpCity === 1 ? ' active' : '')}
                 onClick={this.toggleCity.bind(this, 1)}>
              { !this.state.callForm ? (
                <p>В Москву</p> ) : (
                <p>Из Москвы</p> )}
            </div>
            <div className={"item-tab" + (this.state.popUpCity === 2 ? ' active' : '')}
                 onClick={this.toggleCity.bind(this, 2)}>
                 { !this.state.callForm ? (
                   <p>В Ростов-на-Дону</p> ) : (
                   <p>Из Ростова-на-Дону</p> )}
            </div>
            <div className={"item-tab" + (this.state.popUpCity === 3 ? ' active' : '')}
                 onClick={this.toggleCity.bind(this, 3)}>
                 { !this.state.callForm ? (
                   <p>В Краснодар</p> ) : (
                   <p>Из Краснодара</p> )}
            </div>
          </div>
          <div className="button-close" onClick={this.close.bind(this)}/>
          <div className="contact-form-wr">
            <div className='contact-form-item active'>
              {!this.state.callForm ? (
                <MessageFormHOC {...this.state} /> ) : ( <CallFormHOC {...this.state} /> )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PopupForm
