import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Router, browserHistory } from 'react-router'
import routes from '../../routes/rootRouter'
import PricePopUp from '../../containers/PricePopUp'
import { INIT_STORAGE_FAVORITES } from '../../constants/Favorites'

import './css/App.css'
import './css/bootstrap.css'
import 'rc-slider/assets/index.css'
import './css/index.css'
import './css/media.css'

class App extends Component {
   componentDidMount() {
      this.props.initCookieFavorites()
   }

   render() {
      return (
         <div>
             <PricePopUp />
            <Router history={browserHistory}>{routes}</Router>
         </div>
      )
   }
}

export default connect(
   (state) => ({
      testStore: state,
   }),
   (dispatch) => ({
      initCookieFavorites: () => {
         dispatch({ type: INIT_STORAGE_FAVORITES })
      },
   })
)(App)
