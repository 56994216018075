import React from 'react'

export const Sort = (props, {sort}) => (
  <div className='sort-block'>
    <p>
      <span>Сортировать:</span>
      <a className={props.sortBy === 'name' && !props.sortState ? "active up" : props.sortBy === 'name' && props.sortState ? "active down" : ""} onClick={props.sort.bind(this, 'name')}>по алфавиту</a>
      <a className={props.sortBy === 'price' && !props.sortState ? "active up" : props.sortBy === 'price' && props.sortState ? "active down" : ""} onClick={props.sort.bind(this, 'price')}>по цене</a>
      <a className={props.sortBy === 'created_at' && !props.sortState ? "active up" : props.sortBy === 'created_at' && props.sortState ? "active down" : ""} onClick={props.sort.bind(this, 'created_at')}>по новизне</a>
    </p>
  </div>
)