import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { connect } from 'react-redux'
import Item from '../../containers/GoodItem/Item'

class Favorites extends Component {

  // (!) target < favorites-panel < favorites-item
  toggleFavorite (e) {
    e.currentTarget.parentElement.parentElement.classList.toggle('active')
  }

  // (?) TODO: метод из свойств перенесён в event компонента
  deleteCollectionFavorite (item) {
    this.props.deleteFavoriteCollection(item);
  }

  // (?) TODO: метод из свойств перенесён в event компонента
  deleteGroupDesigns (item) {
    this.props.deleteGroupDesigns(item);
  }

  render () {

    // TODO: bad code
    const favoritesCollections = this.props.testStore.favorites.collections;
    const favoritesDesigns = this.props.testStore.favorites.designs;
    const d = [];
    favoritesDesigns.forEach(function (value) {
      if (d[value.category] === undefined)
        d[value.category] = [];
      d[value.category].push(value);
    });
    const groupFavDesigns = Object.keys(d).map(function (key) {
      return d[key];
    });

    // маппинг дизайнов для получения массива их id
    let idArr = [];
    this.props.testStore.favorites.designs.map((el) => {
      return idArr.push(el.id)
    })

    return (
      <div className="fovorites-wr">
        <div className="favorites-top">
          <h1 className="h1">Избранное</h1>
          <p>Добавляйте понравившиеся коллекции и отдельные дизайны в избранное для удобства выбора.</p>
        </div>
        <div className="favorits-content">
          {groupFavDesigns.map((item, index) => {
            return (
              <div className="favorites-item" key={index}>
                <Row className="favorites-panel">
                  <Col xs="9" className="favorites-panel-left" onClick={this.toggleFavorite.bind(this)}>
                    <div className="icon"></div>
                    <div className="favorites-panel-title"><p>Дизайны {item[0].category} (<span
                      className="num">{item.length}</span>)</p></div>
                  </Col>
                  <Col xs="3" className="favorites-panel-right">
                    <div className="button-delete" onClick={this.props.deleteGroupDesigns.bind(this, item)}>Удалить
                    </div>
                  </Col>
                </Row>
                <Row className="favorites-list">
                  {item.map((item) => {
                    // return <Item item={item} key={item.id} favorite={true} design={true} {...this.props}/>
                    return <Item
                      item={item}
                      key={item.id}
                      design={true}
                      favorite={idArr.indexOf(item.id) !== -1}
                      disableFavActions={false}
                      {...this.props}/>
                  })}
                </Row>
              </div>
            )
          })}
          {favoritesCollections.map((item, index) => {
            // if (!item.attributes.friendly_designs) return '';
            let num = item.attributes.friendly_designs.data.length;
            return (
              <div className="favorites-item" key={index}>
                <Row className="favorites-panel">
                  <Col xs="10" className="favorites-panel-left" onClick={this.toggleFavorite.bind(this)}>
                    <div className="icon"></div>
                    <div className="favorites-panel-title"><p>Коллекция {item.attributes.name} (<span
                      className="num">{item.attributes.designs.data.length + num}</span>)</p></div>
                    <div className="favorites-panel-category">
                      <span>{item.mainType}</span><span>{item.attributes.is_new ? 'Новинка' : item.attributes.is_sale ? 'Распродажа' : ''}</span>
                    </div>
                  </Col>
                  <Col xs="2" className="favorites-panel-right">
                    <div className="button-delete" onClick={this.deleteCollectionFavorite.bind(this, item)}>Удалить
                    </div>
                  </Col>
                </Row>
                <Row className="favorites-list">
                  {item.attributes.designs.data.map((item) => {
                    return <Item
                      item={item}
                      key={item.id}
                      design={true}
                      favorite={idArr.indexOf(item.id) !== -1}
                      disableFavActions={false}
                      {...this.props}/>
                  })}
                  {item.attributes.friendly_designs.data.map((item) => {
                    return <Item item={item} key={item.id} design={true} disableFavActions={false} {...this.props}/>
                  })}
                </Row>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    testStore: state
  }),
  dispatch => ({
    deleteFavoriteCollection: (item) => {
      dispatch({type: 'DELETE_FAVORITE_COLLECTION', payload: item})
    },
    deleteGroupDesigns: (item) => {
      dispatch({type: 'DELETE_GROUP_DESIGNS', payload: item})
    }
  })
)(Favorites)