import {
  INITIAL_DETAIL_GOOD
} from '../constants/Goods'

export default function detailGoods (state = {}, action) {
  if (action.type === INITIAL_DETAIL_GOOD) {
    return action.payload;
  }
  return state;
}
