import React, { Component } from 'react';

class RandomBlockTwo extends Component{
  render(){
    return(
      <div className="random-block">
        <div className="random-block-border blue">
          <div className="random-block-item">
            <div className="random-block-item-text">
              <div>
              <p className="random-block-title">Оптовые цены</p>
              <p className="random-block-text">
                Мы закупаем ткани напрямую у производителей. Поэтому наши цены вам понравятся!
              </p>
          </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default RandomBlockTwo;
