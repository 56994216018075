import React, { Component } from 'react'
import { connect } from 'react-redux'
import remoteServer from '../../remoteServer'

const CITY_LIST = [
   {
      id: 'MOSCOW',
      name: 'Москва',
   },
   {
      id: 'RND',
      name: 'Ростов-на-Дону',
   },
   {
      id: 'KRASNODAR',
      name: 'Краснодар',
   },
]

class PopupCity extends Component {
   componentDidMount() {
      let cityId = localStorage.getItem('active_city_id')
      let city = CITY_LIST.filter((c) => c.id === cityId)
      if (city.length > 0) {
         this.props.addCity(city[0])
         this.close()
      }
   }
   // открытие/закрытие формы сообщения
   close() {
      document.querySelector('.popup-bg-city').classList.toggle('active')
      document.querySelector('.popup-block-city').classList.toggle('active')
   }
   addCityHandler(city) {
      localStorage.setItem('active_city_id', city.id)
      this.props.addCity(city)
      this.close()
   }
   render() {
      return (
         <div>
            <div className='popup-bg popup-bg-city active'></div>
            <div className='popup-block popup-block-city popup-block_small active'>
               <p className='popup-block-title'>Ваш город {this.props.city.name}</p>
               <div className='contact-form-tab'>
                  {CITY_LIST.map((city) => (
                     <div key={city.id} className={'item-tab active'} onClick={this.addCityHandler.bind(this, city)}>
                        <p>{city.name}</p>
                     </div>
                  ))}
               </div>
            </div>
         </div>
      )
   }
}

export default connect(
   (state) => ({
      city: state.city,
   }),
   (dispatch) => ({
      addCity: (item) => {
         dispatch({ type: 'ADD_CITY', payload: item })
      },
   })
)(PopupCity)
