import remoteServer from '../../remoteServer'

// (!) props.statusFound - поле redux-объекта, слабое место фильтра
// get-запрос на удалённый сервер, обработка данных (второй параметр func - как правило, это reducer)

import { URL_GOOD_GENERATOR } from '../../constants/UrlMap'

export const search = (value, func, props) => {
  props.statusFound('Expectation');

  remoteServer.get(value)
    .then((response) => {
      let data = response.data.data;

      if (data.length === 0) {
        props.statusFound('NotFound');
        return;
      }

      data.forEach(URL_GOOD_GENERATOR);

      func(data);
      props.statusFound('Found');
    })
    .catch((error) => {
      props.statusFound('NotFound');
    })
};