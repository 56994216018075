import React, { Component } from 'react'
import { Link } from 'react-router'
import { browserHistory } from 'react-router'
import translit from 'translitit-cyrillic-russian-to-latin';

import { search } from '../../actions/filter/search'


class Search extends Component {
  constructor (props) {
    super(props);
    this.state = {
      results: [],
      conditionText: null,
      dataIsFetched: false
    };
  }

  static searchByWord(word, ret) {
    if (typeof window.loadedSearchData === 'undefined') {
      window.loadedSearchData = [];

      let c = 0;

      let appendSearchData = (data) => {
        window.loadedSearchData = window.loadedSearchData.concat(data);
        if (--c === 0) searchFunc(window.loadedSearchData);
      };

      let loadSearchData = (url) => {
        c++;
        search(url, appendSearchData, {statusFound() {}});
      };

      loadSearchData('/cloths');
      loadSearchData('/leatherettes');
      loadSearchData('/leathers');
      loadSearchData('/grids');

      return;
    }

    if (word.length === 0)
      return ret('Введите запрос');

    let searchFunc = (data) => {
      let regexp = RegExp(word, 'ig');
      let regexpLatin = RegExp(translit(word), 'ig');

      let results = data.filter(
        i => i.attributes.name.match(regexp) || (i.attributes.type && i.attributes.type.data.attributes.name.match(regexp)) ||
             i.attributes.name.match(regexpLatin) || (i.attributes.type && i.attributes.type.data.attributes.name.match(regexpLatin))
      );

      if (results.length === 0)
        return ret('Ничего не найдено');

      return ret('Результатов: ' + results.length, results, regexp, regexpLatin);
    };

    searchFunc(window.loadedSearchData);
  }

  static renderHighlightResult(subject, regexp) {
    return subject.replace(regexp, letters => `<span class="highlight">${letters}</span>`);
  }

  search() {
    Search.searchByWord(this.getSearchValue(), this.setSearchState.bind(this));
  }

  getSearchValue() {
    return this.refs.searchInputRef.value.trim();
  }

  setSearchState(condText, results = [], regexp = null, regexpLatin) {
    this.setState({
      conditionText: condText,
      results: results,
      searchRegexp: regexp,
      searchRegexpLatin: regexpLatin
    });
  }

  openSearchPage(e) {
    e.preventDefault();
    this.props.close();
    browserHistory.push('/goods/search_results/' + this.getSearchValue());
  }

  // не пойму в какой момент можно получить доступ по рефу
  componentDidMount() {
    setTimeout(() => {
      if (this.refs.searchRef) {
        this.refs.searchRef.style.height = window.innerHeight + "px";
      }
      let self = this;
      window.addEventListener('resize', function() {
        if (self.refs.searchRef) {
          self.refs.searchRef.style.height = window.innerHeight + "px";
        }
      });
    }, 100)
  }

  componentDidUpdate() {
    if (this.props.opened) {
      if (!this.state.dataIsFetched) {
          this.search();
          this.setState({ dataIsFetched: true })
      }

      this.refs.searchInputRef.focus();
    }
  }

  render() {
    return (
      <div className={"search " + (this.props.opened ? 'active' : '')} ref="searchRef">
        <div className="close" role="button" onClick={this.props.close}/>
        <div className="search-field">
          <form className="input" onSubmit={this.openSearchPage.bind(this)}>
            <div className="before"/>
            <input
              type="text"
              ref="searchInputRef"
              onChange={this.search.bind(this)}
            />
            <div className="after"/>
          </form>
          <span className="condition">{ this.state.conditionText || 'Введите запрос' }</span>
          <div className="search-suggests">
            {this.state.results.map(suggest =>
              <Link to={suggest._url} onClick={this.props.close} key={suggest._url}>
                {
                  /[а-я]/i.test(suggest.attributes.name) ?
                  renderSearchName(suggest, this.state.searchRegexp) :
                  renderSearchName(suggest, this.state.searchRegexpLatin)
                }
                 { suggest.attributes.type ?
                    /[a-z]/i.test(suggest.attributes.type.data.attributes.name) ?
                      renderSearchType(suggest, this.state.searchRegexp) :
                      renderSearchType(suggest, this.state.searchRegexpLatin)
                   : null
                 }
              </Link>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const renderSearchName = (suggest, searchWord) => {
  return <div className="product-name" dangerouslySetInnerHTML={{__html: Search.renderHighlightResult(suggest.attributes.name, searchWord) }}/>
}

const renderSearchType = (suggest, searchWord) => {
  return <div className="product-type" dangerouslySetInnerHTML={{__html: Search.renderHighlightResult(suggest.attributes.type.data.attributes.name, searchWord) }}/>
}

export default Search
