import {
  ADD_GLOBAL_NEWS_GOODS,
} from '../../constants/Goods'

import { URL_GOOD_GENERATOR } from '../../constants/UrlMap'

export default function GlobalNews (state = [], action) {
  if (action.type === ADD_GLOBAL_NEWS_GOODS) {

    action.payload.forEach(URL_GOOD_GENERATOR);

    return [...action.payload];
  }
  return state;
}
