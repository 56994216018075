import React, { Component } from "react";
import { connect } from "react-redux";
import Filter from "../../containers/Filter";
import { ArtificialHOC } from "../../composed/composedArtificial";
import { addLeather, statusSearch } from "../../actions/filter/filter";
import { Helmet } from "react-helmet";

class ArtificialLeather extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>
            Купить искусственную кожу для мебели, каталог с ценами - компания
            "Вектор"
          </title>
          <meta
            name="description"
            content="Искусственная кожа  для обивки мебели от компании Вектор — лучшие цены и именитые производители. Покупайте кожзам для мебели оптом с доставкой по России."
          />
          <link
            rel="canonical"
            href="https://vektor.club/goods/artificial_leather"
          />
        </Helmet>
        <Filter
          filter={["price", "color", "compositionTop", "tags", "NewsOrRas"]}
          main={"ArtificialLeather"}
          box={this.props.Artificial}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    priceArtificial: state.filter.priceArtificial,
    Artificial: state.Artificial,
  }),
  (dispatch) => ({
    addArtifitial: (good) => dispatch(addLeather(good)),
    statusFound: (value) => dispatch(statusSearch(value)),
  })
)(ArtificialHOC(ArtificialLeather));
