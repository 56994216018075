import _ from 'underscore-contrib'
import {
  VISITED
} from '../constants/Visited'

export default function Visited (state = [], action) {
  if (action.type === VISITED) {
    if (_.contains(state, action.payload)) {
      return [...state]
    } else {
      return [...state, action.payload]
    }
  }
  return state;
}