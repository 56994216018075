import React, { Component } from 'react'
import { connect } from 'react-redux'
import Filter from '../../containers/Filter'
import { EcoHOC } from "../../composed/composedEco"
import { addEco, statusSearch } from '../../actions/filter/filter'

class EcoLeather extends Component {

  render () {

    return (
      <div>
        <Filter filter={['price', 'color', 'NewsOrRas']} main={'EcoLeather'} box={this.props.testStore.Eco}/>
      </div>
    )
  }
}

export default connect(
  state => ({
    testStore: state
  }),
  dispatch => ({
    addEco: (good) => {
      dispatch(addEco(good))
    },
    statusFound: (value) => {
      dispatch(statusSearch(value))
    }
  })
)(EcoHOC(EcoLeather))
