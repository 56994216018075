import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'

class Favorites extends Component {

  // количество дизайнов и коллекций в избранном
  getCountFavorites () {
    return this.props.testStore.favorites.collections.length + this.props.testStore.favorites.designs.length;
  }

  render () {

    return (
      <Link to="/favorites" onClick={() => { window.scrollTo(0, 0) }}>
        <div className="favorites-fix">
          <span className="favorits-fix-num">{this.getCountFavorites()}</span>
          <div className="favorits-fix-icon">
            <svg>
              <path fill="#fff" d="M4.013,18.012c-0.34-0.015-0.604-0.301-0.59-0.641c0.001-0.041,0.007-0.082,0.017-0.122l1.463-5.864
								L0.264,7.494C0.003,7.275-0.03,6.887,0.189,6.627C0.297,6.5,0.452,6.421,0.618,6.41l6.043-0.421l2.268-5.606
								c0.128-0.314,0.488-0.465,0.803-0.336c0.153,0.062,0.274,0.184,0.338,0.336l2.272,5.606l6.043,0.421
								c0.34,0.025,0.594,0.321,0.568,0.66c-0.013,0.165-0.092,0.317-0.219,0.424l-4.639,3.886l1.468,5.869
								c0.084,0.329-0.115,0.664-0.445,0.748c-0.164,0.042-0.339,0.015-0.482-0.076l-5.135-3.207l-5.14,3.207
								C4.257,17.984,4.136,18.017,4.013,18.012z M11.343,6.805L9.497,2.255l-1.84,4.549C7.569,7.023,7.365,7.172,7.13,7.19L2.223,7.535
								l3.767,3.152c0.182,0.15,0.262,0.392,0.205,0.621l-1.19,4.763l4.171-2.604c0.2-0.125,0.455-0.125,0.655,0l4.172,2.604l-1.19-4.763
								c-0.058-0.228,0.02-0.47,0.2-0.621l3.767-3.152C16.777,7.535,11.43,7.023,11.343,6.805z"/>
            </svg>
          </div>
          <span className="favorits-fix-p">Избранное</span>
        </div>
      </Link>
    )
  }
}

export default connect(
  state => ({
    testStore: state
  }),
  dispatch => ({})
)(Favorites)