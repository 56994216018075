import React, { Component } from 'react';

class RandomBlockThree extends Component{
  render(){
    return(
      <div className="random-block">
        <div className="random-block-border green">
          <div className="random-block-item">
            <div className="random-block-item-text">
              <div>
              <p className="random-block-title">Ткани в наличии!</p>
              <p className="random-block-text">Вам нужно много и прямо сейчас? Весь ассортимент уже на складе, готовый к отгрузке. Ждать не придется!</p>
          </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default RandomBlockThree;
