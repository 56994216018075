import React, { Component } from 'react'
import { Link } from 'react-router'
import { connect } from 'react-redux'
import { Row, Col } from 'reactstrap'
import RemoteServer, { staticStore } from '../../remoteServer';
import Helper from '../../helper'

const getPriceLinkUrl = '/price.xlsx'
const getRemaindersLinkUrl = '/remainders.xlsx'
let pricelistLink;
let remainderslistLink;

var today = new Date();
const redText = {color:'red'};

class MobileMenu extends Component {

  // открытие/закрытие мобильного меню
  static toggleMobileMenu () {
    Helper.toggleClass('.mobile-menu', 'active');
    Helper.toggleClass('.button-menu', 'active');
  }

  // (!) задержка 300 мс
  // открытие/закрытие формы сообщения и блокирование/разблокирование скролла страницы соответственно
  static showPopupForm () {
    setTimeout(() => {
      Helper.toggleClass('.popup-bg', 'active');
      Helper.toggleClass('.popup-block', 'active');
      Helper.toggleBodyScroll();
    }, 300);
  }

  componentDidMount() {
    RemoteServer.get(getPriceLinkUrl)
      .then(response => {
        if (response.status === 200) {
          pricelistLink = response.data;
          this.forceUpdate()
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  componentDidUpdate(prevProps) {
    if(prevProps.city !== this.props.city) {
      RemoteServer.get(`${getRemaindersLinkUrl}?city=${this.props.city}`)
      .then(response => {
        if (response.status === 200) {
          remainderslistLink = response.data;
          this.forceUpdate()
        }
      })
      .catch(err => {
        console.log(err)
      })
    }
  }

  render () {
    const linkOptions = {
      activeClassName: "active",
      onClick: () => {
        MobileMenu.toggleMobileMenu();
        window.scrollTo(0, 0)
      }
    };

    return (
      <div className="mobile-menu">
        <Row>
          <Col xs="6" className="mobile-menu-one">
            <p>Каталог</p>
            <ul>
              <li><Link {...linkOptions} to="/goods/furniture_fabrics">Мебельные ткани</Link></li>
              <li><Link {...linkOptions} to="/goods/artificial_leather">Искусственная кожа</Link></li>
              <li><Link {...linkOptions} to="/goods/natural_leather">Натуральная кожа</Link></li>
              <li><Link {...linkOptions} to="/goods/grid">Сетка</Link></li>
              {/*<li><Link {...linkOptions} to="/goods/eco_leather">Экокожа</Link></li>*/}
              <li><Link {...linkOptions} to="/goods/glue">Клей</Link></li>
              <li><Link {...linkOptions} to="/goods/accessories">Комплектующие</Link></li>
              <li><Link {...linkOptions} to="/goods/sale">Распродажа</Link></li>
              <li><Link {...linkOptions} to="/goods/3d"><span style={redText}>3D </span>Конструктор <sup style={redText}>NEW</sup></Link></li>

            </ul>
            {/*<a href={window.location}>Прайс-лист<span>.xls</span></a>*/}
          </Col>
          <Col xs="6" className="mobile-menu-two">
            <ul>
              <li><Link {...linkOptions} to="/about">О компании</Link></li>
              <li><Link {...linkOptions} to="/posts">Всё о тканях</Link></li>
              <li><Link {...linkOptions} to="/news">Новости</Link></li>
              <li><Link {...linkOptions} to="/contact">Контакты</Link></li>
              <li className="mobile-menu-two__price-item">{pricelistLink && <a className="price-list-link" href={staticStore + pricelistLink}>Прайс-лист<span>.xls</span></a>}</li>
              <li>{remainderslistLink && <a className="remainders-link" href={staticStore + remainderslistLink}>Остатки на {today.toLocaleDateString()}<span>.xlsx</span></a>}</li>
              <span className="specify-link specify-link_mobile">Уточняйте наличие у менеджера</span>
            </ul>

            <div className="mobile-menu-two-mess">
              <div className="arrow-button" onClick={MobileMenu.showPopupForm}><span className="button-mail">cообщение</span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}
export default connect(
  state => ({
    testStore: state,
    city: state.city.id
  }),
)(MobileMenu)
