import React from 'react'

export default class Helper {
    /**
     * Helper toggle class of element
     */
    static toggleClass(elem, className) {
        return document.querySelector(elem).classList.toggle(className);
    }

    /**
    * Helper delete class of Element
    */
    static removeClass(elem, className) {
      return document.querySelector(elem).classList.remove(className);
    }

    /**
     * Helper toggle body scroll
     */
    static toggleBodyScroll() {
        return Helper.toggleClass('body', 'no-scroll');
    }
}

export class PreloadImage extends React.Component {

  onLoad() {
    this.refs.img.classList.add('loaded');
  }

  render = () =>
    <img ref="img" {...this.props} className="has-preload" onLoad={this.onLoad.bind(this)} alt=""/>
}
