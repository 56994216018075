import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router";
import { DetailPostHOC } from "../../composed/composedDetailPost";
import { staticStore } from "../../remoteServer";

class DetailPosts extends Component {
  // dangerouslySetInnerHTML (innerHtml в реакте) - защита от XSS атак
  createMarkupTitle() {
    return { __html: this.props.testStore.posts[0].attributes.title };
  }

  createMarkupDetail() {
    return { __html: this.props.testStore.posts[0].attributes.content };
  }

  render() {
    const Detail = this.props.testStore.posts;

    // TODO: прикрутить лоадер
    // if (Detail.length > 0){
    return (
      <div>
        <Helmet>
          <title>Всё о тканях — Вектор</title>
          <meta
            name="description"
            content="Ткани и комплектующие для мягкой мебели - интересные факты"
          />
          <link rel="canonical" href="https://vektor.club/posts" />
        </Helmet>

        {Detail.map((item, index) => {
          return (
            <div key={index} className="detail-page-post">
              <div className="detail-page-mobil">
                <Link to="/posts">Всё о тканях</Link>
                <p>{item.attributes.date}</p>
              </div>
              <div className="detail-page-post-title">
                <h1
                  className="h1"
                  dangerouslySetInnerHTML={this.createMarkupTitle()}
                ></h1>
              </div>
              <div className="detail-page-post-content">
                <div className="detail-page-post-pich">
                  <img
                    src={staticStore + item.attributes.image}
                    alt={item.attributes.image_caption}
                  />
                  <p className="alt">{item.attributes.image_caption}</p>
                </div>
                <p className="description-text">{item.attributes.caption}</p>
                <div
                  className="detail-text"
                  dangerouslySetInnerHTML={this.createMarkupDetail()}
                ></div>
                <div className="avtor"></div>
                <div className="detail-page-soc">
                  <div className="item">
                    <a>
                      <svg
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill="#9fa8b1"
                          d="M13,7.429V10h3.5L16,13h-3v9H9v-9H7v-3l2.012,0.008V7.436c0-2.857,1.143-4.429,4.429-4.429L17,3v3h-2.571 C13.143,6,13,6.429,13,7.429z"
                        />
                      </svg>
                    </a>
                  </div>
                  <div className="item">
                    <a>
                      <svg
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill="#9fa8b1"
                          d="M11.701 18.771h1.437s.433-.047.654-.284c.21-.221.21-.63.21-.63s-.031-1.927.869-2.21c.887-.281 2.012 1.86 3.211 2.683.916.629 1.605.494 1.605.494l3.211-.044s1.682-.105.887-1.426c-.061-.105-.451-.975-2.371-2.76-2.012-1.861-1.742-1.561.676-4.787 1.469-1.965 2.07-3.166 1.875-3.676-.166-.48-1.26-.361-1.26-.361l-3.602.031s-.27-.031-.465.09c-.195.119-.314.391-.314.391s-.572 1.529-1.336 2.82c-1.623 2.729-2.268 2.879-2.523 2.699-.604-.391-.449-1.58-.449-2.432 0-2.641.404-3.75-.781-4.035-.39-.091-.681-.15-1.685-.166-1.29-.014-2.378.01-2.995.311-.405.203-.72.652-.539.675.24.03.779.146 1.064.537.375.506.359 1.636.359 1.636s.211 3.116-.494 3.503c-.495.262-1.155-.28-2.595-2.756-.735-1.26-1.291-2.67-1.291-2.67s-.105-.256-.299-.406c-.227-.165-.557-.225-.557-.225l-3.435.03s-.51.016-.689.24c-.166.195-.016.615-.016.615s2.686 6.287 5.732 9.453c2.79 2.902 5.956 2.715 5.956 2.715l-.05-.055z"
                        />
                      </svg>
                    </a>
                  </div>
                  <div className="item">
                    <a>
                      <svg
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill="#9fa8b1"
                          d="M14.505 17.44c1.275-.29 2.493-.794 3.6-1.49.834-.558 1.058-1.686.5-2.52-.536-.802-1.604-1.044-2.435-.553-2.55 1.595-5.79 1.595-8.34 0-.847-.534-1.965-.28-2.5.565 0 .002 0 .004-.002.005-.534.847-.28 1.966.567 2.5l.002.002c1.105.695 2.322 1.2 3.596 1.488l-3.465 3.465c-.707.695-.72 1.83-.028 2.537l.03.03c.344.354.81.53 1.274.53.465 0 .93-.176 1.275-.53L12 20.065l3.404 3.406c.72.695 1.87.676 2.566-.045.678-.703.678-1.818 0-2.52l-3.465-3.466zM12 12.388c3.42-.004 6.19-2.774 6.195-6.193C18.195 2.78 15.415 0 12 0S5.805 2.78 5.805 6.197c.005 3.42 2.776 6.19 6.195 6.192zm0-8.757c1.416.002 2.563 1.15 2.564 2.565 0 1.416-1.148 2.563-2.564 2.565-1.415-.002-2.562-1.148-2.565-2.564C9.437 4.78 10.585 3.633 12 3.63z"
                        />
                      </svg>
                    </a>
                  </div>
                  <div className="item">
                    <a>
                      <svg
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill="#9fa8b1"
                          d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
    // } else {
    //   return <div>load</div>
    // }
  }
}

export default connect(
  (state) => ({
    testStore: state,
  }),
  (dispatch) => ({
    addPost: (item) => {
      dispatch({ type: "ADD_POST_DETAIL", payload: item });
    },
  })
)(DetailPostHOC(DetailPosts));
