import {
  INITIAL_GLUE
} from '../constants/Glue'

export default function glue (state = [], action) {
  if (action.type === INITIAL_GLUE) {
    return action.payload;
  }
  return state;
}
