import React, { Component } from 'react'
import { connect } from 'react-redux'
import { search } from '../../actions/filter/search.js'
import _ from 'underscore-contrib'
import {
   addGlue,
   statusSearch,
   flamability,
   apply,
   changeViscosity,
   changeDryResidue,
   changePrice,
   addColor,
   addType,
   addTypeArti,
   addMechanismType,
   addFurnitire,
   changePriceArtificial,
   addLeather,
   changePriceNatiral,
   addColorNaural,
   addNatural,
   addColorEco,
   addColorArti,
   setColorSupports,
   changePriceEco,
   changePriceMechanisms,
   changePriceSupports,
   addEco,
   newsSaleFurni,
   newsSaleArti,
   newsSaleNatural,
   addMechanisms,
   addFurnitureSupports,
   setFurnitureType,
   setImportedOrDomestic,
   setMetallicOrPlastic,
   addTag,
} from '../../actions/filter/filter'
import {
   BlockColor,
   BlockType,
   BlockNewsOrRas,
   BlockCompositionTop,
   MobilPanel,
   Viscosity,
   DryResidue,
   Flammability,
   CustomizedRangePrice,
   BlockFurnitureType,
   BlockMechanismType,
   BlockDomesticOrImported,
   BlockMetallicOrPlastic,
   BlockTags,
} from './filterBlock'
import { Sort } from './Sort'
import { ResultFilter } from './ResultFilter'
import remoteServer from '../../remoteServer'

// значения фильтра при его сбросе
const applyFilter = {
   min_v: 50,
   max_v: 1500,
   filterNews: false,
   filterRas: false,
}

class Filter extends Component {
   constructor(props) {
      super(props)
      this.state = {
         isOpened: false,
         valueColor: '',
         isCheckYes: true,
         isCheckNo: true,
         min_v: this.props.viscosityMin,
         max_v: this.props.viscosityMax,
         color: [],
         typeCloth: [],
         leatheretteTypes: [],
         mechanismTypes: [],
         sortState: false,
         sortBy: 'name',
         filterNews: false,
         filterRas: false,
         // count: 14,
         cloth_count: 10000,
         activeFilter: null,
         tags: {},
      }
   }

   componentDidMount() {
      // закрытие выпадашек фильтра при проклике документа
      window.screen.width > 1120 &&
         (document.onclick = function (e) {
            if (!_.contains(e.path, document.querySelector('.filter-block-item.active'))) {
               document.querySelectorAll('.filter-block-item.active').forEach(function (block) {
                  if (e.currentTarget !== block) block.classList.remove('active')
               })
            }
         })

      // получение данных инициализации
      remoteServer.get(this.props.main === 'FurnitureSupports' ? '/furniture_colors' : '/colors').then((response) => {
         this.setState({ color: response.data.data })
      })

      remoteServer.get('/leatherette_types').then((response) => {
         this.setState({ leatheretteTypes: response.data.data })
      })

      remoteServer.get('/cloth_types').then((response) => {
         this.setState({ typeCloth: response.data.data })
      })

      remoteServer.get('/transformation_mechanisms/types').then((res) => {
         this.setState({ mechanismTypes: res.data.data })
      })

      // TAGS

      remoteServer.get('/cloth_tags').then((response) => {
         this.setState({ tags: {...this.state.tags, cloth: response.data.data }});
      })

      remoteServer.get('/leatherette_tags').then((response) => {
         this.setState({ tags: {...this.state.tags, leatherette: response.data.data }});
      })

      remoteServer.get('/leather_tags').then((response) => {
         this.setState({ tags: {...this.state.tags, leather: response.data.data }});
      })

      remoteServer.get('/transformation_mechanism_tags').then((response) => {
         this.setState({ tags: {...this.state.tags, transformation_mechanism: response.data.data }});
      })

      remoteServer.get('/furniture_support_tags').then((response) => {
         this.setState({ tags: {...this.state.tags, furniture_support: response.data.data }});
      })

      remoteServer.get('/glue_tags').then((response) => {
         this.setState({ tags: {...this.state.tags, glue: response.data.data }});
      }) 
   }

   componentWillUnmount() {
      this.handleApply(applyFilter)
   }

   // открытие/закрытие выпадающего блока
   toggleBlock(index, e) {
      e.stopPropagation();
      if(index === null)  {
         this.setState({ activeFilter:  null })
      } else {
         this.setState({ activeFilter: this.state.activeFilter === index ? null : index })
      }

      // e.currentTarget.classList.toggle('active');
      // document.querySelectorAll('.filter-block-item.active').forEach(function (block) {
      //    if (e.currentTarget !== block) block.classList.remove('active')
      // })

   }

   // составление url-запроса для тканей и кожи
   jsonUrl(color = [], type = [], price, baseUri, count, newsSale = [false, false], selectedTags) {
      let colorUrl = color
         .map((item, index) => {
            return `colors-${index}=${item.split('_').pop()}&`
         })
         .join('')
      let typeUrl = type
         .map((item, index) => {
            return `types-${index}=${item.split('_').pop()}&`
         })
         .join('')

      let selectedTagsUrl = selectedTags ? selectedTags
         .map((item, index) => {
            return `tag-${index}=${item.split('_').pop()}&`
         })
         .join('') : null;

      const { furnitureType, domesticOrImported, metallicOrPlastic } = this.props.testStore.filter

      // Для мягкой или для корпусной мебели
      const is_for_cushioned = furnitureType[0] ? '&is_for_cushioned=on' : ''
      const is_for_frame = furnitureType[1] ? '&is_for_frame=on' : ''

      // Отечественные или импортные
      const is_domestic = domesticOrImported[0] ? '&is_domestic=on' : ''
      const is_imported = domesticOrImported[1] ? '&is_imported=on' : ''

      // Новинки или распродажи
      const is_new = newsSale[0] ? '&is_new=on' : ''
      const is_sale = newsSale[1] ? '&is_sale=on' : ''

      // Металлические или пластиковые
      const is_metallic = metallicOrPlastic[0] ? '&is_metallic=on' : ''
      const is_plastic = metallicOrPlastic[1] ? '&is_plastic=on' : ''

      // Формируем строку запроса
      const query = [
         is_for_cushioned,
         is_for_frame,
         is_domestic,
         is_imported,
         is_new,
         is_sale,
         is_metallic,
         is_plastic,
      ].join('')

      return `/${baseUri}${count !== undefined ? '_' + count : ''}?${colorUrl}${typeUrl}${selectedTagsUrl || ''}price_min=${
         price[0]
      }&price_max=${price[1]}&sort_by=${this.state.sortBy}&sort_type=${this.state.sortState ? 'desc' : 'asc'}${query}`
      //&limit=${this.state.count}
   }

   // вызов jsonUrl внутри search
   searchByJsonUrl() {
      switch (this.props.main) {
         case 'FurnitureFabrics': {

            search(
               this.jsonUrl(
                  this.props.testStore.filter.color,
                  this.props.testStore.filter.type,
                  this.props.testStore.filter.priceFurnitire,
                  'cloths_by_filter',
                  undefined,
                  this.props.testStore.filter.newsSaleFurni,
                  this.props.testStore.filter.selectedTags
               ),
               this.props.addFurnit,
               this.props
            )
            break
         }

         case 'ArtificialLeather': {
            search(
               this.jsonUrl(
                  this.props.testStore.filter.colorArti,
                  this.props.testStore.filter.typeArti,
                  this.props.testStore.filter.priceArtificial,
                  'leatherettes_by_filter',
                  undefined,
                  this.props.testStore.filter.newsSaleArti,
                  this.props.testStore.filter.selectedTags
               ),
               this.props.addArtifitial,
               this.props
            )
            break
         }

         case 'NaturalLeather': {
            search(
               this.jsonUrl(
                  this.props.testStore.filter.colorNatural,
                  [],
                  this.props.testStore.filter.priceNatural,
                  'leathers_by_filter',
                  undefined,
                  this.props.testStore.filter.newsSaleNatural,
                  this.props.testStore.filter.selectedTags
               ),
               this.props.addNatural,
               this.props
            )
            break
         }
         
         case 'FurnitureSupports': {
            search(
               this.jsonUrl(
                  this.props.testStore.filter.colorSupports,
                  [],
                  this.props.testStore.filter.priceSupports,
                  'furniture_supports_by_filter',
                  undefined,
                  [],
                  this.props.testStore.filter.selectedTags
               ),
               this.props.initialSupports,
               this.props
            )
            break
         }

         case 'Mechanisms': {
            search(
               this.jsonUrl(
                  [],
                  this.props.testStore.filter.mechanismTypes,
                  this.props.testStore.filter.priceMechanisms,
                  'transformation_mechanisms_by_filter',
                  undefined,
                  [],
                  this.props.testStore.filter.selectedTags
               ),
               this.props.initialMechanisms,
               this.props
            )
            break
         }


         case 'Glue': {
            search(this.jsonGlueUrl(), this.props.initialGlue, this.props)
            break
         }
         case 'Grid': {
            console.log('main is Grid. Nothing to search due to the absence of search parametres')
            break
         }
         default:
            console.error('wrong main property')
      }
   }

   // составление url'а запроса для клея
   jsonGlueUrl() {

      const selectedTags = this.props.testStore.filter.selectedTags
      const tagsQuery = selectedTags ? selectedTags
         .map((item, index) => {
            return `&tag-${index}=${item.split('_').pop()}`
         })
         .join('') : null;


      const url = `/glues_by_filter?v_min=${this.props.testStore.filter.viscosity[0]}&v_max=${
         this.props.testStore.filter.viscosity[1]
      }&d_min=${this.props.testStore.filter.dry_residue[0]}&d_max=${this.props.testStore.filter.dry_residue[1]}&type=${
         this.props.testStore.filter.flammability
      }&sort_by=order&sort_type=${this.state.sortState ? 'desc' : 'asc'}${tagsQuery || ''}`
      return url
   }

   // открытие/закрытие мобильной версии фильтра
   toggleFilter() {
      this.setState({ isOpened: !this.state.isOpened })
   }

   // вызов toggleBlock, searchByJsonUrl и reducer'а по фильтру (changeColor, addType)
   fabricsCheck(block, e) {
      e.stopPropagation()
      
      // this.setState({ activeFilter:  null })

      let checkbox = e.currentTarget.previousElementSibling

      if (block === 'tags') {
         return setTimeout(() => {
            this.props.changeTag(checkbox.value)
            this.searchByJsonUrl()
         }, 10)
      }

      switch (this.props.main) {
         case 'FurnitureFabrics': {
            setTimeout(() => {
               if (block === 'color') {
                  this.props.changeColor(checkbox.value)
               } else if (block === 'type') {
                  this.props.changeType(checkbox.value)
               } 
               this.searchByJsonUrl()
            }, 10)
            break
         }
         case 'ArtificialLeather': {
            setTimeout(() => {
               if (block === 'color') {
                  this.props.changeColorArti(checkbox.value)
               } else if (block === 'type') {
                  this.props.addTypeArti(checkbox.value)
               }
               this.searchByJsonUrl()
            }, 10)
            break
         }
         case 'NaturalLeather': {
            setTimeout(() => {
               if (block === 'color') {
                  this.props.changeColorNatural(checkbox.value)
               }
               // else if (block === 'type') {
               //   this.props.addTypeArti(checkbox.value)
               // }
               this.searchByJsonUrl()
            }, 10)
            break
         }
         case 'FurnitureSupports': {
            setTimeout(() => {
               if (block === 'color') {
                  this.props.setColorSupports(checkbox.value)
               }
               this.searchByJsonUrl()
            }, 10)
            break
         }
         case 'Mechanisms': {
            setTimeout(() => {
               this.props.addMechanismType(checkbox.value)
               this.searchByJsonUrl()
            }, 10)
            break
         }
         default:
            console.error('wrong main property')
      }
   }

   // вызов jsonGlueUrl внутри search
   flammabilityToggle(value) {
      setTimeout(() => {
         search(this.jsonGlueUrl(), this.props.initialGlue, this.props)
      }, 200)
   }

   // вызов searchByJsonUrl с сортировкой по параметру type
   sort(type) {
      this.state.sortBy === type && this.setState({ sortState: !this.state.sortState })
      this.setState({ sortBy: type })
      setTimeout(() => {
         this.searchByJsonUrl()
      }, 10)
   }

   // вызов flammabilityToggle
   toggleState() {
      this.props.initialFlamability(((this.state.isCheckYes + 2 * this.state.isCheckNo) % 3) + 1)
      setTimeout(() => {
         this.flammabilityToggle(this.props.testStore.filter.flammability)
      }, 10)
   }

   // (!) задержка 200 мс
   // вызов toggleState
   toggleChange_yes() {
      this.setState({
         isCheckYes: !this.state.isCheckYes,
      })
      setTimeout(() => {
         this.toggleState()
      }, 200)
   }

   // (!) задержка 200 мс
   // вызов toggleState
   toggleChange_no() {
      this.setState({
         isCheckNo: !this.state.isCheckNo,
      })
      setTimeout(() => {
         this.toggleState()
      }, 200)
   }

   // сброс фильтра
   handleApply(apply) {
      const input = document.querySelectorAll('.filter-block-item input')

      // сброс выбранных checkbox'ов
      input.forEach(function (element) {
         element.checked = false
      }, this)

      this.setState(...apply)

      // сброс значений redux-объекта до исходных
      // равносильно this.forceUpdate()
      this.props.applyFilter()

      // получение данных о тканях и коже, заполнение redux-объекта
      setTimeout(() => {
         search(`/cloths`, this.props.addFurnit, this.props)
      }, 10)
      setTimeout(() => {
         search(`/leatherettes`, this.props.addArtifitial, this.props)
      }, 10)
      setTimeout(() => {
         search(`/leathers`, this.props.addNatural, this.props)
      }, 10)
      // search(`/ecoleathers`, this.props.addNatural, this.props)

      // получение данных о клее, заполнение redux-объекта
      setTimeout(() => {
         search(
            `/glues_by_filter?
					v_min=${this.props.testStore.filter.viscosity[0]}
					&v_max=${this.props.testStore.filter.viscosity[1]}
					&d_min=${this.props.testStore.filter.dry_residue[0]}
					&d_max=${this.props.testStore.filter.dry_residue[1]}
					&type=1&sort_by=order&sort_type=asc`,
            this.props.initialGlue,
            this.props
         )
      }, 10)
   }

   // вызов jsonGlueUrl внутри search
   onSliderChangeVis(value) {
      setTimeout(() => {
         search(this.jsonGlueUrl(), this.props.initialGlue, this.props)
      }, 10)
   }

   // вызов jsonGlueUrl внутри search
   onSliderChangeRes(value) {
      setTimeout(() => {
         search(this.jsonGlueUrl(), this.props.initialGlue, this.props)
      }, 10)
   }

   // обработчик изменения значений input'ов в модуле rc-silder. Вызов change-reducer'а
   changePrice(value) {
      switch (this.props.main) {
         case 'FurnitureFabrics':
            this.props.changePrice(value)
            break
         case 'ArtificialLeather':
            this.props.changePriceArtificial(value)
            break
         case 'NaturalLeather':
            this.props.changePriceNatiral(value)
            break
         case 'EcoLeather':
            this.props.changePriceEco(value)
            break
         case 'Mechanisms':
            this.props.changePriceMechanisms(value)
            break
         case 'FurnitureSupports':
            this.props.changePriceSupports(value)
            break
         default:
            console.error('wrong main property')
            break
      }
   }

   // вызов searchByJsonUrl, вызов change-reducer'а
   afterChangePrice(value) {
      setTimeout(() => {
         switch (this.props.main) {
            case 'FurnitureFabrics':
               this.props.changePrice(value)
               this.searchByJsonUrl()
               break
            case 'ArtificialLeather':
               this.props.changePriceArtificial(value)
               this.searchByJsonUrl()
               break
            case 'NaturalLeather':
               this.props.changePriceNatiral(value)
               this.searchByJsonUrl()
               break
            case 'Mechanisms':
               this.props.changePriceMechanisms(value)
               this.searchByJsonUrl()
               break
            case 'FurnitureSupports':
               this.props.changePriceSupports(value)
               this.searchByJsonUrl()
               break
            // case 'EcoLeather':
            //   search(this.jsonUrl(this.props.testStore.filter.colorEco, [], this.props.testStore.filter.priceEco, 'ecoleathers_by_filter'), this.props.addEco, this.props)
            //   break
            default:
               console.error('wrong main property')
         }
      }, 10)
   }

   changeFurnitureType(value) {
      const filter = this.props.testStore.filter
      switch (this.props.main) {
         case 'Mechanisms': {
            if (value === 'upholstered') {
               this.props.setFurnitureType([!filter.furnitureType[0], filter.furnitureType[1]])
            } else if (value === 'cabinet') {
               this.props.setFurnitureType([filter.furnitureType[0], !filter.furnitureType[1]])
            }
            break
         }
         default:
            console.error('wrong main property')
      }

      setTimeout(() => {
         this.searchByJsonUrl()
      }, 10)
   }

   changeDomesticOrImported(value) {
      const { filter } = this.props.testStore
      if (value === 'domestic')
         this.props.setImportedOrDomestic([!filter.domesticOrImported[0], filter.domesticOrImported[1]])
      else if (value === 'imported') {
         this.props.setImportedOrDomestic([filter.domesticOrImported[0], !filter.domesticOrImported[1]])
      }

      setTimeout(() => {
         this.searchByJsonUrl()
      }, 10)
   }

   changeMetallicOrPlastic(value) {
      const { filter } = this.props.testStore
      if (value === 'metallic')
         this.props.setMetallicOrPlastic([!filter.metallicOrPlastic[0], filter.metallicOrPlastic[1]])
      else if (value === 'plastic') {
         this.props.setMetallicOrPlastic([filter.metallicOrPlastic[0], !filter.metallicOrPlastic[1]])
      }

      setTimeout(() => {
         this.searchByJsonUrl()
      }, 10)
   }

   // вызов searchByJsonUrl, сохранение значений checkbox'ов фильтра (news и sale - новинки и распродажи соответственно) в redux-объекте
   changeNewsRas(value) {
      const filter = this.props.testStore.filter
      // if (value === 'news') {
      //   this.setState({filterNews: !this.state.filterNews})
      // } else if (value === 'sale') {
      //   this.setState({filterRas: !this.state.filterRas})
      // }

      switch (this.props.main) {
         case 'FurnitureFabrics': {
            if (value === 'news') {
               this.props.newsSaleFurni([!filter.newsSaleFurni[0], filter.newsSaleFurni[1]])
            } else if (value === 'sale') {
               this.props.newsSaleFurni([filter.newsSaleFurni[0], !filter.newsSaleFurni[1]])
            }
            break
         }
         case 'ArtificialLeather': {
            if (value === 'news') {
               this.props.newsSaleArti([!filter.newsSaleArti[0], filter.newsSaleArti[1]])
            } else if (value === 'sale') {
               this.props.newsSaleArti([filter.newsSaleArti[0], !filter.newsSaleArti[1]])
            }
            break
         }
         case 'NaturalLeather': {
            if (value === 'news') {
               this.props.newsSaleNatural([!filter.newsSaleNatural[0], filter.newsSaleNatural[1]])
            } else if (value === 'sale') {
               this.props.newsSaleNatural([filter.newsSaleNatural[0], !filter.newsSaleNatural[1]])
            }
            break
         }
         default:
            console.error('wrong main property')
      }

      setTimeout(() => {
         this.searchByJsonUrl()
      }, 10)
   }
   render() {
      // // позиционирование фильтра вверху страницы при скролле вниз
      window.addEventListener('scroll', (event) => {
         let a = document.querySelector('.filter-block')
         if (a) {
            let scrollFrom =
               document.querySelector('.page-title').scrollHeight + document.querySelector('header.header').scrollHeight
            a.classList.toggle('fixed', window.scrollY > scrollFrom)
         }
      })

      if (this.props.filter !== undefined && this.props.filter !== 'grid') {
         return (
            <div>
               <MobilPanel
                  {...this.props}
                  {...this.state}
                  sort={this.sort.bind(this)}
                  click={this.toggleFilter.bind(this)}
               />
               <div className={'filter-block ' + (this.state.isOpened ? 'show' : 'hide')}>
                  <div className='filter-block-wraper'>
                     {this.props.filter.map((item, index) => {
                        switch (item) {
                           case 'price':
                              return (
                                 <CustomizedRangePrice
                                    {...this.props}
                                    key={index}
                                    main={this.props.main}
                                    change={this.changePrice.bind(this)}
                                    afterChange={this.afterChangePrice.bind(this)}
                                 />
                              )
                           case 'color':
                              return (
                                 <BlockColor
                                    key={index}
                                    main={this.props.main}
                                    click={(e) => this.toggleBlock(index, e)}
                                    clickCheckbox={this.fabricsCheck.bind(this, 'color')}
                                    active={this.state.activeFilter === index}
                               
                                    {...this.props}
                                    {...this.state}
                                 />
                              )
                           case 'type':
                              return (
                                 <BlockType
                                    key={index}
                                    click={(e) => this.toggleBlock(index, e)}
                                    clickCheckbox={this.fabricsCheck.bind(this, 'type')}
                                    active={this.state.activeFilter === index}
                             
                                    {...this.state}
                                    {...this.props}
                                 />
                              )
                           case 'NewsOrRas':
                              return (
                                 <BlockNewsOrRas click={this.changeNewsRas.bind(this)} {...this.props} key={index} />
                              )
                           case 'furnitureType':
                              return (
                                 <BlockFurnitureType
                                    click={this.changeFurnitureType.bind(this)}
                                    key={index}
                                    {...this.props}
                                 />
                              )
                           case 'mechanismType':
                              return (
                                 <BlockMechanismType
                                    key={index}
                                    clickCheckbox={this.fabricsCheck.bind(this, 'type')}
                                    mechanismTypes={this.state.mechanismTypes}
                                    onClick={(e) => this.toggleBlock(index, e)}
                                    filter={this.props.testStore.filter}

                                    active={this.state.activeFilter === index}
                                 />
                              )
                           case 'domesticOrImported':
                              return (
                                 <BlockDomesticOrImported
                                    key={index}
                                    filter={this.props.testStore.filter}
                                    clickCheckbox={this.changeDomesticOrImported.bind(this)}
                                 />
                              )
                           case 'metallicOrPlastic':
                              return (
                                 <BlockMetallicOrPlastic
                                    key={index}
                                    filter={this.props.testStore.filter.metallicOrPlastic}
                                    clickCheckbox={this.changeMetallicOrPlastic.bind(this)}
                                 />
                              )
                           case 'compositionTop':
                              return (
                                 <BlockCompositionTop
                                    key={index}
                                    click={(e) => this.toggleBlock(index, e)}
                                    clickCheckbox={this.fabricsCheck.bind(this, 'type')}
                                    active={this.state.activeFilter === index}
                                    {...this.props}
                                    {...this.state}
                                 />
                              )
                           case 'DryResidue':
                              return (
                                 <DryResidue
                                    key={index}
                                    {...this.props}
                                    change={this.props.resizeDryResize.bind(this)}
                                    afterChange={this.onSliderChangeRes.bind(this)}
                                 />
                              )
                           case 'viscosity':
                              return (
                                 <Viscosity
                                    key={index}
                                    {...this.props}
                                    {...this.state}
                                    change={this.props.resizeViscosity.bind(this)}
                                    afterChange={this.onSliderChangeVis.bind(this)}
                                 />
                              )
                           case 'flammability':
                              return (
                                 <Flammability
                                    key={index}
                                    {...this.props}
                                    {...this.state}
                                    changeYes={this.toggleChange_yes.bind(this)}
                                    changeNo={this.toggleChange_no.bind(this)}
                                 />
                              )

                           case 'tags':
                              return (
                                 <BlockTags
                                    key={index}
                                    main={this.props.main}
                                    click={(e) => this.toggleBlock(index, e)}
                                    clickCheckbox={this.fabricsCheck.bind(this, 'tags')}
                                    active={this.state.activeFilter === index}
                              
                                    {...this.props}
                                    {...this.state}
                                 />
                              )
                           default:
                              return <div key={index}></div>
                        }
                     })}
                     <div className='filter-block-item reset-filter' onClick={this.handleApply.bind(this, applyFilter)}>
                        {/*<img*/}
                        {/*src="/img/svg/filter-reset.svg"*/}
                        {/*alt=""/>*/}
                        <div className='reset-filter-image' />
                        <p>Сбросить</p>
                     </div>
                  </div>
               </div>
               {this.props.main !== 'Glue' && <Sort {...this.props} {...this.state} sort={this.sort.bind(this)} />}
               <ResultFilter {...this.state} {...this.props} main={this.props.main} />
               {/*<ResultFilter {...this.state} {...this.props} main={this.props.main}  moreItems={this.moreItems.bind(this)}/>*/}
            </div>
         )
      } else if (this.props.filter === 'grid') {
         return (
            <div>
               <ResultFilter {...this.state} {...this.props} main={this.props.main} />{' '}
            </div>
         )
      } else {
         return <div></div>
      }
   }
}

export default connect(
   (state) => ({
      testStore: state,
   }),
   (dispatch) => ({
      initialGlue: (good) => {
         dispatch(addGlue(good))
      },
      initialFlamability: (item) => {
         dispatch(flamability(item))
      },

      statusFound: (value) => {
         dispatch(statusSearch(value))
      },

      applyFilter: () => {
         dispatch(apply())
      },

      resizeViscosity: (value) => {
         dispatch(changeViscosity(value))
      },
      resizeDryResize: (value) => {
         dispatch(changeDryResidue(value))
      },

      changePrice: (value) => {
         dispatch(changePrice(value))
      },
      changePriceArtificial: (value) => {
         dispatch(changePriceArtificial(value))
      },
      changePriceNatiral: (value) => {
         dispatch(changePriceNatiral(value))
      },
      changePriceEco: (value) => {
         dispatch(changePriceEco(value))
      },
      changePriceMechanisms: (value) => {
         dispatch(changePriceMechanisms(value))
      },
      changePriceSupports: (value) => {
         dispatch(changePriceSupports(value))
      },

      changeColor: (value) => {
         dispatch(addColor(value))
      },
      changeColorArti: (value) => {
         dispatch(addColorArti(value))
      },
      changeColorNatural: (value) => {
         dispatch(addColorNaural(value))
      },
      changeColorEco: (value) => {
         dispatch(addColorEco(value))
      },
      setColorSupports: (value) => {
         dispatch(setColorSupports(value))
      },

      changeType: (value) => {
         dispatch(addType(value))
      },
      addTypeArti: (value) => {
         dispatch(addTypeArti(value))
      },
      addMechanismType: (value) => {
         dispatch(addMechanismType(value))
      },
      initialMechanisms: (good) => {
         dispatch(addMechanisms(good))
      },
      initialSupports: (good) => {
         dispatch(addFurnitureSupports(good))
      },
      addFurnit: (good) => {
         dispatch(addFurnitire(good))
      },
      addArtifitial: (good) => {
         dispatch(addLeather(good))
      },
      addNatural: (good) => {
         dispatch(addNatural(good))
      },
      addEco: (good) => {
         dispatch(addEco(good))
      },

      newsSaleFurni: (value) => {
         dispatch(newsSaleFurni(value))
      },
      newsSaleArti: (value) => {
         dispatch(newsSaleArti(value))
      },
      newsSaleNatural: (value) => {
         dispatch(newsSaleNatural(value))
      },

      setMetallicOrPlastic: (value) => {
         dispatch(setMetallicOrPlastic(value))
      },
      setImportedOrDomestic: (value) => {
         dispatch(setImportedOrDomestic(value))
      },
      setFurnitureType: (value) => {
         dispatch(setFurnitureType(value))
      },
      changeTag: (value) => {
         dispatch(addTag(value))
      },
   })
)(Filter)
