import {
    ADD_FURNITURE_SUPPORT
  } from '../constants/Goods'
  
  export default function Supports (state = [], action) {
    if (action.type === ADD_FURNITURE_SUPPORT) {
      return action.payload;
    }
    return state;
  }
  