import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { browserHistory, Link } from "react-router";

// список комплектующих
const arr = [
  {
    name: "Клей",
    img: "/img/jpg/glue.jpg",
    link: "/goods/glue",
  },
  {
    name: "Нитки",
    img: "/img/jpg/thread-main.jpg",
    link: "/goods/thread",
  },
  {
    name: "Резинка",
    img: "/img/jpg/elastic-main.jpg",
    link: "/goods/elastic",
  },
  {
    name: "Механизмы трансформации",
    img: "/img/jpg/mechanisms-main.png",
    link: "/goods/transformation_mechanisms",
  },
];

class Accessories extends Component {
  render() {
    return (
      <Row className="catalog-block">
        <Helmet>
          <title>
            Купить фурнитуру для мягкой мебели, каталог с ценами - компания
            "Вектор"
          </title>
          <meta
            name="description"
            content="Фурнитура и комплектующие для мебели от компании Вектор — лучшие цены и именитые производители. Опт и доставка по России."
          />
          <link rel="canonical" href="https://vektor.club/goods/accessories" />
        </Helmet>

        {arr.map((item, index) => {
          return (
            <Col xs="3" key={index}>
              <div className="accessories-item">
                <Link to={item.link}>
                  <div
                    className="accessories-pich"
                    style={{ backgroundImage: "url(" + item.img + ")" }}
                  ></div>
                  <div className="goods-text">
                    <div className="good-text-left">
                      <p className="good-text-name">{item.name}</p>
                    </div>
                  </div>
                </Link>
              </div>
            </Col>
          );
        })}
      </Row>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    testStore: state,
    browserHistory,
  }),
  (dispatch) => ({})
)(Accessories);
