import {
  ADD_NEWS,
  INITIAL_DETAIL_NEWS
} from '../constants/News'

export default function news (state = [], action) {
  if (action.type === ADD_NEWS) {
    return [...action.payload];
  } else if (action.type === INITIAL_DETAIL_NEWS) {
    state = [];
    return [...state, action.payload];
  }
  return state;
}
