import {
  ADD_CITY
} from '../constants/City'

export default function news (state = [], action) {
  if (action.type === ADD_CITY) {
    return action.payload;
  }
  return state;
}
