// goods/Artificial
export const INITIAL_GOOD_ARTIFICIAL = 'INITIAL_GOOD_ARTIFICIAL'

// goods/Eco
export const ADD_GOOD_ECO = 'ADD_GOOD_ECO'

// goods/FurnitureFabrics
export const INITIAL_GOOD = 'INITIAL_GOOD'

export const INITIAL_MECHANISMS = 'INITIAL_MECHANISMS'

export const ADD_FURNITURE_SUPPORT = 'ADD_FURNITURE_SUPPORT'

// goods/Natural
export const INITIAL_GOOD_NATURAL = 'INITIAL_GOOD_NATURAL'

//goods/Grid добавил я
export const INITIAL_GOOD_GRID = 'INITIAL_GOOD_GRID'

// goods/NewsGoods
export const ADD_GLOBAL_NEWS_GOODS = 'ADD_GLOBAL_NEWS_GOODS'

// goods/SaleGoods
export const ADD_GLOBAL_SALE_GOODS = 'ADD_GLOBAL_SALE_GOODS'

// newsGoods
export const ADD_GOOD = 'ADD_GOOD'


export const INITIAL_DETAIL_GOOD = 'INITIAL_DETAIL_GOOD'
export const CLEAR_GOOD = 'CLEAR_GOOD'
