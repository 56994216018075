import React from 'react';

function customInput(props) {
    return (
      <div className={"input-wr" + (props.type === 'textarea' ? " multiline" : "") + (props.error ? " error" : "")}>
        {props.type === 'textarea' ?
          <textarea
            cols="30"
            rows="4"
            value={props.value}
            name={props.name}
            onChange={props.onChange}
            className="input"/>
          :
          <input
            type={props.type}
            name={props.name}
            checked={props.checked}
            value={props.value || ''}
            onChange={props.onChange}
            className="input"/>}
        <span
          className={!props.error && !props.value ? "placeholder empty" : "placeholder"}>{props.placeholder}</span>
        {props.error && !props.value ? <span className="error">Заполните это поле</span> : ''}
      </div>
    )
  }

export default customInput;
