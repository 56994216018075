import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { Container, Row, Col } from 'reactstrap'
import scroll from 'animated-scroll-to'
import './header.css'
import Helper from '../../helper'

class Header extends Component {

  // открытие/закрытие мобильного меню
  static openMobileMenu () {
    Helper.toggleClass('.mobile-menu', 'active');
    Helper.toggleClass('.button-menu', 'active');
  }

  // открытие/закрытие формы сообщения и блокирование/разблокирование скролла страницы соответственно
  static showPopupForm () {
    Helper.toggleClass('.popup-bg', 'active');
    Helper.toggleClass('.popup-block', 'active');
    Helper.toggleBodyScroll();
  }

  // открытие/закрытие блока со списком контактов
  static openPhone () {
    Helper.toggleClass('.popup-bg-phone', 'active');
    Helper.toggleClass('.popup-block-phone', 'active');
  }

  static openCity () {
    document.querySelector('.popup-bg-city').classList.toggle('active');
    document.querySelector('.popup-block-city').classList.toggle('active');
  }

  static openOrderPhone () {
    Helper.toggleClass('.popup-bg', 'active');
    Helper.toggleClass('.popup-block', 'message-active');
    Helper.toggleBodyScroll();
  }

  static handleLogoClick () {
    scroll(0, {speed: 400});
    [...document.querySelectorAll('header nav li a')].map((el) => el.style.color = 'white')
  }

  render () {
    const linkOptions = {
      activeClassName: "active",
      // скролл вверх страницы, изменение цвета у элементов навигации
      onClick: (e) => {
        window.scrollTo(0, 0);
      },
    }

    // this.props.testStore.routing.locationBeforeTransitions.pathname === '/' ?
    // [...document.querySelectorAll('header nav li a')].map((el) => el.style.color = 'white')
    //   : [...document.querySelectorAll('header nav li a:not(active)')].map((el) => el.style.color = 'black')
    return (

      <header className="header">
        <Container>
          <Row>
            <Col xs="5">
              <nav>
                <ul>
                  <li><Link {...linkOptions} to="/about">О компании</Link></li>
                  <li><Link {...linkOptions} to="/posts">Всё о тканях</Link></li>
                  <li><Link {...linkOptions} to="/news">Новости</Link></li>
                  <li><Link {...linkOptions} to="/contact">Контакты</Link></li>
                </ul>
              </nav>
              <div className="mobile-header">
                <Link
                  to="/"
                  onClick={Header.handleLogoClick}>
                  <img src="/img/svg/logo-vektor-mobile.svg" alt=""/>
                </Link>
                <div className="button-menu" onClick={Header.openMobileMenu}>
                  <svg>
                    <g>
                      <path fill="#788390" d="M0,0h28v2H0V0z"/>
                      <path fill="#788390" d="M0,8.001h28V10H0V8.001z"/>
                      <path fill="#788390" d="M0,16h28v2H0V16z"/>
                    </g>
                  </svg>
                </div>
                <div className="search-icon" role="button" onClick={this.props.open.bind(this)}/>
                <div className="button-mail white" role="button" onClick={Header.showPopupForm}>Сообщение</div>
                <div className="button-phone white" role="button" onClick={Header.openPhone}/>
                <div className="button-city white" role="button" onClick={Header.openCity}/>
              </div>
            </Col>
            <Col xs="1">
              <div className="search-icon" role="button" onClick={this.props.open.bind(this)}/>
            </Col>
            <Col xs="2">
              <div className="button-mail" role="button" onClick={Header.showPopupForm}>Сообщение</div>
            </Col>
            <Col xs="4">
                {
                  this.props.testStore.city.id ? 
                  <div className="header-contact">
                    <div className="header-contact-items">
                      <div className="button-city"></div>
                      {this.props.testStore.city.id == 'MOSCOW' ? <p><span onClick={Header.openCity}>Москва</span><div className="phone-container"><div className="button-phone"></div><a href="tel:+74955977686">+7 (495) 597-76-86</a></div></p> :
                        this.props.testStore.city.id == 'RND' ? <p><span onClick={Header.openCity}>Ростов-на-Дону</span><div className="phone-container"><div className="button-phone"></div><a href="tel:+78632693766">+7 (863) 269-37-66</a></div></p> :
                        <p><span onClick={Header.openCity}>Краснодар</span><div className="phone-container"><div className="button-phone"></div><a href="tel:+78612604758">+7 (861) 260-47-58</a></div></p>
                      }
                    </div>
                  </div> : ''
                }
            </Col>
          </Row>
        </Container>
        {/* <Search opened={this.state.searchOpened} close={this.toggleSearchModal.bind(this)} /> */}
      </header>
    )
  }
}

export default connect(
  (state) => ({
    testStore: state,
  })
)(Header)
