import {
  INIT_STORAGE_FAVORITES,
  ADD_FAVORITE_COLLECTION,
  DELETE_FAVORITE_COLLECTION,
  ADD_FAVORITE_DESIGN,
  DELETE_FAVORITE_DESIGN,
  DELETE_GROUP_DESIGNS
} from '../constants/Favorites'

const initialState = {
  collections: [],
  designs: []
}

export default function favorites (state = initialState, action) {
  const item = action.payload
  switch (action.type) {
    case INIT_STORAGE_FAVORITES:
      return {
        ...state,
        collections: JSON.parse(window.localStorage.getItem('collections')) || [],
        designs: window.localStorage.getItem('designs') ? JSON.parse(window.localStorage.getItem('designs')) : []
      }

    case ADD_FAVORITE_COLLECTION:
      state.collections.push(item)
      window.localStorage.setItem('collections', JSON.stringify(state.collections))
      break

    case DELETE_FAVORITE_COLLECTION:
      state.collections = state.collections.filter((itemArray) => {
        return itemArray.attributes.name !== item.attributes.name || itemArray.id !== item.id
      })
      window.localStorage.setItem('collections', JSON.stringify(state.collections))
      break

    case ADD_FAVORITE_DESIGN:
      state.designs.push(item)
      window.localStorage.setItem('designs', JSON.stringify(state.designs))
      break

    case DELETE_FAVORITE_DESIGN:
      state.designs = state.designs.filter((itemArray) => {
        return itemArray.attributes.name !== item.attributes.name || itemArray.id !== item.id
      })
      window.localStorage.setItem('designs', JSON.stringify(state.designs))
      break

    case DELETE_GROUP_DESIGNS:
      state.designs.splice(0, state.designs.length)
      window.localStorage.setItem('designs', JSON.stringify([]))
      break

    default:
  }
  return {...state};
}