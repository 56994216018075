import React from 'react'
import remoteServer from '../remoteServer'

export const NewsHOC = ComposedComponent => class extends React.Component {
  static displayName = 'ComponentNewsHOC';

  componentDidMount () {

    // TODO: initial_detail_news затирает весь массив в Store на один выбранный - исправить
    // if (this.props.news.length === 0)
      remoteServer.get('/news')
        .then((response) => {
          this.props.addNews(response.data.data)
        });
  }

  render () {
    return <ComposedComponent {...this.props} {...this.state} />;
  }
}