import React from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router'
import CustomInput from './custominput'
import { FormHOC } from './composedForm'

function CallForm(props) {

    if (props.status === 'submited' && !props.callForm) {
      return <span>Спасибо за ваше обращение. Менеджер свяжется с вами в ближайшее время.</span>
    } else if (props.status === 'submited' && props.callForm) {
      return <span>Ваша заявка принята. Менеджер перезвонит вам в ближайшее время.</span>
    } else if (props.status === 'error') {
      return <span>Ошибка! Попробуйте снова.</span>
    }

    return (
      <form className="send-message-form" onSubmit={props.submitCall}>
          <CustomInput
            type="text"
            onChange={props.handleChange}
            name="person"
            value={props.callData.person}
            placeholder="Контактное лицо *"
            error={props.callErrors.person} />
          <CustomInput
            type="text"
            onChange={props.handleChange}
            name="phone"
            value={props.callData.phone}
            placeholder="Телефон *"
            error={props.callErrors.phone} />
          <input
            type="checkbox"
            className="checkbox"
            onChange={props.onCheckClick}
            checked={props.callData.pdpCheck}
            id={props.mainForm ? 'main-checkbox' : 'checkbox'}/>
          <label
            onClick={props.onClick}
            htmlFor={props.mainForm ? 'main-checkbox' : 'checkbox'}></label>
          <Link className={props.callErrors.pdpCheck ? "link-processing-error" : "link-processing"}
                to="/personal-data-processing"
                onClick={props.close}>Даю согласие на обработку персональных данных</Link>
          <div className="clearfix"/>
            <button type="submit" className={"arrow-button" + (props.status === 'submiting' ? " no-action" : "")}>отправить</button>
       </form>
    )
}

const CallFormHOC = FormHOC(CallForm);
export default CallFormHOC;

CallFormHOC.propTypes = {
    callForm: PropTypes.bool,
    popUpCity: PropTypes.number
};