import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'

const spanbond = [
  {
    name: 'СПАНБОНД 60 г/м ш.1,6',
    plot: '60 г/м',
    shir: '1,6',
    color: 'черный, коричн.'
  },
  {
    name: 'СПАНБОНД 60 г/м ш.1,06',
    plot: '60 г/м',
    shir: '1,06',
    color: 'белый'
  },
  {
    name: 'СПАНБОНД 40 г/м ш.1,6',
    plot: '40 г/м',
    shir: '1,6',
    color: 'белый'
  },
  {
    name: 'СПАНБОНД 40 г/м ш.1,4',
    plot: '40 г/м',
    shir: '1,4',
    color: 'белый'
  },
  {
    name: 'СПАНБОНД 80 г/м ш.1,6',
    plot: '80 г/м',
    shir: '1,6',
    color: 'беж, коричн.'
  },
  {
    name: 'СПАНБОНД 100 г/м ш.1,6',
    plot: '100 г/м',
    shir: '1,6',
    color: 'черный'
  },
  {
    name: 'СПАНБОНД 17 г/м ш.2,2',
    plot: '17 г/м',
    shir: '2,2',
    color: 'белый'
  }
]

class Spanbond extends Component {
  componentDidMount () {
  }

  render () {
    return (
      <div className="thread-page">
        <Row className="acce-page-top">
          <Col xs="4" className="acce-page-top-img">
            <img src="/img/jpg/spanbond.jpg" alt=""/>
          </Col>
          <Col xs="8" className="aces-page-top-text">
            <div className="aces-page-top-title">
              <h1 className="h1">Спанбонд</h1>
            </div>
            <div className="aces-page-top-table">
              <table>
                <thead>
                <tr>
                  <td></td>
                  <td>Плотность</td>
                  <td>Ширина</td>
                  <td>Цвет</td>
                </tr>
                </thead>
                <tbody>
                {spanbond.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.plot}</td>
                      <td>{item.shir}</td>
                      <td>{item.color}</td>
                    </tr>
                  )
                })}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        <div className="acce-page-bottom">
          {/*<h1 className="h1"/>*/}
          <div className="acce-page-color opora"/>
        </div>
      </div>
    )
  }
}

export default Spanbond