import React from 'react'
import _ from 'underscore-contrib'
import remoteServer from '../remoteServer'

export var DetailPostHOC = ComposedComponent => class extends React.Component {
    static displayName = 'ComponentDetailPostHOC';
    componentDidMount(){
      const path = this.props.testStore.routing.locationBeforeTransitions.pathname;
      const pathArr = path.split('/');
      const id = _.last(pathArr)
      setTimeout(() => {
        remoteServer.get('/articles/' + id)
        .then((response) => {
          this.props.addPost(response.data.data)
        })
      },1000)
    }
    render() {
        return <ComposedComponent {...this.props} {...this.state} />;
    }
}