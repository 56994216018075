import React from 'react'
import { connect } from 'react-redux'
import Filter from '../../containers/Filter'
import { FurnitureHOC } from '../../composed/composedFurniture'
import { addFurnitire, statusSearch } from '../../actions/filter/filter'
import { Helmet } from 'react-helmet'

const FurnitureFabrics = ({ FurnitureFabrics }) => {
   return (
      <div>
         <Helmet>
            <title>Купить ткань для обивки мягкой мебели, каталог с ценами - компания "Вектор"</title>
            <meta
               name='description'
               content='Мебельные ткани от компании Вектор — лучшие цены и именитые производители. Покупайте ткани для обивки мягкой мебели оптом с доставкой по России.'
            />
            <link rel='canonical' href='https://vektor.club/goods/furniture_fabrics' />
         </Helmet>
         <Filter filter={['price', 'type', 'color', 'tags', 'NewsOrRas']} main='FurnitureFabrics' box={FurnitureFabrics} />
      </div>
   )
}

// class FurnitureFabrics extends Component {
//   render () {
//     return (
//       <div>
//         <Filter
//           filter={['price', 'type', 'color', 'NewsOrRas']}
//           main={'FurnitureFabrics'}
//           box={this.props.testStore.FurnitureFabrics}/>
//       </div>
//     )
//   }
// }

export default connect(
   (state) => ({
      priceFurnitire: state.filter.priceFurnitire,
      FurnitureFabrics: state.FurnitureFabrics,
   }),
   (dispatch) => ({
      initialGoods: (good) => dispatch(addFurnitire(good)),
      statusFound: (value) => dispatch(statusSearch(value)),
   })
)(FurnitureHOC(FurnitureFabrics))
