import React from "react";
import { IndexRoute, Route } from "react-router";
import Test from "../components/goods/3D/index";
import {
  Goods,
  FurnitureFabrics,
  NaturalLeather,
  ArtificialLeather,
  EcoLeather,
  Glue,
  Sale,
  Accessories,
  SearchResults,
  Thread,
  DetailedGood,
  Elastic,
} from "../components";
import Grid from "../components/goods/Grid";
import FurnitureSupports from "../components/goods/FurnitureSupports";
import Mechanisms from "../components/goods/Mechanisms";

const filterParams = {
  furnitureFabrics: ["price", "type", "color", "NewsOrRas"],
  artificialLeather: ["price", "color", "compositionTop", "NewsOrRas"],
  naturalLeather: ["price", "color", "NewsOrRas"],
  ecoLeather: ["price", "color", "NewsOrRas"],
  filterGlue: ["DryResidue", "viscosity", "flammability"],
  grid: ["price", "type", "color", "NewsOrRas"],
};

const GoodTypeRoute = (path, remotePath, title, component, filter) => {
  return (
    <Route path={path}>
      <IndexRoute component={component} title={title} filter={filter} />
      <Route
        path="(:collectionName)_:collectionId"
        remotePath={remotePath}
        component={DetailedGood}
        title={title}
      />
    </Route>
  );
};

export default (
  <Route path="goods" component={Goods}>
    <IndexRoute
      component={FurnitureFabrics}
      title={"Мебельные ткани"}
      filter={filterParams.furnitureFabrics}
    />

    {GoodTypeRoute(
      "furniture_fabrics",
      "cloths",
      "Мебельные ткани",
      FurnitureFabrics,
      filterParams.artificialLeather
    )}

    {GoodTypeRoute(
      "artificial_leather",
      "leatherettes",
      "Искусственная кожа",
      ArtificialLeather,
      filterParams.artificialLeather
    )}

    {GoodTypeRoute(
      "natural_leather",
      "leathers",
      "Натуральная кожа",
      NaturalLeather,
      filterParams.naturalLeather
    )}

    {GoodTypeRoute(
      "artificial_leather",
      "eco_leather",
      "Экокожа",
      EcoLeather,
      filterParams.ecoLeather
    )}

    {GoodTypeRoute("grid", "grids", "Сетка", Grid, filterParams.grid)}

    {GoodTypeRoute(
      "transformation_mechanisms",
      "transformation_mechanisms",
      "Механизмы-трансформации",
      Mechanisms
    )}

    {GoodTypeRoute(
      "furniture_supports",
      "furniture_supports",
      "Опоры",
      FurnitureSupports
    )}

    <Route
      path="glue"
      component={Glue}
      title={"Клей"}
      filter={filterParams.filterGlue}
      sort={"none"}
      componentName="Клей"
    />
    <Route
      path="accessories"
      component={Accessories}
      title={"Комплектующие"}
      sort={"none"}
      componentName="Комплектующие"
    />
    <Route
      path="sale"
      component={Sale}
      title={"Распродажа"}
      sort={"none"}
      componentName="Распродажа"
    />
    <Route
      path="grid"
      component={Grid}
      title={"Сетка"}
      sort={"none"}
      componentName="Сетка"
    />
    <Route
      path="search_results(/:queryText)"
      component={SearchResults}
      sort="none"
      title="Результаты поиска"
    />

    <Route path="thread" component={Thread} componentName="Нитки" />
    <Route
      path="opora"
      component={FurnitureSupports}
      componentName="Опоры для мебели"
      title={"Опоры для мебели"}
    />
    <Route path="3d" title={"3D-Конструктор онлайн"} component={Test} />
    {/*<Route path="goods/spanbond" component={Spanbond} componentName="Спанбонд"/>*/}
    <Route path="elastic" component={Elastic} componentName="Резинки" />
  </Route>
);
