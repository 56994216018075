import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Row } from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router";
import { PostHOC } from "../../composed/composedPost";
import { staticStore } from "../../remoteServer";

class Posts extends Component {
  render() {
    return (
      <div className="post-page">
        <Helmet>
          <title>Всё о тканях — Вектор</title>
          <meta
            name="description"
            content="Ткани и комплектующие для мягкой мебели - интересные факты"
          />
          <link rel="canonical" href="https://vektor.club/posts" />
        </Helmet>

        <div className="page-title">
          <h1 className="h1">Всё о тканях</h1>
        </div>
        <div className="post-page-wr">
          <Row>
            {this.props.posts.map((item, index) => {
              return (
                <div key={index} className="main-post">
                  <div className="mainpost-picher">
                    <Link to={"/posts/" + item.id}>
                      <img
                        src={staticStore + item.attributes.image}
                        alt={item.attributes.image_caption}
                      />
                    </Link>
                  </div>
                  <div className="main-post-text">
                    <Link to={"/posts/" + item.id} className="main-post-title">
                      {item.attributes.title}
                    </Link>
                    <p>{item.attributes.caption}</p>
                  </div>
                </div>
              );
            })}
          </Row>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    posts: state.posts,
  }),
  (dispatch) => ({
    addPost: (item) => {
      dispatch({ type: "ADD_POST", payload: item });
    },
  })
)(PostHOC(Posts));
