import React from 'react'
import { search } from '../actions/filter/search.js'

export var GlueHOC = ComposedComponent => class extends React.Component {
  static displayName = 'GlueHOC';

  componentDidMount () {

    if (this.props.testStore.glue.length === 0)
      setTimeout(() => {
        search(
          `/glues_by_filter?
					v_min=${this.props.testStore.filter.viscosity[0]}
					&v_max=${this.props.testStore.filter.viscosity[1]}
					&d_min=${this.props.testStore.filter.dry_residue[0]}
					&d_max=${this.props.testStore.filter.dry_residue[1]}
					&type=1&sort_by=order&sort_type=asc`,
          this.props.initialGlue,
          this.props)
      }, 10)
  }

  render () {
    return <ComposedComponent {...this.props} {...this.state} />;
  }
}