import React from 'react'
import remoteServer from '../../remoteServer'
import Helper from '../../helper'

const MAIL_PATTERN = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')

export const FormHOC = ComposedComponent => class extends React.Component {
  static displayName = "ComponentFormHOC";
  constructor(props) {
    super(props);

    this.state = {
      data: { organisation: '', name: '', phone: '', email: '', message: '', pdpCheck: false },
      callData: { person: '', phone: '', pdpCheck: false },
      status: 'no-status',
      errors: { name: false, email: false, message: false, pdpCheck: false, person: false, phone: false },
      callErrors: { person: false, phone: false, pdpCheck:false }
    }

    this.changeFormStatus = this.changeFormStatus.bind(this);
    this.clearInputs = this.clearInputs.bind(this);
    this.catchError = this.catchError.bind(this);
  }

  close () {
    Helper.toggleClass('.popup-bg', 'active');
    Helper.toggleClass('.popup-block', 'active');
    Helper.toggleClass('.popup-block', 'message-active');
    Helper.toggleBodyScroll();
  }

  changeFormStatus () {
    this.setState({status: 'submited'})
    return new Promise((resolve) => {
      setTimeout(() => {
        this.setState({status: 'no-status'});
        resolve();
      }, 1000 * 2);
    })
  }

  clearInputs (state) {
    const newState = state;
    for (let key in newState) {
      key === 'pdpCheck' ? newState[key] = false : newState[key] = '';
    }
    !this.props.callForm ? this.setState({ data: newState }) :  this.setState({ callData: newState });
  }

  catchError () {
    this.setState({ status: 'error' })
    return new Promise((resolve) => {
      setTimeout(() => {this.setState({ status: 'no-status' });
        resolve();
      }, 1000 * 2);
    })
  }

  handleChange (e) {
    const { name, value } = e.currentTarget;
    if (!this.props.callForm) {
      this.setState(({ data }) => ({
          data: {
              ...data,
              [name]: value
          }
      }));

    } else {
      this.setState(({ callData }) => ({
          callData: {
              ...callData,
              [name]: value
          }
      }));
    }
  }

  handleCheckboxClick (e) {
    const { name } = e.currentTarget;
    if (!this.props.callForm) {
      this.setState(({ data }) => ({
          data: {
              ...data,
              [name]: !this.state.data.pdpCheck
          }
      }));

    } else {
      this.setState(({ callData }) => ({
          callData: {
              ...callData,
              pdpCheck: !this.state.callData.pdpCheck
          }
      }));
    }
  }

  submitForm (e) {
    e.preventDefault();

    let errors = {
        name: this.state.data.name === '',
        email: !MAIL_PATTERN.test(this.state.data.email),
        message: this.state.data.message === '',
        pdpCheck: this.state.data.pdpCheck === false
    };
    this.setState({ errors: errors });

    if (errors.name || errors.email || errors.message || errors.pdpCheck) return;

    this.setState({status: 'submiting'});

    const data = { ...this.state.data, destination: this.props.cityId || this.props.popUpCity };  // сбор данных и отправка сообщения
    remoteServer.post('/message', data)

    .then((response) => { //смена статуса на "сабмит", через 3 секунды возвращение к исходному статусу
      this.changeFormStatus();
    })
    .then(() => { //очистка значений инпутов
      this.clearInputs(this.state.data);
    })
    .catch((error) => { //отлов ошибок
      this.catchError();
    })
  }

  submitCall (e) {
    e.preventDefault();

    let errors = {
      person: this.state.callData.person === '',
      phone: this.state.callData.phone === '',
      pdpCheck: this.state.callData.pdpCheck === false
    };
    this.setState({ callErrors: errors });

    if (errors.pdpCheck || errors.person || errors.phone) return;

    this.setState({status: 'submiting'});

    remoteServer.post('/callback', {
        destination: (this.props.cityId || this.props.popUpCity ||1),
        check: this.state.callData.pdpCheck,
        phone: this.state.callData.phone,
        name: this.state.callData.person,
    })

    .then((response) => {
        this.changeFormStatus();
    })
    .then(() => {
        this.clearInputs(this.state.callData);
    })
    .catch((error) => {
        this.catchError();
    });
  }

  render() {
    return (
      <ComposedComponent
          {...this.state}
          {...this.props}
          handleChange={this.handleChange.bind(this)}
          onCheckClick={this.handleCheckboxClick.bind(this)}
          submitForm={this.submitForm.bind(this)}
          submitCall={this.submitCall.bind(this)}
          close={this.close.bind(this)} />
    );
  }
}


