import React, { Component, Fragment } from 'react'
import { Row, Col } from 'reactstrap'
import { Link } from 'react-router'
import LogoImm from './img/nebo-logo.svg'
import './footer.css'
import Helper from '../../helper'

 const FooterLogo = () => <a className="footer-biz-logo" href="https://nebo.team" target="_blank" rel="noopener noreferrer">
    <span>
      <img src={LogoImm} alt=""/>
      <div className='logo-text'>nebo.team</div>
    </span>
  </a>

class Footer extends Component {

  // (!) задержка на мобильных экранах 300 мс
  // открытие/закрытие формы сообщения и блокирование/разблокирование скролла страницы соответственно
  open () {
    setTimeout(() => {
      Helper.toggleClass('.popup-bg', 'active');
      Helper.toggleClass('.popup-block', 'active');
      Helper.toggleBodyScroll();
    }, (window.innerWidth <= 760 ? 300 : 0))
  }

  render () {

    return (
      <footer>
        <Row>
          <Col xs="4">
            <p className="footer-p">Москва
              <span>
                <a href="tel:+74955977686">+7 (495) 597-76-86</a>
                <a href="tel:+74955977603">+7 (495) 597-76-03</a>
              </span>
            </p>
            <a
              className="footer-link button-mail"
              onClick={this.open.bind(this)}>Сообщение</a>
          </Col>

          <Col xs="4">
            <p className="footer-p">Ростов-на-Дону<span>
              <a href="tel:+78632693766">+7 (863) 269-37-66</a>
              <a href="tel:+78633030052">+7 (863) 303-00-52</a>
            </span></p>
            <span className="footer-processing">
              <Link to="/personal-data-processing">Соглашение</Link> об обработке персональных данных
            </span>
            <p className="footer-coo">© ООО «Вектор», 2017–2019</p>
          </Col>

          <Col xs="4">
            <p className="footer-p">Краснодар
              <span>
                <a href="tel:+78612604758">+7 (861) 260-47-58</a>
                <a href="tel:+79180802977">+7 (918) 080-29-77</a>
              </span>
            </p>
            <p className="footer-biz">
              
               <span>Интернет-проекты<br/>для бизнеса</span>
               <FooterLogo />
              {/* <a className="footer-biz-logo" href="https://immelman.ru" target="_blank" rel="noopener noreferrer"><img
                src={LogoImm} alt=""/></a>  */}
            </p>
          </Col>
        </Row>

        <Row className="footer-mobil">
          <Col xs="12">
            <div className="footer-mobil-items">
              <div className="span1">Москва:</div>
              <div className="span2">
                <a href="tel:+74955977686">+7 (495) 597-76-86</a>
                <a href="tel:+74955977603">+7 (495) 597-76-03</a>
              </div>
            </div>
          </Col>

          <Col xs="12">
            <div className="footer-mobil-items">
              <div className="span1">Ростов-на-Дону:</div>
              <div className="span2">
                <a href="tel:+78632693766">+7 (863) 269-37-66</a>
                <a href="tel:+78633030052">+7 (863) 303-00-52</a>
              </div>
            </div>
          </Col>

          <Col xs="12">
            <div className="footer-mobil-items">
              <div className="span1">Краснодар:</div>
              <div className="span2">
                <a href="tel:+78612604758">+7 (861) 260-47-58</a>
                <a href="tel:+79180802977">+7 (918) 080-29-77</a>
              </div>
            </div>
          </Col>

          <Col xs="12">
            <div className="arrow-button" onClick={this.open.bind(this)}>
              <span className="button-mail footer-link">Сообщение</span>
            </div>
            <span className="footer-processing">
              <Link to="/personal-data-processing">Соглашение</Link> об обработке персональных данных
            </span>
          </Col>

          <Col xs="12">
            <p className="footer-coo">© ООО «Вектор», 2017–2019</p>
          </Col>

          <Col xs="12">
            <div className="footer-biz">
            <FooterLogo />
             {/* <a href="http://immelman.ru" target="_blank" rel="noopener noreferrer"><img src={LogoImm} alt=""/></a> */}
              <span>Интернет-проекты<br/>для бизнеса</span>
            </div>
          </Col>
        </Row>
      </footer>
    )
  }
}

export default Footer
