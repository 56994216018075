import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import PrevSlider from '../PrevSlider'
import Item from '../../../containers/GoodItem/Item'
import GalleryItem from '../../../containers/GoodItem/GalleryItem'
import Header from '../../../containers/Header'
import remoteServer from '../../../remoteServer'
import { CollectionProps } from '../CollectionProps'

const designDescription = {
   naturalLeather:
      'Искусственная кожа-компаньон, идентичная по цвету, фактуре и степени блеска основной коллекции натуральной кожи.',
}

class DetailGood extends Component {
   constructor(props) {
      super(props)
      this.state = {
         status: 'load',
         paramGalleryTop: {
            centeredSlides: true,
            onInit: (swiper) => {
               this.galleryTop = swiper
               this.connectSliders()
            },
         },
         paramGalleryThumbs: {
            spaceBetween: 20,
            centeredSlides: true,
            slidesPerView: 'auto',
            touchRatio: 1,
            slideToClickedSlide: true,
            onInit: (swiper) => {
               this.galleryThumbs = swiper
               this.connectSliders()
            },
         },
         localTitle: '',
         tagsKeyWord: ''
      }
   }

   // инициализация слайдера
   connectSliders() {
      if (this.galleryTop && this.galleryThumbs) {
         this.galleryTop.params.control = this.galleryThumbs
         this.galleryThumbs.params.control = this.galleryTop
      }
   }

   // появление выпадающего списка, target + parent -> active
   toggleFullText(e) {
      e.currentTarget.classList.toggle('active')
      e.currentTarget.previousElementSibling.classList.toggle('active')
   }

   componentWillMount() {
      this.load(this.props)
   }

   componentWillReceiveProps(nextProps) {
      if (nextProps.routeParams.collectionId !== this.props.routeParams.collectionId) this.load(nextProps)
   }

   componentDidUpdate(prevProps) {
      if (prevProps.city !== this.props.city) {
         this.load(this.props)
      }
   }

   load(props) {
      const id = props.routeParams.collectionId
      this.setState({ status: 'load' })

      // получение новости по id
      remoteServer
         .get(`${props.route.remotePath}/${id}?city=${props.city}`)
         .then(({ data }) => {
            this.data = data.data

            const tagsKeyWord = (
               data.data.attributes.tags?.data.map((item) => item.attributes.name.toLowerCase()))
               ?.join(', ') || ''

            this.setState({
               status: 'ok',
               localTitle: `${this.data.attributes.name} - ${props.route.title}`,
               tagsKeyWord
            })
         })
         .catch((err) => {
            console.error(err)
            this.setState({ status: 'error' })
         })

      
   }


   render() {
      const { collectionName, collectionId } = this.props.params
      const { pathname } = this.props.location

      if (this.state.status === 'load') return <div className='loader' />

      if (this.state.status === 'error') return <div>Товар не найден</div>

      //очень плохое место, уже третий раз добавляю какой то функционал, такими темпами нужно уже весь компонент переделывать,
      //причем логика вывода единиц измерений уже противроечит сама себе
      const unitOfMeasure = () => {
         if (collectionName === 'Уругвай-(экокожа)' || collectionId == 19) {
            return <p className='about-price'>розничная цена за 1 п/м. Оптовые цены — по запросу.</p>
         } else {
            const unit =
               pathname.includes('natural_leather') || pathname.includes('grid')
                  ? 'м²'
                  : pathname.includes('transformation_mechanisms') || pathname.includes('furniture_support')
                  ? 'шт'
                  : 'п/м'
            return <p className='about-price'>розничная цена за 1 {unit}. Оптовые цены — по запросу.</p>
         }
      }

      const { attributes, id: goodId } = this.data

      const tagsKeyWord = this.state.tagsKeyWord;

      return (
         <div className='detail-page'>
            <Helmet>
               <link rel='canonical' href={'https://vektor.club' + pathname} />
            </Helmet>

            {this.state.localTitle && (
               <Helmet>
                  <title>{this.state.localTitle}</title>
                  <meta
                     name='description'
                     content={this.state.localTitle + ' по лучшим ценам. Опт и доставка по России.'}
                  />
                  {
                     !!tagsKeyWord && <meta
                     name='keywords'
                     content={tagsKeyWord}
                  />
                  }
                  {/* {goodId == 31 && (
                     <meta
                        name='keywords'
                        content='Основание кроватные-ортопедические основания, опоры, Ортопедические основания для кровати, кроватные основания разборные, Кроватные рамки с ламелями, латами на ножках, с механизмом подъёма с газ лифтом..., Механизмом подъема кроватной рамки'
                     />
                  )} */}

               </Helmet>
            )}
            <Row className='detail-page-top'>
               <Col xs='8'>
                  <Row className='detail-page-mobil-title'>
                     <Col xs='6'>
                        <div className='breadcrumbs'>
                           <a>Каталог</a>
                        </div>
                        <h1 className='colection-name'>{attributes.name}</h1>
                        <p className='type-status'></p>
                     </Col>
                     <Col xs='6'>
                        <div className='colection-title-price'>
                           {attributes.old_price > 0 ? <p className='priceSale'>{attributes.old_price + '₽'}</p> : ''}
                           {!attributes.is_sale && <p className='price'>{attributes.price} ₽</p>}
                        </div>
                        <p className='about-price'>розничная цена за 1 п/м. Оптовые цены — по запросу.</p>
                     </Col>
                  </Row>
                  <PrevSlider
                     detail={this.data}
                     parametrTop={this.state.paramGalleryTop}
                     parametrBottom={this.state.paramGalleryThumbs}
                  />

                  <CollectionProps data={attributes.icons.data} description={attributes.description} />
               </Col>

               <Col xs='4'>
                  <div className='colection-char'>
                     <div className='colection-title'>
                        <h1 className='colection-name'>{attributes.name}</h1>
                        <div className='colection-title-price'>
                           {attributes.old_price > 0 ? <p className='priceSale'>{attributes.old_price + '₽'}</p> : ''}
                           {!attributes.is_sale && <p className='price'>{attributes.price} ₽</p>}
                        </div>
                        {!attributes.is_sale ? (
                           unitOfMeasure()
                        ) : (
                           <p className='about-price'>Оптовые цены — по запросу.</p>
                        )}
                     </div>
                     <div className='colection-parametr'>
                        {!pathname.includes('transformation_mechanisms') && !pathname.includes('furniture_support') && (
                           <div>
                              <Row className='colection-parametr-item'>
                                 <Col xs='6'>
                                    <p>Состав ткани</p>
                                 </Col>
                                 <Col xs='6'>
                                    <p>{attributes.fabric_composition}</p>
                                 </Col>
                              </Row>
                              {this.props.location.pathname.indexOf('natural_leather') > -1 ? (
                                 <NaturalLeatherProps width={attributes.width} />
                              ) : (
                                 <AllComponentsProps width={attributes.width} />
                              )}
                              <Row className='colection-parametr-item'>
                                 <Col xs='6'>
                                    {this.props.params.type === 'natural_leather' ? (
                                       <p>Толщина ткани</p>
                                    ) : (
                                       <p>Плотность ткани</p>
                                    )}
                                 </Col>
                                 <Col xs='6'>
                                    <p>{attributes.fabric_count} г/м²</p>
                                 </Col>
                              </Row>
                              <Row className='colection-parametr-item'>
                                 <Col xs='6'>
                                    {this.props.params.type === 'natural_leather' ? (
                                       <p>Назначение</p>
                                    ) : (
                                       <p>Устойчивость к истиранию</p>
                                    )}
                                 </Col>
                                 <Col xs='6'>
                                    <p>{attributes.wear_resistance}</p>
                                 </Col>
                              </Row>
                           </div>
                        )}
                        {pathname.includes('furniture_support') && (
                           <div>
                              <Row className='colection-parametr-item'>
                                 <Col xs='6'>
                                    <p>Материал</p>
                                 </Col>
                                 <Col xs='6'>
                                    <p>{attributes.material || '-'}</p>
                                 </Col>
                              </Row>
                              <Row className='colection-parametr-item'>
                                 <Col xs='6'>
                                    <p>Высота</p>
                                 </Col>
                                 <Col xs='6'>
                                    <p>{attributes.height || '-'}</p>
                                 </Col>
                              </Row>
                              <Row className='colection-parametr-item'>
                                 <Col xs='6'>
                                    <p>Кол-во в упаковке</p>
                                 </Col>
                                 <Col xs='6'>
                                    <p>{attributes.amount || '-'}</p>
                                 </Col>
                              </Row>
                           </div>
                        )}
                        <Row className='colection-parametr-item'>
                           <Col xs='6'>
                              <p>Страна производства</p>
                           </Col>
                           <Col xs='6'>
                              <p>{attributes.country}</p>
                           </Col>
                        </Row>
                        {attributes.rapport && (
                           <Row className='colection-parametr-item'>
                              <Col xs='6'>
                                 <p>Раппорт</p>
                              </Col>
                              <Col xs='6'>
                                 <p>{attributes.rapport} см</p>
                              </Col>
                           </Row>
                        )}
                     </div>
                     <h5 className='feedback__title'>Нужна помощь в выборе?</h5>
                     <div className='feedback'>
                        <div className='button-mail' role='button' onClick={Header.showPopupForm}>
                           Сообщение
                        </div>
                        <div className='button-phone' role='button' onClick={Header.openOrderPhone}>
                           Заказать звонок
                        </div>
                     </div>
                  </div>
               </Col>
            </Row>

            <div className='block-design main-design'>
               {!pathname.includes('transformation_mechanisms') && !pathname.includes('furniture_support') && (
                  <div>
                     <div className='block-design-title'>
                        <h4>{attributes.name}</h4>
                        <div className='attention-remainder'>
                           *Остатки на сайте могут отличаться от остатков на складе. Уточняйте наличие у менеджера.
                        </div>
                     </div>

                     <Row className='my-gallery catalog-block' itemScope itemType='http://schema.org/ImageGallery'>
                        {attributes.designs.data.map((item) => {
                           return <GalleryItem item={item} key={item.id} design={true} {...this.props} />
                        })}
                     </Row>
                  </div>
               )}

               <div className='pswp' tabIndex='-1' role='dialog' aria-hidden='true'>
                  <div className='pswp__bg' />
                  <div className='pswp__scroll-wrap'>
                     <div className='pswp__container'>
                        <div className='pswp__item' />
                        <div className='pswp__item' />
                        <div className='pswp__item' />
                     </div>

                     {/*Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed.*/}
                     <div className='pswp__ui pswp__ui--hidden'>
                        <div className='pswp__top-bar'>
                           <div className='pswp__counter' />
                           <button className='pswp__button pswp__button--close' title='Close (Esc)' />
                           <button className='pswp__button pswp__button--share' title='Share' />
                           <button className='pswp__button pswp__button--fs' title='Toggle fullscreen' />
                           <button className='pswp__button pswp__button--zoom' title='Zoom in/out' />
                           <div className='pswp__preloader'>
                              <div className='pswp__preloader__icn'>
                                 <div className='pswp__preloader__cut'>
                                    <div className='pswp__preloader__donut' />
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className='pswp__share-modal pswp__share-modal--hidden pswp__single-tap'>
                           <div className='pswp__share-tooltip' />
                        </div>
                        <button
                           className='pswp__button pswp__button--arrow--left'
                           title='Previous (arrow left)'
                        ></button>
                        <button className='pswp__button pswp__button--arrow--right' title='Next (arrow right)'></button>
                        <div className='pswp__caption'>
                           <div className='pswp__caption__center' />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            {attributes.friendly_designs.data.length > 0 &&
            !pathname.includes('transformation_mechanisms') &&
            !pathname.includes('furniture_support') ? (
               <div className='block-design adition-design'>
                  <div className='block-design-title'>
                     <div className='block-design-title-left'>
                        <p className='block-design-title-status'>Дизайн-компаньон</p>
                     </div>
                     <div className='block-design-title-right'>
                        <p className='block-design-description'>
                           {this.props.location.pathname.includes('natural_leather') &&
                              designDescription.naturalLeather}
                        </p>
                     </div>
                  </div>
                  <Row className='catalog-block'>
                     {attributes.friendly_designs.data.map((item, index) => {
                        return <Item item={item} key={index} favorites={false} design={true} {...this.props} />
                     })}
                  </Row>
               </div>
            ) : (
               ''
            )}
         </div>
      )
   }
}

const NaturalLeatherProps = ({ width }) => {
   return (
      <Row className='colection-parametr-item'>
         <Col xs='6'>
            <p>Вид материала</p>
         </Col>
         <Col xs='6'>
            <p>{width}</p>
         </Col>
      </Row>
   )
}

const AllComponentsProps = ({ width }) => {
   return (
      <Row className='colection-parametr-item'>
         <Col xs='6'>
            <p>Ширина ткани</p>
         </Col>
         <Col xs='6'>
            <p>{width} см</p>
         </Col>
      </Row>
   )
}

export default connect((state) => ({
   testStore: state,
   city: state.city.id,
}))(DetailGood)
