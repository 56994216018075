import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router";
import { NewsHOC } from "../../composed/composedNews";

class News extends Component {
  // добавление/удаление стилей target'у
  handleMouseOver(id, e) {
    document.querySelector(`#post-data${id}`) &&
      document
        .querySelector(`#post-data${id}`)
        .classList.add("post-data-hover");
  }
  handleMouseOut(id, e) {
    document.querySelector(`#post-data${id}`) &&
      document
        .querySelector(`#post-data${id}`)
        .classList.remove("post-data-hover");
  }

  render() {
    return (
      <div className="news-page">
        <Helmet>
          <title>Новости — Вектор</title>
          <meta
            name="description"
            content="Новости компании и индустрии - ткани и комплектующие для мягкой мебели"
          />
          <link rel="canonical" href="https://vektor.club/news" />
        </Helmet>

        <div className="page-title">
          <h1 className="h1">Новости</h1>
        </div>
        <div className="news-page-wr">
          <Row className="post-wr">
            {this.props.news.map((item) => {
              return (
                <Col xs="6" key={item.id} className="post">
                  <p id={`post-data${item.id}`} className="post-data">
                    {item.attributes.date}
                  </p>
                  <Link
                    to={"/news/" + item.id}
                    className="post-name"
                    onMouseOver={this.handleMouseOver.bind(this, item.id)}
                    onMouseOut={this.handleMouseOut.bind(this, item.id)}
                  >
                    {item.attributes.title}
                  </Link>
                  <p className="post-des">{item.attributes.caption}</p>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    news: state.news,
  }),
  (dispatch) => ({
    addNews: (item) => {
      dispatch({ type: "ADD_NEWS", payload: item });
    },
  })
)(NewsHOC(News));
