import React from 'react'
import { connect } from 'react-redux'
import { GridHOC } from '../../composed/composedGrid'
import Filter from '../../containers/Filter'
import Helmet from 'react-helmet'
import { addGrid, statusSearch } from '../../actions/filter/filter'

const Grid = ({ Grid }) => (
   <div>
      <Helmet>
         <title>Купить сетку для мебели, каталог с ценами - компания "Вектор"</title>
         <meta
            name='description'
            content='Сетка для мебели от компании Вектор — лучшие цены и именитые производители. Опт и доставка по России.'
         />
         <link rel='canonical' href='https://vektor.club/goods/grid' />
      </Helmet>
      <Filter filter='grid' main='Grid' box={Grid} />
   </div>
)

export default connect(
   (state) => ({
      priceGrid: [0, 0],
      Grid: state.Grid,
   }),
   (dispatch) => ({
      addGrid: (good) => dispatch(addGrid(good)),
      statusFound: (value) => dispatch(statusSearch(value)),
   })
)(GridHOC(Grid))
