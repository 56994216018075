import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import MessageFormHOC from "../../containers/Form/MessageForm";
import { Map, Marker, MarkerLayout } from "yandex-map-react";

// указатель для карты
const MapBullet = () => <img alt="" src="/img/svg/map-pin2.svg" />;

const nbsp = "\u00A0";
// данные по городам
const cities = [
  {
    name: "Москва",
    street: `Московская область, г.${nbsp}Одинцово, ул.${nbsp}Луговая, 10`,
    phone: "+7 (495) 597-76-86, +7 (495) 597-76-03",
    map: [55.684404, 37.328538],
    email: "moskva@vektor.club",
  },
  {
    name: "Ростов-на-Дону",
    street: `пос. Кирпичный, ул.${nbsp}50${nbsp}лет${nbsp}Октября${nbsp}33/1`,
    phone: "+7 (863) 269-37-66, +7 (863) 303-00-52",
    map: [47.252363, 39.830749],
    email: "rostov@vektor.club",
  },
  {
    name: "Краснодар",
    street: `ул.${nbsp}Симферопольская,${nbsp}55/1`,
    phone: "+7 (861) 260-47-58, +7 (918) 080-29-77",
    map: [45.039834, 39.085875],
    email: "kyban@vektor.club",
  },
];
// конфиг карты
const mapOptions = {
  width: "100%",
  height: "100%",
  zoom: 14,
  state: { controls: ["zoomControl"], behaviors: ["drag", "dblClickZoom"] },
};

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      city: 0,
      prevCity: -1,
      formParam: {},
    };
  }

  // заполнени состояния компонента значением target-index'a
  toggleCity(city) {
    this.setState({
      prevCity: city === this.state.prevCity ? -1 : this.state.city,
      city: city,
    });
  }

  render() {
    return (
      <div className="page-contact">
        <Helmet>
          <link rel="canonical" href="https://vektor.club/contact" />
        </Helmet>
        <div className="page-title">
          <h1 className="h1">Контакты</h1>
        </div>
        <div className="page-contact-wr">
          <Row>
            {cities.map((item, index) => {
              return (
                <Col
                  key={"map_" + index}
                  xs="4"
                  className={
                    "item" +
                    (index === this.state.city && index !== this.state.prevCity
                      ? " active"
                      : "")
                  }
                >
                  <div className="item-ab">
                    <p
                      className="item-name"
                      onClick={this.toggleCity.bind(this, index)}
                    >
                      {item.name}
                    </p>
                    <p className="item-street">{item.street}</p>
                    <p className="item-phone">{item.phone}</p>
                    <a href={"mailto:" + item.email} className="email">
                      {item.email}
                    </a>
                    <div className="mobil-map">
                      <Map center={item.map} {...mapOptions}>
                        <Marker lat={item.map[0]} lon={item.map[1]}>
                          <MarkerLayout>
                            <MapBullet />
                          </MarkerLayout>
                        </Marker>
                      </Map>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
          <div className="map">
            <Map center={cities[this.state.city].map} {...mapOptions}>
              <Marker
                lat={cities[this.state.city].map[0]}
                lon={cities[this.state.city].map[1]}
              >
                <MarkerLayout>
                  <MapBullet />
                </MarkerLayout>
              </Marker>
            </Map>
          </div>
          <div className="contact-form">
            <div className="contact-form-title">
              <p>Написать сообщение</p>
            </div>
            <div className="contact-form-tab">
              <div
                className={
                  "item-tab" + (this.state.city === 0 ? " active" : "")
                }
                onClick={this.toggleCity.bind(this, 0)}
              >
                <p>В Москву</p>
              </div>
              <div
                className={
                  "item-tab" + (this.state.city === 1 ? " active" : "")
                }
                onClick={this.toggleCity.bind(this, 1)}
              >
                <p>В Ростов-на-Дону</p>
              </div>
              <div
                className={
                  "item-tab" + (this.state.city === 2 ? " active" : "")
                }
                onClick={this.toggleCity.bind(this, 2)}
              >
                <p>В Краснодар</p>
              </div>
            </div>
            <div className="contact-form-wr">
              <MessageFormHOC cityId={this.state.city + 1} mainForm={true} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    testStore: state,
  }),
  (dispatch) => ({})
)(Contact);
