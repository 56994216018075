import React from 'react'
import { search } from '../actions/filter/search.js'

export const FurnitureSupportsHOC = ComposedComponent => class extends React.Component {
  static displayName = 'ComponentFurnitureSupportsHOC';

  componentDidMount () {

    if (this.props.initialSupports.length === 0)
      setTimeout(() => {search(`/furniture_supports`, this.props.initialGoods, this.props)}, 10)
  }

  render () {
    return <ComposedComponent {...this.props} {...this.state} />;
  }
}