import {
    INITIAL_MECHANISMS
  } from '../constants/Goods'
  
  export default function Mechanisms (state = [], action) {
    if (action.type === INITIAL_MECHANISMS) {
      return action.payload;
    }
    return state;
  }
  