import React from 'react'
import { Link } from 'react-router'
import CustomInput from './custominput'
import { FormHOC } from './composedForm'

function MessageForm(props) {

    if (props.status === 'submited' && !props.callForm) {
      return <span>Спасибо за ваше обращение. Менеджер свяжется с вами в ближайшее время.</span>
    } else if (props.status === 'submited' && props.callForm) {
      return <span>Ваша заявка принята. Менеджер перезвонит вам в ближайшее время.</span>
    } else if (props.status === 'error') {
      return <span>Ошибка! Попробуйте снова.</span>
    }

    return (
      <form className="send-message-form" onSubmit={props.submitForm}>
          <CustomInput
            type="text"
            onChange={props.handleChange}
            name="organisation"
            value={props.data.organisation}
            placeholder="Название организации" />
          <CustomInput
            type="text"
            onChange={props.handleChange}
            name="phone"
            value={props.data.phone}
            placeholder="Телефон" />
          <CustomInput
            type="text"
            onChange={props.handleChange}
            name="name"
            value={props.data.name}
            placeholder="Контактное лицо *"
            error={props.errors.name} />
          <CustomInput
            type="e-mail"
            onChange={props.handleChange}
            name="email"
            value={props.data.email}
            placeholder="E-mail *"
            error={props.errors.email} />
          <CustomInput
            type="textarea"
            onChange={props.handleChange}
            name="message"
            value={props.data.message}
            placeholder="Сообщение *"
            error={props.errors.message} />
          <div><input
            type="checkbox"
            name="pdpCheck"
            className="checkbox"
            onChange={props.onCheckClick}
            checked={props.data.pdpCheck}
            id={props.mainForm ? 'main-checkbox' : 'checkbox'}/>
          <label
            onClick={props.onClick}
            htmlFor={props.mainForm ? 'main-checkbox' : 'checkbox'}></label>
          <Link className={props.errors.pdpCheck ? "link-processing-error" : "link-processing"}
                to="/personal-data-processing"
                onClick={props.close}>Даю согласие на обработку персональных данных</Link>
            </div>
          <div className="clearfix"/>
            <button type="submit" className={"arrow-button" + (props.status === 'submiting' ? " no-action" : "")}>отправить</button>
       </form>
    )
}

const MessageFormHOC = FormHOC(MessageForm);
export default MessageFormHOC;
