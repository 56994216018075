import React from 'react'
import _ from 'underscore-contrib'
import remoteServer from '../remoteServer'

export var DetailNewsHOC = ComposedComponent => class extends React.Component {
    static displayName = 'ComponentDetailNewsHOC';
    componentDidMount(){
      const path = this.props.testStore.routing.locationBeforeTransitions.pathname;
      const pathArr = path.split('/');
      const id = _.last(pathArr)
      remoteServer.get('/news/' + id)
        .then((response) => {
          this.props.addDetail(response.data.data)
        })
    }
    render() {
        return <ComposedComponent {...this.props} {...this.state} />;
    }
};
