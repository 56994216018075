import React from 'react'
import { connect } from 'react-redux'
import { staticStore } from '../../remoteServer'
import _ from 'underscore-contrib'
// import ImageLoader from 'react-imageloader'

import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import * as PhotoSwipe from 'photoswipe/dist/photoswipe.min'
import * as PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default.min'

// const localImage = () => (
//   <img
//     src='/img/jpg/load.gif'
//     alt="not found">
//
//   </img>
// )

const Icon = ({active}) => (
  <svg>
    {active ? (
      <path fill="#fff" d="M4.013,18.012c-0.34-0.015-0.604-0.301-0.59-0.641c0.001-0.041,0.007-0.082,0.017-0.122l1.463-5.864
			L0.264,7.494C0.003,7.275-0.03,6.887,0.189,6.627C0.297,6.5,0.452,6.421,0.618,6.41l6.043-0.421l2.268-5.606
			c0.128-0.314,0.488-0.465,0.803-0.336c0.153,0.062,0.274,0.184,0.338,0.336l2.271,5.606l6.043,0.421
			c0.34,0.025,0.594,0.321,0.568,0.66c-0.014,0.165-0.092,0.317-0.219,0.424l-4.639,3.886l1.467,5.869
			c0.084,0.329-0.114,0.664-0.444,0.748c-0.164,0.042-0.339,0.015-0.481-0.076l-5.136-3.207l-5.14,3.207
			C4.257,17.984,4.136,18.018,4.013,18.012z"/>
    ) : (
      <path fill="#fff" d="M4.013,18.012c-0.34-0.015-0.604-0.301-0.59-0.641c0.001-0.041,0.007-0.082,0.017-0.122l1.463-5.864L0.264,7.494
			C0.003,7.275-0.03,6.887,0.189,6.627C0.297,6.5,0.452,6.421,0.618,6.41l6.043-0.421l2.268-5.606
			c0.128-0.314,0.488-0.465,0.803-0.336c0.153,0.062,0.274,0.184,0.338,0.336l2.271,5.606l6.043,0.421
			c0.34,0.025,0.594,0.321,0.568,0.66c-0.014,0.165-0.092,0.317-0.219,0.424l-4.639,3.886l1.467,5.869
			c0.084,0.329-0.114,0.664-0.444,0.748c-0.164,0.042-0.339,0.015-0.481-0.076l-5.136-3.207l-5.14,3.207
			C4.257,17.984,4.136,18.018,4.013,18.012z M11.343,6.805l-1.846-4.55l-1.84,4.549C7.569,7.023,7.365,7.172,7.13,7.19L2.223,7.535
			l3.767,3.152c0.182,0.149,0.262,0.392,0.205,0.621l-1.19,4.762l4.171-2.604c0.2-0.125,0.455-0.125,0.655,0l4.172,2.604l-1.19-4.762
			c-0.058-0.229,0.021-0.471,0.201-0.621l3.766-3.152C16.777,7.535,11.43,7.023,11.343,6.805z"/>
    )}
  </svg>
)

class GalleryItem extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      image: this.props.item.attributes.image,
      favorite: this.props.favorite
    }
  }

  componentDidMount () {

    let initPhotoSwipeFromDOM = function (gallerySelector) {

      // parse slide data (url, title, size ...) from DOM elements
      // (children of gallerySelector)
      let parseThumbnailElements = function (el) {
        let thumbElements = el.childNodes,
          numNodes = thumbElements.length,
          items = [],
          figureEl,
          linkEl,
          size,
          item;

        for (let i = 0; i < numNodes; i++) {

          figureEl = thumbElements[i]; // <figure> element

          // include only element nodes
          if (figureEl.nodeType !== 1) {
            continue;
          }

          linkEl = figureEl.children[0]; // <a> element
          size = linkEl.getAttribute('data-size').split('x');

          // create slide object
          item = {
            src: linkEl.getAttribute('href'),
            w: parseInt(size[0], 10),
            h: parseInt(size[1], 10)
          };

          if (figureEl.children.length > 1) {
            // <figcaption> content
            item.title = figureEl.children[1].innerHTML;
          }

          if (linkEl.children.length > 0) {
            // <img> thumbnail element, retrieving thumbnail url
            item.msrc = linkEl.children[0].getAttribute('src');
          }

          item.el = figureEl; // save link to element for getThumbBoundsFn
          items.push(item);
        }

        return items;
      };

      // find nearest parent element
      let closest = function closest (el, fn) {
        return el && ( fn(el) ? el : closest(el.parentNode, fn) );
      };

      // triggers when user clicks on thumbnail
      let onThumbnailsClick = function (e) {
        e = e || window.event;
        e.preventDefault ? e.preventDefault() : e.returnValue = false;

        let eTarget = e.target || e.srcElement;

        if (eTarget.tagName === 'svg' ||
          eTarget.tagName === 'path' ||
          eTarget.className === 'action-favorits' ||
          eTarget.className === 'animated-tile')
          return;

        // find root element of slide
        let clickedListItem = closest(eTarget, function (el) {
          return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
        });

        if (!clickedListItem) {
          return;
        }

        // find index of clicked item by looping through all child nodes
        // alternatively, you may define index via data- attribute
        let clickedGallery = clickedListItem.parentNode,
          childNodes = clickedListItem.parentNode.childNodes,
          numChildNodes = childNodes.length,
          nodeIndex = 0,
          index;

        for (let i = 0; i < numChildNodes; i++) {
          if (childNodes[i].nodeType !== 1) {
            continue;
          }

          if (childNodes[i] === clickedListItem) {
            index = nodeIndex;
            break;
          }
          nodeIndex++;
        }

        if (index >= 0) {
          // open PhotoSwipe if valid index found
          openPhotoSwipe(index, clickedGallery);
        }
        return false;
      };

      // parse picture index and gallery index from URL (#&pid=1&gid=2)
      let photoswipeParseHash = function () {
        let hash = window.location.hash.substring(1),
          params = {};

        if (hash.length < 5) {
          return params;
        }

        let vars = hash.split('&');
        for (let i = 0; i < vars.length; i++) {
          if (!vars[i]) {
            continue;
          }
          let pair = vars[i].split('=');
          if (pair.length < 2) {
            continue;
          }
          params[pair[0]] = pair[1];
        }

        if (params.gid) {
          params.gid = parseInt(params.gid, 10);
        }

        return params;
      };

      let openPhotoSwipe = function (index, galleryElement, disableAnimation, fromURL) {
        let pswpElement = document.querySelectorAll('.pswp')[0],
          gallery,
          options,
          items;

        items = parseThumbnailElements(galleryElement);

        // define options (if needed)
        options = {

          // define gallery index (for URL)
          galleryUID: galleryElement.getAttribute('data-pswp-uid'),

          getThumbBoundsFn: function (index) {
            // See Options -> getThumbBoundsFn section of documentation for more info
            let thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
              pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
              rect = thumbnail.getBoundingClientRect();

            return {x: rect.left, y: rect.top + pageYScroll, w: rect.width};
          }

        };

        // PhotoSwipe opened from URL
        if (fromURL) {
          if (options.galleryPIDs) {
            // parse real index when custom PIDs are used
            // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
            for (let j = 0; j < items.length; j++) {
              if (items[j].pid === index) {
                options.index = j;
                break;
              }
            }
          } else {
            // in URL indexes start from 1
            options.index = parseInt(index, 10) - 1;
          }
        } else {
          options.index = parseInt(index, 10);
        }

        // exit if index not found
        if (isNaN(options.index)) {
          return;
        }

        if (disableAnimation) {
          options.showAnimationDuration = 0;
        }

        // Pass data to PhotoSwipe and initialize it
        gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
        gallery.init();
      };

      // loop through all gallery elements and bind events
      let galleryElements = document.querySelectorAll(gallerySelector);
      for (let i = 0, l = galleryElements.length; i < l; i++) {
        galleryElements[i].setAttribute('data-pswp-uid', i + 1);
        galleryElements[i].onclick = onThumbnailsClick;
      }

      // Parse URL and open gallery if it contains #&pid=3&gid=1
      let hashData = photoswipeParseHash();
      if (hashData.pid && hashData.gid) {
        openPhotoSwipe(hashData.pid, galleryElements[hashData.gid - 1], true, true);
      }
    };
    initPhotoSwipeFromDOM('.my-gallery');

    if (this.props.main)
      this.searchImg(this.props.testStore.filter.color)

    // Поиск элемента в фаворитном списке
    let array = (this.props.design ? this.props.testStore.favorites.designs : this.props.testStore.favorites.collections)
    let inFavorite = array.some(
      (item) => { return item.attributes.name === this.props.item.attributes.name && item.id === this.props.item.id }
    )
    this.setState({favorite: inFavorite})
  }

  searchImg (colorStore) {
    if (colorStore.length > 0) {
      const item = colorStore[0]
      const arr = item.split('_');
      const num = _.last(arr)

      var designs_img = []
      if (this.props.item.attributes.designs.data.length !== 0)
        designs_img = _.filter(this.props.item.attributes.designs.data,
          function search (item) {
            if (item.attributes.color)
              if (item.attributes.color.data.id === num) return item
          })

      var friendly_designs_img = []
      if (this.props.item.attributes.friendly_designs.data !== [])
        friendly_designs_img = _.filter(this.props.item.attributes.friendly_designs.data,
          function search (item) { if (item.id === num) return item })

      if (designs_img.length > 0) {
        this.setState({image: designs_img[0].attributes.image})
      } else if (friendly_designs_img.length > 0) {
        this.setState({image: friendly_designs_img[0].attributes.image})
      } else {
        this.setState({image: this.props.item.attributes.image})
      }

    } else {
      this.setState({image: this.props.item.attributes.image})
    }
  }

  toggleFavorite (item, e) {
    const tile = e.currentTarget.lastChild
    if (this.state.favorite) {
      tile.style.cssText = ''

      if (item.type === "design")
        this.props.deleteFavoriteDesign(item)
      else
        this.props.deleteFavoriteCollection(item)
    }
    else {
      // effect adding:
      let positionTo = document.querySelector(".favorites-fix").getBoundingClientRect()
      tile.classList.add("active")
      let top = positionTo.top - tile.getBoundingClientRect().top
      let left = positionTo.left - tile.getBoundingClientRect().left
      tile.style.cssText = `top:${top}px;left:${left}px;`

      setTimeout(() => {
        tile.classList.remove("active")
        if (item.type === "design")
          this.props.addFavoriteDesign(item)
        else
          this.props.addFavoriteCollection(item)
      }, 500)
    }
    this.setState({favorite: !this.state.favorite})
  }

  componentWillReceiveProps (nextProps) {
    this.searchImg(nextProps.testStore.filter.color)
  }

  handleImgLoaded (e) {

    // img.item-picher < span < a < figure.item-c
    let targetParent = e.target.parentNode.parentNode.parentNode;
    (targetParent.classList.contains('item-c')) && targetParent.classList.add('item-c-loaded');

    let loadedImagesCount = document.querySelectorAll('.item-c-loaded').length;
    let imagesCount = document.querySelectorAll('.item-c img').length;
    let loader = document.querySelector('.result-filter-loader');
    loader.style.opacity = 1;

    // loader width
    (loadedImagesCount && imagesCount) &&
    (loader.style.width = (`${(loadedImagesCount / imagesCount) * 100}%`));

    // on all loading
    if (imagesCount === loadedImagesCount) {
      loader.style.opacity = 0;
      [...document.querySelectorAll('.item-c .random-block')].map((el) => {
        return el.parentElement.classList.add('item-c-loaded');
      })
    }
  }

  checkReminders(item) {
    let data = item.attributes.remainders.data
    if (data.length === 1) {
      return data[0].attributes.value
    }
    data = data.filter(d => {
      return d.attributes.city.replace('CityEnum.', '') === this.props.city
    })
    return data[0].attributes.value
  }

  render () {
    return (

      <figure className="col-3 item-c" itemProp="associatedMedia" itemScope itemType="http://schema.org/ImageObject">
        <a
          href={this.props.itemIsStatic ? this.state.image : staticStore + this.state.image}
          itemProp="contentUrl"
          data-size="1024x1024">

          <span>
            <img
              className="item-picher"
              src={this.props.itemIsStatic ? this.state.image : staticStore + this.state.image}
              onLoad={this.handleImgLoaded.bind(this)}
              alt="not found"/>
          </span>

          {/*<ImageLoader*/}
          {/*src={staticStore + this.state.image}*/}
          {/*itemProp="thumbnail"*/}
          {/*preloader={localImage}>*/}
          {/*Image not found =(*/}
          {/*</ImageLoader>*/}
        </a>
        <figcaption itemProp="caption description">
        <div className="slider-info-block">
          {this.props.item.attributes.name}
          <div className="design-item-remainder">В наличии: {this.checkReminders(this.props.item)} п/м</div>
        </div>
        </figcaption>
        {this.props.disableFavActions ? "" : (
          <div className={"action-favorits" + (this.state.favorite ? " active" : "")}
               onClick={this.toggleFavorite.bind(this, this.props.item)}>
            <Icon active={this.state.favorite}/>
            <div className="animated-tile"/>
          </div>
        )}
        <p>
          <span className="design-item-name">{this.props.item.attributes.name}</span>
          <span className="design-item-remainder">В наличии: {this.checkReminders(this.props.item)} п/м</span>
        </p>
      </figure>
    )
  }
}

export default connect(
  state => ({
    testStore: state
  }),
  dispatch => ({
    addFavoriteCollection: (item) => {
      dispatch({type: 'ADD_FAVORITE_COLLECTION', payload: item})
    },
    deleteFavoriteCollection: (item) => {
      dispatch({type: 'DELETE_FAVORITE_COLLECTION', payload: item})
    },
    addFavoriteDesign: (item) => {
      dispatch({type: 'ADD_FAVORITE_DESIGN', payload: item})
    },
    deleteFavoriteDesign: (item) => {
      dispatch({type: 'DELETE_FAVORITE_DESIGN', payload: item})
    }
  })
)(GalleryItem)
