import React, { Component } from 'react'
import Swiper from 'react-id-swiper'
import { staticStore } from '../../remoteServer'
import { PreloadImage } from '../../helper'

// TODO: пустые элементы слайдера не нужно отображать

export default class PrevSlider extends Component {
   constructor(props) {
      super(props)
      this.state = {}
   }

   render() {
      const { attributes, type } = this.props.detail

      const rawSlides =
         type === 'furniture_support' || type === 'transformation_mechanism'
            ? [attributes.image, attributes.additional_image_1, attributes.additional_image_2, attributes.additional_image_3]
            : [attributes.image, attributes.flat_image, attributes.product_image]

      let slides = rawSlides.filter((slide) => !!slide)

      return (
         <div>
            <div className='slider-top'>
               <Swiper {...this.props.parametrTop}>
                  {slides.map((slide) => (
                     <div className='item' key={slide}>
                        <PreloadImage src={staticStore + slide} alt='' />
                     </div>
                  ))}
               </Swiper>
            </div>
            <div className='slider-bottom'>
               <Swiper {...this.props.parametrBottom}>
                  {slides.map((slide) => (
                     <div className='item' key={slide}>
                        <PreloadImage width='100' src={staticStore + slide} alt='' />
                     </div>
                  ))}
               </Swiper>
            </div>
         </div>
      )
   }
}
