import {
  DRY_RESIDUE,
  VISCOSITY,
  COLOR,
  FLAMMABILITY,
  APPLY_FILTER,
  PRICE_FURNITURE,
  PRICE_ARTIFICIAL,
  PRICE_NATURAL,
  PRICE_ECO,
  PRICE_MECHANISMS,
  PRICE_SUPPORTS,

  ADD_COLOR,
  ADD_COLOR_ARTIFICIAL,
  ADD_COLOR_NATURAL,
  ADD_COLOR_ECO,
  SET_COLORS_SUPPORTS,

  ADD_TYPE,
  ADD_TYPE_ARTIFICIAL,
  ADD_MECHANISM_TYPE,

  NEWS_SALE_FURNI,
  NEWS_SALE_ARTI,
  NEWS_SALE_NATURAL,
  SET_FUNTITURE_TYPE,
  SET_DOMESTIC_OR_IMPORTED,
  SET_METALLIC_OR_PLASTIC,

  ADD_TAG
} from '../constants/Filter'
import _ from 'underscore-contrib'
import remoteServer from '../remoteServer'

const initialState = {
  dry_residue: [5, 100],
  viscosity: [10, 3000],
  flammability: 1,

  color: [],
  colorArti: [],
  colorNatural: [],
  colorEco: [],
  colorSupports : [],

  type: [],
  typeArti: [],
  mechanismTypes : [],

  newsSaleFurni: [false, false],
  newsSaleArti: [false, false],
  newsSaleNatural: [false, false],
  
  sliderPriceInitFurniture: [],
  sliderPriceInitArtificial: [],
  sliderPriceInitNatural: [],
  sliderPriceInitMechanisms: [],
  sliderPriceInitSupports: [],
  
  furnitureType : [false, false],
  domesticOrImported : [false, false],
  metallicOrPlastic : [false, false],

  priceFurnitire: [],
  priceArtificial: [],
  priceNatural: [],
  priceGrid: [],
  priceMechanisms : [],
  priceSupports : [],
  priceEco: [50, 5000],

  selectedTags: []
}

remoteServer.get('/cloths/price_range')
  .then((response) => {
    initialState.sliderPriceInitFurniture = [response.data.price_min, response.data.price_max];
    initialState.priceFurnitire = [response.data.price_min, response.data.price_max]
  })

remoteServer.get('/leatherette/price_range')
  .then((response) => {
    initialState.sliderPriceInitArtificial = [response.data.price_min, response.data.price_max];
    initialState.priceArtificial = [response.data.price_min, response.data.price_max]
  })

remoteServer.get('/leather/price_range')
  .then((response) => {
    initialState.sliderPriceInitNatural = [response.data.price_min, response.data.price_max];
    initialState.priceNatural = [response.data.price_min, response.data.price_max]
  })

remoteServer.get('/transformation_mechanisms/price_range')
  .then((response) => {
    initialState.sliderPriceInitMechanisms  = [response.data.price_min, response.data.price_max]
    initialState.priceMechanisms  = [response.data.price_min, response.data.price_max]
  })

remoteServer.get('/furniture_supports/price_range')
  .then((response) => {
    initialState.sliderPriceInitSupports  = [response.data.price_min, response.data.price_max]
    initialState.priceSupports  = [response.data.price_min, response.data.price_max]
  })

export default function filter (state = initialState, action) {
  switch (action.type) {

    case DRY_RESIDUE:
      return {...state, dry_residue: action.payload}
    case VISCOSITY:
      return {...state, viscosity: action.payload}
    case COLOR:
      return {...state, color: action.payload}
    case FLAMMABILITY:
      return {...state, flammability: action.payload}

    case APPLY_FILTER:
      state = initialState
      return {...state}

    case PRICE_FURNITURE: {
      return {...state, priceFurnitire: action.payload}}
    case PRICE_ARTIFICIAL:
      return {...state, priceArtificial: action.payload}
    case PRICE_NATURAL:
      return {...state, priceNatural: action.payload}
    case PRICE_ECO:
      return {...state, priceEco: action.payload}
    case PRICE_MECHANISMS:
      return {...state, priceMechanisms: action.payload}
    case PRICE_SUPPORTS:
      return {...state, priceSupports: action.payload}

    case ADD_COLOR:
      return {...state, color: [action.payload]}
    case ADD_COLOR_ARTIFICIAL:
      return {...state, colorArti: [action.payload]}
    case ADD_COLOR_NATURAL:
      return {...state, colorNatural: [action.payload]}
    case ADD_COLOR_ECO:
      return {...state, colorEco: [action.payload]}
    case SET_COLORS_SUPPORTS:
      return {...state, colorSupports : [action.payload]}

    case ADD_TYPE:
      if (_.contains(state.type, action.payload)) {
        state.type = _.without(state.type, action.payload);
        return {...state, type: state.type}
      } else {
        return {...state, type: [...state.type, action.payload]}
      }
    case ADD_TYPE_ARTIFICIAL:
      if (_.contains(state.typeArti, action.payload)) {
        state.typeArti = _.without(state.typeArti, action.payload);
        return {...state, typeArti: state.typeArti}
      } else {
        return {...state, typeArti: [...state.typeArti, action.payload]}
      }
    case ADD_MECHANISM_TYPE:
      if (_.contains(state.mechanismTypes, action.payload)) {
        state.mechanismTypes = _.without(state.mechanismTypes, action.payload);
        return {...state, mechanismTypes: state.mechanismTypes}
      } else {
        return {...state, mechanismTypes: [...state.mechanismTypes, action.payload]}
      }
    
    case SET_FUNTITURE_TYPE:
      return {...state, furnitureType : action.payload}

    case SET_DOMESTIC_OR_IMPORTED:
      return {...state, domesticOrImported : action.payload}
    case SET_METALLIC_OR_PLASTIC:
      return {...state, metallicOrPlastic : action.payload}

    case NEWS_SALE_FURNI:
      return {...state, newsSaleFurni: action.payload}
    case NEWS_SALE_ARTI:
      return {...state, newsSaleArti: action.payload}
    case NEWS_SALE_NATURAL:
      return {...state, newsSaleNatural: action.payload}

    case ADD_TAG:
      if (_.contains(state.selectedTags, action.payload)) {
       
        state.selectedTags = _.without(state.selectedTags, action.payload);
        return {...state, selectedTags: state.selectedTags}
      } else {
        return {...state, selectedTags: [...state.selectedTags, action.payload]}
      }

    default:
      return state;
  }
}
