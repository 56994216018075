import React from "react";
import { Link } from "react-router";

const GoodsDescription = ({ catalog }) => {
  const CatalogName = catalog.split("/")[2];
  const title = {
    furniture_fabrics: "Ткань для обивки мебели",
    artificial_leather: "Искусственная кожа для перетяжки мебели",
    natural_leather: "Кожа для мебели",
    grid: "Сетка для мебели",
    glue: "Клей для мебели",
    accessories: "Фурнитура для мягкой мебели",
    opora: "Опоры для мягкой мебели",
  };
  const text = {
    furniture_fabrics: (
      <div className="catalog__description index-text-wr">
        <h1>{title[CatalogName]}</h1>
        <p>
          Добро пожаловать в наш каталог тканей для мягкой мебели. В этом
          разделе можно выбрать и купить ткань обивки для дивана, материалы для
          перетяжки кресел, пуфов, стульев и других предметов интерьера.
        </p>
        <p>
          Все поставщики прошли строжайший отбор и ассортимент включает только
          самые качественные и современные позиции. Ткани уже у нас на складе —
          просто уточните у менеджера доступный на момент покупки остаток.
        </p>
        <h2>Обивочные ткани оптом и в розницу</h2>
        <p>
          Мы работаем и с оптом, и с розницей. Поэтому купить ткань для обивки
          мебели у нас могут как производители, так и частные покупатели,
          которые просто хотят продлить жизнь любимому креслу.
        </p>
        <p>
          А еще у нас есть:
          <ul>
            <span className="indented">
              <Link to="/goods/accessories">
                • аксессуары и комплектующие для мебели
              </Link>
            </span>
            <span className="indented">
              <Link to="/goods/natural_leather">
                • натуральная кожа для обивки
              </Link>
            </span>
            <span className="indented">
              <Link to="/goods/artificial_leather">• искусственная кожа</Link>
            </span>
            <span className="indented">
              <Link to="/goods/grid">• сетки</Link>
            </span>
            <span className="indented">
              <Link to="/goods/glue">• и многое другое.</Link>
            </span>
          </ul>
        </p>
      </div>
    ),
    artificial_leather: (
      <div className="catalog__description index-text-wr">
        <h1>{title[CatalogName]}</h1>
        <p>
          Этот раздел каталога посвящен искусственной коже. Если вы планируете
          обновить любимые предметы интерьера или занимаетесь производством,
          здесь вы можете выбрать и купить искусственную кожу для мебели:
          диванов, кресел, стульев и т.д..
        </p>
        <p>
          Качественная искусственная кожа имеет более привлекательную цену, чем
          натуральная, и при этом является не менее практичным и долговечным
          материалом. Мы тщательно отобрали поставщиков и включили в каталог
          только самые востребованные фактуры и расцветки. Все позиции всегда в
          наличии на нашем складе, ждать поставки не нужно.
        </p>
        <h2>Искусственная кожа для мебели оптом и в розницу</h2>
        <p>
          Для заказа доступны опт и розница — мы сотрудничаем и с частными
          лицами, и с производителями.
        </p>
        <p>
          А еще у нас есть:
          <ul>
            <span className="indented">
              <Link to="/goods/accessories">
                • аксессуары и комплектующие для мебели
              </Link>
            </span>
            <span className="indented">
              <Link to="/goods/natural_leather">
                • натуральная кожа для обивки мебели
              </Link>
            </span>
            <span className="indented">
              <Link to="/goods/furniture_fabrics">• ткани для перетяжки</Link>
            </span>
            <span className="indented">
              <Link to="/goods/grid">• сетки для мебели</Link>
            </span>
            <span className="indented">
              <Link to="/goods/glue">• и многое другое.</Link>
            </span>
          </ul>
        </p>
      </div>
    ),
    natural_leather: (
      <div className="catalog__description index-text-wr">
        <h1>{title[CatalogName]}</h1>
        <p>
          В этом разделе каталога представлена натуральная мебельная кожа. Это
          универсальный материал, который подойдет для перетяжки любых предметов
          интерьера: от диванов и кресел до стульев и табуретов.
        </p>
        <p>
          Обивка мебели кожей — это не только красиво, но и очень практично и
          долговечно. Мы особенно тщательно отбирали позиции для этого каталога,
          поэтому здесь представлена только самая качественная кожа для мебели
          актуальных цветов и фактур.
        </p>
        <h2>Натуральная мебельная кожа оптом и в розницу</h2>
        <p>
          У нас вы можете купить мебельную кожу в любых количествах: есть и опт,
          и розница. Только уточните у нашего менеджера доступный остаток на
          складе перед оформлением заказа.
        </p>
        <p>
          А еще у нас можно купить:
          <ul>
            <span className="indented">
              <Link to="/goods/accessories">
                • комплектующие и аксессуары для мебели
              </Link>
            </span>
            <span className="indented">
              <Link to="/goods/artificial_leather">
                • искусственная кожа для перетяжки мебели
              </Link>
            </span>
            <span className="indented">
              <Link to="/goods/furniture_fabrics">• ткани для обивки</Link>
            </span>
            <span className="indented">
              <Link to="/goods/grid">• сетки для мебели</Link>
            </span>
            <span className="indented">
              <Link to="/goods/glue">• и многое другое.</Link>
            </span>
          </ul>
        </p>
      </div>
    ),
    grid: (
      <div className="catalog__description index-text-wr">
        <h1>{title[CatalogName]}</h1>
        <p>
          В этом разделе каталога вы можете купить сетку для мебели. Мы
          сотрудничаем как с частными заказчиками, так и с производителями,
          поэтому возможны опт и розница. Доступные в наличии объемы лучше
          предварительно уточнить у нашего менеджера.
        </p>
        <p>
          Сетку часто используют в автомобильной промышленности (чехлы,
          подушки), а также для производства или ремонта предметов интерьера — в
          первую очередь, офисных стульев и кресел.
        </p>
        <p>
          Материал не впитывает влагу, не выцветает на солнце и устойчив к
          истиранию, поэтому очень часто сетка ПВХ применяется для уличной
          мебели.
        </p>
        <p>
          Что еще у нас можно купить:
          <ul>
            <span className="indented">
              <Link to="/goods/accessories">• комплектующие и аксессуары</Link>
            </span>
            <span className="indented">
              <Link to="/goods/natural_leather">
                • натуральная кожа для обивки
              </Link>
            </span>
            <span className="indented">
              <Link to="/goods/artificial_leather">
                • искусственная кожа для перетяжки
              </Link>
            </span>
            <span className="indented">
              <Link to="/goods/furniture_fabrics">• мебельные ткани</Link>
            </span>
            <span className="indented">
              <Link to="/goods/glue">• и многое другое.</Link>
            </span>
          </ul>
        </p>
      </div>
    ),
    glue: (
      <div className="catalog__description index-text-wr">
        <h1>{title[CatalogName]}</h1>
        <p>
          В этом разделе вы можете купить клей для мебели. Возможны опт и
          розница.
        </p>
        <p>
          Частным заказчикам клей может потребоваться, чтобы починить сломанный
          предмет интерьера. Ну а в производстве это и вовсе незаменимый
          продукт.
        </p>
        <h2>Мебельный клей оптом и в розницу</h2>
        <p>
          В нашем каталоге представлен клей для мебели от нескольких
          производителей и обладающий различными свойствами. Если вы точно
          знаете, какие характеристики вам нужны — воспользуйтесь фильтром,
          чтобы быстро найти то, что нужно. Если вам сложно сделать выбор,
          просто позвоните нам или напишите через форму обратной связи — наши
          менеджеры свяжутся с вами и проконсультируют по всем вопросам.
        </p>
        <p>
          Также у нас можно купить:
          <ul>
            <span className="indented">
              <Link to="/goods/accessories">
                • мебельные аксессуары и комплектующие
              </Link>
            </span>
            <span className="indented">
              <Link to="/goods/natural_leather">• кожа натуральная</Link>
            </span>
            <span className="indented">
              <Link to="/goods/artificial_leather">• кожа искусственная</Link>
            </span>
            <span className="indented">
              <Link to="/goods/furniture_fabrics">• обивочные ткани</Link>
            </span>
            <span className="indented">
              <Link to="/goods/grid">• и многое другое.</Link>
            </span>
          </ul>
        </p>
      </div>
    ),
    accessories: (
      <div className="catalog__description index-text-wr">
        <h1>{title[CatalogName]}</h1>
        <p>
          В этом разделе вы можете выбрать и купить фурнитуру для производства
          мягкой мебели мебели: нитки, опоры, клей, резинки. Эти товары также
          будут полезны тем, кому нужно отремонтировать вышедший из строя
          предмет интерьера.
        </p>
        <p>
          Работаем с частными лицами и производствами, так что объем вашего
          заказа может быть любым — опт или розница.
        </p>
        <p>
          К выбору комплектующих для мягкой мебели мы подошли не менее
          ответственно, чем к другим разделам каталога. В нашем ассортименте
          представлена продукция только проверенных поставщиков, в качестве
          которой мы уверены на 100%.
        </p>
        <h2>Мебельная фурнитура оптом и в розницу</h2>
        <p>
          А если у вас возникли вопросы или вы не уверены, какая именно
          фурнитура для мягкой мебели вам нужна, напишите нам или закажите
          звонок — менеджеры свяжутся с вами и ответят на все вопросы.
        </p>
        <p>
          Что еще у нас есть:
          <ul>
            <span className="indented">
              <Link to="/goods/natural_leather">
                • натуральная кожа для перетяжки
              </Link>
            </span>
            <span className="indented">
              <Link to="/goods/artificial_leather">
                • искусственная кожа для мебели
              </Link>
            </span>
            <span className="indented">
              <Link to="/goods/furniture_fabrics">
                • обивочные ткани для мебели
              </Link>
            </span>
            <span className="indented">
              <Link to="/goods/grid">• и многое другое.</Link>
            </span>
          </ul>
        </p>
      </div>
    ),
    opora: (
      <div className="catalog__description index-text-wr">
        <h1>{title[CatalogName]}</h1>
        <p>
          В этом разделе нашего каталога вы можете купить опоры для мебели оптом
          или в розницу.
        </p>
        <p>
          Ассортимент включает металлические, декоративные опоры. Есть
          регулируемые опоры для мебели. Товары всегда в наличии на складе,
          просто уточните необходимый объем у нашего менеджера при покупке.
        </p>
        <h2>Мебельные опоры оптом и в розницу</h2>
        <p>
          Если вы производитель мебели, то выбрать и купить опоры вам будет
          несложно. А если вы выбираете опоры для себя и сомневаетесь, подойдет
          ли вам модель из каталога и будет ли она лучшим для вас решением — вы
          можете заказать звонок нашего менеджера, который проконсультирует и
          поможет сделать выбор.
        </p>
        <p>
          Что еще у нас можно приобрести:
          <ul>
            <span className="indented">
              <Link to="/goods/natural_leather">
                • натуральная кожа для мебели
              </Link>
            </span>
            <span className="indented">
              <Link to="/goods/artificial_leather">• искусственная кожа</Link>
            </span>
            <span className="indented">
              <Link to="/goods/furniture_fabrics">
                • обивочные ткани для обивки
              </Link>
            </span>
            <span className="indented">
              <Link to="/goods/grid">• и многое другое.</Link>
            </span>
          </ul>
        </p>
      </div>
    ),
  };

  return text[CatalogName] || <div> </div>;
};

export default GoodsDescription;
