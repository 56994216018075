import React, { useRef, useEffect, useCallback, useState } from 'react'
import Header from '../../../containers/Header';
import Helmet from 'react-helmet';
import { Link } from 'react-router'
import './style.css';

export default function Index() {

    return (
        <div>
            <Helmet>
                <title>3D Конструктор онлайн</title>
            </Helmet>
 
       <div className="iframe-wrapper">
            <span className="iframe-description">Выберите модель дивана, затем выберите понравившуюся ткань, а далее наведите на область на диване и кликните по нему. При помощи своей мыши вы можете вращать диван как угодно и рассмотреть его с разных ракурсов. 
            <br /> <br />«Для удобной работы в конструкторе рекомендуем использовать планшеты или компьютеры».
            </span>
            <iframe src="https://webgl.elarbis.com/vektor_web/index.html?model=common" frameborder="0"  title="3d-mdel" className="iframe" ></iframe>
       </div>
        
        <div className="iframe-wrapper-mobile">
            <span className="description">
            3D-конструктор доступен только на десктопной версии сайта.
            </span>
            <br />
            <Link to="/">Вернуться на главную</Link>
        </div>
        </div>
    )
}