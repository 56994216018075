import {
  INITIAL_GOOD_ARTIFICIAL,
  CLEAR_GOOD
} from '../../constants/Goods'

export default function Artificial (state = [], active) {
  if (active.type === INITIAL_GOOD_ARTIFICIAL) {

    function shuffle (array) {
      let m = array.length, t, i;

      // While there remain elements to shuffle…
      while (m) {

        // Pick a remaining element…
        i = Math.floor(Math.random() * m--);

        // And swap it with the current element.
        t = array[m];
        array[m] = array[i];
        array[i] = t;
      }

      return array;
    }

    let arrIndex = 0;
    let arr = shuffle([0, 1, 2]);

    const payload = active.payload.reduce((p, item, index) => {
      p.push(item);
      if ((index % 6 === 0) && (index > 0) && arrIndex < 3) {
        p.push({type: 'advertising', idx: arr[arrIndex]});
        arrIndex++;
      }
      return p;
    }, []);
    return payload;
  } else if (active.type === CLEAR_GOOD) {
    return [];
  }
  return state;
}