export const URL_GOODS_MAP = {
  cloth: 'furniture_fabrics',
  leatherette: 'artificial_leather',
  leather: 'natural_leather',
  grid: 'grid',
  eco_leather: 'eco_leather',
  transformation_mechanism : 'transformation_mechanisms',
  furniture_support : 'furniture_supports'
};

export const URL_GOOD_GENERATOR = (item) => {
  item._url = `/goods/${URL_GOODS_MAP[item.type]}/${item.attributes.name.replace(/\s/g, '-')}_${item.id}`
};
