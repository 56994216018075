import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import { addMechanisms, statusSearch } from '../../actions/filter/filter'

import Filter from './../../containers/Filter'
import { MechanismsHOC } from './../../composed/composedMechanisms'

const Mechanisms = ({ initialMechanisms }) => {
   return (
      <div>
         <Helmet>
            <title>Купить механизмы трансформации для мебели, каталог с ценами - компания "Вектор"</title>
            <meta
               name='description'
               content='Механизмы трансформации для мебели от компании Вектор — лучшие цены и именитые производители. Покупайте механизмы трансформации для мебели оптом с доставкой по России.'
            />
            <link rel='canonical' href='https://vektor.club/goods/transformation_mechanisms' />
         </Helmet>
         <Filter
            filter={['price', 'furnitureType', 'mechanismType', 'tags', 'domesticOrImported']}
            main='Mechanisms'
            box={initialMechanisms}
         />
      </div>
   )
}

export default connect(
   (state) => ({
      priceMechanisms: state.filter.priceMechanisms,
      initialMechanisms: state.Mechanisms,
   }),
   (dispatch) => ({
      initialGoods: (good) => dispatch(addMechanisms(good)),
      statusFound: (value) => dispatch(statusSearch(value)),
   })
)(MechanismsHOC(Mechanisms))
