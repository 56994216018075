import React from 'react'
import { search } from '../actions/filter/search.js'

export const GridHOC = ComposedComponent => class extends React.Component {
  static displayName = 'ComponentGridHOC';

  componentDidMount () {
  if (this.props.Grid.length === 0)
      setTimeout(() => {search(`/grids`, this.props.addGrid, this.props)}, 10)
  }

  render () {
    return <ComposedComponent {...this.props} {...this.state} />;
  }
}
