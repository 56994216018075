import {
  ADD_POST,
  ADD_POST_DETAIL
} from '../constants/Posts'

export default function posts (state = [], action) {
  if (action.type === ADD_POST) {
    return action.payload
  } else if (action.type === ADD_POST_DETAIL) {
    state = [];
    return [...state, action.payload];
  }
  return state;
}
