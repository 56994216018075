import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'
import Header from './Header'
import Search from './Search/Search'
import MobileMenu from './MobileMenu'
import Favorites from './Favorites'
import Aside from './Aside'
import Footer from './Footer'
import PopupForm from './Form/Popup'
import PopupPhone from './PopupPhone'
import PopupCity from './PopupCity'
import { Helmet } from 'react-helmet'
import Helper from './../helper'

class AppContainer extends Component {
   constructor(props) {
      super(props)
      this.state = {
         searchOpened: false,
      }
   }

   toggleSearchModal() {
      this.setState({ searchOpened: !this.state.searchOpened })
      Helper.toggleBodyScroll()
   }

   render() {
      return (
         <div className='App'>
            <div className='result-filter-loader'></div>
            <Header {...this.state} open={this.toggleSearchModal.bind(this)} />
            <MobileMenu />
            <Search opened={this.state.searchOpened} close={this.toggleSearchModal.bind(this)} />
            <Favorites />
            <div className='content'>
               <Container>
                  <Row>
                     <Col xs='3' className='col-xs-1-5'>
                        <Aside location={window.location} />
                     </Col>
                     <Col xs='9' className='col-xs-1-4 main-wrap'>
                        {this.props.children.props.route.componentName ? (
                           <Helmet>
                              <title>{this.props.children.props.route.componentName} — Вектор</title>
                           </Helmet>
                        ) : (
                           this.props.children.props.children &&
                           this.props.children.props.children.props.route.componentName && (
                              <Helmet>
                                 <title>{this.props.children.props.children.props.route.componentName} — Вектор</title>
                              </Helmet>
                           )
                        )}
                        {this.props.children}
                        <Footer />
                     </Col>
                  </Row>
               </Container>
            </div>
            <PopupForm />
            <PopupPhone />
            <PopupCity />
         </div>
      )
   }
}

export default connect(
   (state, ownProps) => ({
      testStore: state,
      browserHistory,
   }),
   (dispatch) => ({})
)(AppContainer)
