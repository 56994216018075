import React from 'react'

import {search} from "../actions/filter/search";

export const SaleHOC = ComposedComponent => class extends React.Component {
  static displayName = 'ComponentSaleHOC';

  componentDidMount () {
    if (this.props.testStore.globalSale.length === 0)
      search('/get_sale_collections', this.props.addGlobalSaleGood, this.props);
  }

  render () {
    return <ComposedComponent {...this.props} {...this.state} />;
  }
}
