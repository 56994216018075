const initialState = {locationBeforeTransitions: null}
export default function Local (state = initialState, action) {
  if (action.type === '@@router/LOCATION_CHANGE')
    if (action.payload.pathname === '/')
      setTimeout(function () {
        document.querySelector('.App').classList.add('index-page')
        document.querySelector('aside').classList.add('active')
      }, 10)
    else
      setTimeout(function () {
        if (document.querySelector('.App'))
          document.querySelector('.App').classList.remove('index-page')
      }, 10)
  return state
}