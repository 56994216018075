import React from 'react'
import { Helmet } from 'react-helmet'

const ErrorPage = () => (
  <div className="wrapper-404">
    <div className="block-404">
      <img src="/img/jpg/404.jpg" alt=""/>
      <div className="block-404-text">
        <p className="title-404">Страница не найдена</p>
        <p>Неправильно набран адрес, или такой страницы на сайте больше не существует. <a href="/">Перейдите на главную
          страницу.</a></p>
        <span>ООО «Вектор»</span>
      </div>
    </div>
    <Helmet>
      <meta name="prerender-status-code" content="404"/>
    </Helmet>
  </div>
)

export default ErrorPage
