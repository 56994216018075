import React, {Component} from 'react'
import { Helmet } from 'react-helmet'
import Search from "../../containers/Search/Search";
import {Row} from "reactstrap";
import Item from "../../containers/GoodItem/Item";
import {connect} from "react-redux";


class SearchResults extends Component {

  constructor (props) {
    super(props);
    this.state = {
      results: [],
      conditionText: null
    };
  }

  componentDidMount() {
    this.refs.searchInputRef.value = this.props.params.queryText || '';

    this.search();
  }

  componentDidUpdate() {
    setTimeout( () => {
      this.refs.searchInputRef.focus();
    }, 100);
  }

  search(e = null) {
    if (null !== e) e.preventDefault();
    Search.searchByWord(this.getSearchValue(), this.setSearchState.bind(this));
  }

  getSearchValue() {
    return this.refs.searchInputRef.value.trim();
  }

  setSearchState(condText, results = [], regexp = null) {
    this.setState({
      conditionText: condText,
      results: results,
      searchRegexp: regexp
    });
  }

  render() {

    //TODO: duplicate code
    // маппинг коллекций для получения массива их id
    let idArr = [];
    this.props.testStore.favorites.collections.map((el) => {
      return idArr.push(el.id)
    });

    return (
      <div>

        <Helmet>
          <title>Результаты поиска</title>
          <meta name="description" content=""/>
        </Helmet>

        <div className="search-on-results">
          <form className="input" onSubmit={this.search.bind(this)}>
            <div className="before"/>
            <input type="text" ref="searchInputRef"/>
            <div className="after"/>
          </form>
          <span className="search-condition">{ this.state.conditionText || 'Введите запрос' }</span>
        </div>

        <Row className="catalog-block row">
          { this.state.results.map( item =>
            <Item
              item={item}
              key={item.id}
              favorite={idArr.indexOf(item.id) !== -1}
              main={this.props.main}
              {...this.props}/>
          )}
        </Row>

      </div>
    )
  }
}


export default connect(
  state => ({
    testStore: state
  }),
)(SearchResults)