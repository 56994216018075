import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import {
  addFurnitureSupports,
  statusSearch,
} from "../../actions/filter/filter";

import Filter from "../../containers/Filter";
import { FurnitureSupportsHOC } from "../../composed/composedFurnitureSupports.js";

const FurnitureSupports = ({ initialSupports }) => {
  // TODO: Вот это будем наполнять контентом
  return (
    <div>
      <Helmet>
        <title>
          Купить регулируемые опоры для мебели, каталог с ценами - компания
          "Вектор"
        </title>
        <meta
          name="description"
          content="Опоры для мебели от компании Вектор — лучшие цены и именитые производители. Опт и доставка по России."
        />
        <meta
          name="keywords"
          content="опоры для мебели, опоры хром, опоры пластик, мебельные опоры, фурнитура для трансформации мебели, механизмы для трансформации мебели"
        />
        <link rel="canonical" href="https://vektor.club/goods/opora" />
      </Helmet>
      <Filter
        filter={["price", "metallicOrPlastic", "color", "tags", "domesticOrImported"]}
        main="FurnitureSupports"
        box={initialSupports}
      />
    </div>
  );
};

export default connect(
  (state) => ({
    priceSupports: state.filter.priceSupports,
    initialSupports: state.Supports,
  }),
  (dispatch) => ({
    initialGoods: (good) => dispatch(addFurnitureSupports(good)),
    statusFound: (value) => dispatch(statusSearch(value)),
  })
)(FurnitureSupportsHOC(FurnitureSupports));
