import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import newsGoods from './newsGoods'
import posts from './posts'
import Local from './Local'
import FurnitureFabrics from './goods/GoodsFurnitureFabrics'
import Artificial from './goods/Artificial'
import Natural from './goods/Natural'
// import Eco from './goods/Eco'
import glue from './glue'
import Grid from './goods/Grid'
import news from './news'
import city from './city'
import favorites from './favorites'
import filter from './filter'
import found from './found'
import Mechanisms from './transformationMechanisms'
import Supports from './furnitureSupports'
import detailGoods from './detailGoods'
import Visited from './visited'
import globalNews from './goods/GlobalNews'
import globalSale from './goods/GlobalSale'

export default combineReducers({
  routing: routerReducer,
  Local,
  newsGoods,
  posts,
  FurnitureFabrics,
  Artificial,
  Natural,
  // Eco,
  Grid,
  glue,
  globalNews,
  globalSale,
  news,
  city,
  favorites,
  filter,
  found,
  detailGoods,
  Visited,
  Mechanisms,
  Supports
})
