import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { syncHistoryWithStore } from 'react-router-redux'
import { browserHistory } from 'react-router'
import Reducers from '../reducers/Reducers'
import thunk from 'redux-thunk'

export default function configureStore () {
  let store = createStore(Reducers, composeWithDevTools(applyMiddleware(thunk)));
  syncHistoryWithStore(browserHistory, store);

  return store
}