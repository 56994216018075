import React from 'react'
import { search } from '../actions/filter/search.js'

export const ArtificialHOC = ComposedComponent => class extends React.Component {
  static displayName = 'ComponentArtificialHOC';

  componentDidMount () {

    if (this.props.Artificial.length === 0) {
      search(`/leatherettes`, this.props.addArtifitial, this.props)
    }
  }

  render () {
    return <ComposedComponent {...this.props} {...this.state} />;
  }
}