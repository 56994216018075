import axios from 'axios'
import { REMOTE_SERVER_URL } from './remoteServerUrl'

export const staticStore = REMOTE_SERVER_URL + '/static/'

const config = {
   baseURL: REMOTE_SERVER_URL,
   headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST,GET,PUT,DELETE',
      'Access-Control-Allow-Headers': 'Authorization, Lang',
   },
}

export default class RemoteServer {
   static get(path) {
      return axios.get(path, config).catch((err) => console.error('remoteServer ERR', err))
   }

   static post(path, data) {
      return axios.post(path, data, config)
   }
}
