import React from 'react'
import { search } from '../actions/filter/search.js'

export var EcoHOC = ComposedComponent => class extends React.Component {
	static displayName = 'ComponentEcoHOC';
	componentDidMount() {
		if (this.props.testStore.Eco.length === 0)
			setTimeout(() => {search(`/ecoleathers`, this.props.addEco, this.props)},10)
	}
	render() {
		return <ComposedComponent {...this.props} {...this.state} />;
	}
}