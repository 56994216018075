import React from 'react'
import scroll from 'animated-scroll-to'

class PDP extends React.Component {

  componentDidMount () {

    // скролл вверх страницы при инициализации
    scroll(0, {speed: 800})
  }

  render () {

    return (

      <div className="pdp-wr">
        <h1 className="h1">Согласие на обработку персональных данных</h1>
        <div className="pdp-content">
          <div className="detail-text">
            <p>
              Предоставляя свои персональные данные в анкете добровольно, я автоматически даю согласие обществу с
              ограниченной
              ответственностью «ВЕКТОР» на обработку своих персональных данных, включая их трансграничную передачу в
              соответствии
              с действующим законодательством Российской Федерации.
              Подписывая анкету, я даю Согласие на обработку своих персональных данных со следующими условиями:
            </p>

            <p>1. Согласие дается на обработку моих персональных данных, как без использования средств автоматизации,
              так и
              с их
              использованием.</p>

            <p>2. Согласие дается на обработку моих персональных данных, таких как: имя, адрес электронной почты,
              контактный
              телефон.</p>

            <p>3. Основанием для обработки персональных данных является статья 24 Конституции Российской Федерации и
              статья
              6
              Федерального закона № 152-ФЗ 27.07.2006 «О персональных данных», иные действующие федеральные законы и
              нормативно
              правовые акты РФ.</p>

            <p>4. Действия (операции) с моими персональными данными включают в себя: сбор, запись, систематизацию,
              накопление,
              хранение, уточнение (обновление, изменение), извлечение, использование, передача (распространение,
              предоставление,
              доступ), обезличивание, блокирование, удаление, уничтожение.</p>

            <p>5. Полученные данные ООО «ВЕКТОР» обязуется использовать для обратной связи, оформления и доставки
              заказа.</p>

            <p>6. ООО «ВЕКТОР» гарантирует, что хранение персональных данных будет осуществляться согласно требованиям
              Федерального
              закона № 152-ФЗ 27.07.2006 года «О персональных данных».</p>

            <p>7. Я ознакомлен с тем, что данное согласие может быть отозвано мной либо моим законным представителем
              путем
              направления письменного заявления на адрес электронной почты ООО «ВЕКТОР» info@vektor.club или на адрес
              местонахождения: 143005, Российская Федерация, МО, г. Одинцово, ул. Луговая, д.10.</p>

            <p>Настоящее согласие действует все время до момента прекращения обработки персональных данных, указанных в
              настоящем
              согласии.</p>
          </div>
        </div>
      </div>
    )
  }

}

export default PDP;
