import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router"; // goods/

const About = () => (
  <div className="detail-page-post">
    <Helmet>
      <link rel="canonical" href="https://vektor.club/about" />
    </Helmet>

    <div className="detail-page-post-title">
      <h1 className="h1">О компании</h1>
    </div>
    <div className="detail-page-post-content">
      <div className="detail-text">
        <p>
          Компания «Вектор» была основана в 2008 году, молодой и энергичной
          командой. За этот непродолжительный период времени мы наработали
          немалый опыт на мебельном рынке России, что позволяет нам отслеживать
          и предугадывать модные тенденции в мебельной индустрии.
        </p>
      </div>
      <div className="detail-page-post-pich">
        <img src="/img/jpg/warehouse.jpg" alt="" />
        <p className="alt">Наш склад в Одинцово</p>
      </div>
      <p className="description-text">
        Благодаря тщательному отбору поставщиков мы можем предложить нашим
        партнерам материалы высочайшего качества по наиболее привлекательным
        ценам.
      </p>
      <div className="detail-text">
        <p>
          Мы постоянно ведем работу по своевременному обновлению наших
          коллекций.
        </p>
        <p>
          Линейка предлагаемой нами продукции включает{" "}
          <Link to="/goods/furniture_fabrics">мебельные ткани</Link>,
          <Link to="/goods/natural_leather">натуральную</Link> и{" "}
          <Link to="/goods/artificial_leather">искусственную кожу</Link>,{" "}
          <Link to="/goods/eco_leather">эко-кожу</Link>, так же сопутствующие{" "}
          <Link to="/goods/accessories">материалы</Link>, необходимые для
          производства мебели.
        </p>
        <p>
          В настоящее время у компании «Вектор» открыты собственные филиалы в
          городах Москва, Ростов-на-Дону и Краснодар. Мы постоянно расширяем
          дилерскую сеть по регионам России и становимся еще более доступными
          для производителей мебели на всей территории Российской Федерации.
        </p>
        <p>
          <Link to="/sout-docs">Документы по СОУТ</Link>
        </p>
      </div>
    </div>
  </div>
);

export default About;
