import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router";
import scroll from "animated-scroll-to";
import { statusSearch, apply } from "../../actions/filter/filter";
import RemoteServer, { staticStore } from "../../remoteServer";
// import FileDownload from 'react-file-download'
// import axios from 'axios'

const getPriceLinkUrl = "/price.xlsx";
const getRemaindersLinkUrl = "/remainders.xlsx";
let pricelistLink;
let remainderslistLink;

var today = new Date();
const redText = { color: "red" };

class Aside extends Component {
  // фиксирование компонента Aside при скролле вниз
  asideScroll() {
    window.onscroll = function () {
      const h = this.pageYOffset,
        aside = document.querySelector("aside");

      // позиционирование дивана на главной странице (компонент Home) относительно высоты экрана
      if (document.querySelector(".index-text-pich")) {
        let backGroundPos = 370 - h * 0.24;
        backGroundPos > 5 &&
          (document.querySelector(".index-text-pich").style.cssText =
            "background-position:" + backGroundPos + "px");
      }

      aside.classList.toggle("aside-fixed", h > 65);
      aside.classList.toggle("active", h < 700);
    };
  }

  // скролл вверх страницы, изменение цвета у элементов навигации
  handleLogoClick() {
    scroll(0, { speed: 400 });
  }

  componentDidMount() {
    RemoteServer.get(getPriceLinkUrl)
      .then((response) => {
        if (response.status === 200) {
          pricelistLink = response.data;
          this.forceUpdate();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.city !== this.props.city) {
      RemoteServer.get(`${getRemaindersLinkUrl}?city=${this.props.city}`)
        .then((response) => {
          if (response.status === 200) {
            remainderslistLink = response.data;
            this.forceUpdate();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  render() {
    const linkOptions = {
      activeClassName: "active",

      // (!) значение testStore.found у redux-объекта привязано ко всем запросам фильтра (компонент Filter)
      // изменение цвета у элементов навигации, перезаполнение данными redux-объекта (при testStore.found === 'NotFound'), скролл вверх страницы
      onClick: () => {
        if (this.props.testStore.found === "NotFound") {
          this.props.statusFound("");
          this.props.apply();
        }

        scroll(0, { speed: 800 });
      },

      // добавление/удаление стилей элементу компонента Aside
      onMouseOver: (e) => {
        document.querySelector("aside ul li a.active") &&
          e.target !== document.querySelector("aside ul li a.active") &&
          document
            .querySelector("aside ul li a.active")
            .classList.add("pseudo-before");
      },
      onMouseOut: (e) => {
        document.querySelector("aside ul li a.active") &&
          e.target !== document.querySelector("aside ul li a.active") &&
          document
            .querySelector("aside ul li a.active")
            .classList.remove("pseudo-before");
      },
    };

    const prevLocation =
      this.props.testStore.routing.locationBeforeTransitions.pathname;

    return (
      <aside onLoad={this.asideScroll.bind(this)}>
        <div className={"logo" + (prevLocation === "/" ? " cursor-no" : "")}>
          <Link to="/" onClick={this.handleLogoClick.bind(this)}>
            <img src="/img/svg/logo-vektor-sm.svg" alt="" />
          </Link>
        </div>
        <p>Каталог</p>
        <ul>
          <li>
            <Link {...linkOptions} to="/goods/furniture_fabrics">
              Мебельные ткани
            </Link>
          </li>
          <li>
            <Link {...linkOptions} to="/goods/artificial_leather">
              Искусственная кожа
            </Link>
          </li>
          <li>
            <Link {...linkOptions} to="/goods/natural_leather">
              Натуральная кожа
            </Link>
          </li>
          <li>
            <Link {...linkOptions} to="/goods/grid">
              Сетка
            </Link>
          </li>
          {/*<li><Link {...linkOptions} to="/goods/eco_leather">Экокожа</Link></li>*/}
          <li>
            <Link {...linkOptions} to="/goods/glue">
              Клей
            </Link>
          </li>
          <li>
            <Link {...linkOptions} to="/goods/opora">
              Опоры для мебели
            </Link>
          </li>
          <li>
            <Link {...linkOptions} to="/goods/accessories">
              Комплектующие
            </Link>
          </li>
          <li>
            <Link {...linkOptions} to="/goods/sale">
              Распродажа
            </Link>
          </li>
          <li>
            <Link {...linkOptions} to="/goods/3d">
              <div style={{ position: "relative", bottom: 5 }}>
                <span style={redText}>3D </span>Конструктор{" "}
                <sup style={redText}>NEW</sup>
              </div>
            </Link>
          </li>
        </ul>
        {pricelistLink && (
          <a href={staticStore + pricelistLink}>
            Прайс-лист<span>.xls</span>
          </a>
        )}
        {remainderslistLink && (
          <a
            className="remainders-link"
            href={staticStore + remainderslistLink}
          >
            Остатки на {today.toLocaleDateString()}
            <span>.xlsx</span>
          </a>
        )}
        <span className="specify-link">Уточняйте наличие у менеджера</span>
      </aside>
    );
  }
}

export default connect(
  (state) => ({
    testStore: state,
    city: state.city.id,
  }),
  (dispatch) => ({
    statusFound: (value) => {
      dispatch(statusSearch(value));
    },
    apply: () => {
      dispatch(apply());
    },
  })
)(Aside);
