export const DRY_RESIDUE = 'DRY_RESIDUE'
export const VISCOSITY = 'VISCOSITY'
export const COLOR = 'COLOR'
export const FLAMMABILITY = 'FLAMMABILITY'
export const APPLY_FILTER = 'APPLY_FILTER'

export const PRICE_FURNITURE = 'PRICE_FURNITURE'
export const PRICE_ARTIFICIAL = 'PRICE_ARTIFICIAL'
export const PRICE_NATURAL = 'PRICE_NATURAL'
export const PRICE_ECO = 'PRICE_ECO'
export const PRICE_MECHANISMS = 'PRICE_MECHANISMS'
export const PRICE_SUPPORTS = 'PRICE_SUPPORTS'

export const ADD_COLOR = 'ADD_COLOR'
export const ADD_COLOR_ARTIFICIAL = 'ADD_COLOR_ARTIFICIAL'
export const ADD_COLOR_NATURAL = 'ADD_COLOR_NATURAL'
export const ADD_COLOR_ECO = 'ADD_COLOR_ECO'
export const SET_COLORS_SUPPORTS = 'SET_COLORS_SUPPORTS'
export const ADD_TYPE = 'ADD_TYPE'
export const ADD_TYPE_ARTIFICIAL = 'ADD_TYPE_ARTIFICIAL'
export const ADD_MECHANISM_TYPE = 'ADD_MECHANISM_TYPE'

export const NEWS_SALE_FURNI = 'NEWS_SALE_FURNI'
export const NEWS_SALE_ARTI = 'NEWS_SALE_ARTI'
export const NEWS_SALE_NATURAL = 'NEWS_SALE_NATURAL'

export const SET_FUNTITURE_TYPE = 'SET_FUNTITURE_TYPE'
export const SET_DOMESTIC_OR_IMPORTED = 'SET_DOMESTIC_OR_IMPORTED'
export const SET_METALLIC_OR_PLASTIC = 'SET_METALLIC_OR_PLASTIC'
export const ADD_TAG = 'ADD_TAG'


