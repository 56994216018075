import React from "react";
import { Helmet } from "react-helmet";

const SoutDocs = () => (
  <div className="detail-page-post">
    <Helmet>
      <link rel="canonical" href="https://vektor.club/sout-docs" />
    </Helmet>

    <div className="detail-page-post-title">
      <h1 className="h1">Документы по СОУТ</h1>
    </div>
    <div className="detail-page-post-content">
      <div className="detail-text">
        <p>
          <a href="/docs/Сводная ведомость (Москва).pdf" target="_blank" rel="noopener noreferrer">Сводная ведомость (Москва)</a> <br />
          <a href="/docs/Сводная ведомость (Ростов).pdf" target="_blank" rel="noopener noreferrer">Сводная ведомость (Ростов)</a> <br />
          <a href="/docs/Сводная ведомость (Краснодар).pdf" target="_blank" rel="noopener noreferrer">Сводная ведомость (Краснодар)</a> <br />
          <a href="/docs/Перечень реком. по улучш. усл. труда (Москва).pdf" target="_blank" rel="noopener noreferrer">Перечень реком. по улучш. усл. труда (Москва)</a> <br />
          <a href="/docs/Перечень реком. по улучш. усл. труда (Ростов).pdf" target="_blank" rel="noopener noreferrer">Перечень реком. по улучш. усл. труда (Ростов)</a> <br />
          <a href="/docs/Перечень реком. по улучш. усл. труда (Краснодар).pdf" target="_blank" rel="noopener noreferrer">Перечень реком. по улучш. усл. труда (Краснодар)</a>
        </p>
      </div>
    </div>
  </div>
);

export default SoutDocs;