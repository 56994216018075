import {
   SET_FUNTITURE_TYPE,
   SET_DOMESTIC_OR_IMPORTED,
   PRICE_MECHANISMS,
   PRICE_SUPPORTS,
   SET_COLORS_SUPPORTS,
   ADD_MECHANISM_TYPE,
   SET_METALLIC_OR_PLASTIC,
   ADD_TAG
} from '../../constants/Filter'

import { ADD_FURNITURE_SUPPORT } from '../../constants/Goods'

export const statusSearch = (value) => (dispatch) => {
   dispatch({ type: 'STATUS_SEARCH', payload: value })
}

export const addFurnitire = (good) => (dispatch) => {
   dispatch({ type: 'INITIAL_GOOD', payload: good })
}
export const addLeather = (good) => (dispatch) => {
   dispatch({ type: 'INITIAL_GOOD_ARTIFICIAL', payload: good })
}
export const addMechanisms = (good) => (dispatch) => {
   dispatch({ type: 'INITIAL_MECHANISMS', payload: good })
}
export const addFurnitureSupports = (good) => (dispatch) => {
   dispatch({ type: ADD_FURNITURE_SUPPORT, payload: good })
}
export const addNatural = (good) => (dispatch) => {
   dispatch({ type: 'INITIAL_GOOD_NATURAL', payload: good })
}
export const addGrid = (good) => (dispatch) => {
   dispatch({ type: 'INITIAL_GOOD_GRID', payload: good })
}
export const addEco = (good) => (dispatch) => {
   dispatch({ type: 'ADD_GOOD_ECO', payload: good })
}
export const addGlue = (good) => (dispatch) => {
   dispatch({ type: 'INITIAL_GLUE', payload: good })
}

export const addDetailGood = (good) => (dispatch) => {
   dispatch({ type: 'INITIAL_DETAIL_GOOD', payload: good })
}

export const changeDryResidue = (value) => (dispatch) => {
   dispatch({ type: 'DRY_RESIDUE', payload: value })
}
export const changeViscosity = (value) => (dispatch) => {
   dispatch({ type: 'VISCOSITY', payload: value })
}
export const flamability = (item) => (dispatch) => {
   dispatch({ type: 'FLAMMABILITY', payload: item })
}

export const apply = () => (dispatch) => {
   dispatch({ type: 'APPLY_FILTER', payload: apply })
}

export const initVisited = (item) => (dispatch) => {
   dispatch({ type: 'VISITED', payload: item })
}

export const changePrice = (value) => (dispatch) => {
   dispatch({ type: 'PRICE_FURNITURE', payload: value })
}
export const changePriceArtificial = (value) => (dispatch) => {
   dispatch({ type: 'PRICE_ARTIFICIAL', payload: value })
}
export const changePriceNatiral = (value) => (dispatch) => {
   dispatch({ type: 'PRICE_NATURAL', payload: value })
}
export const changePriceEco = (value) => (dispatch) => {
   dispatch({ type: 'PRICE_ECO', payload: value })
}
export const changePriceMechanisms = (value) => (dispatch) => {
   dispatch({ type: PRICE_MECHANISMS, payload: value })
}
export const changePriceSupports = (value) => (dispatch) => {
   dispatch({ type: PRICE_SUPPORTS, payload: value })
}

export const addColor = (value) => (dispatch) => {
   dispatch({ type: 'ADD_COLOR', payload: value })
}
export const addColorArti = (value) => (dispatch) => {
   dispatch({ type: 'ADD_COLOR_ARTIFICIAL', payload: value })
}
export const addColorNaural = (value) => (dispatch) => {
   dispatch({ type: 'ADD_COLOR_NATURAL', payload: value })
}
export const addColorEco = (value) => (dispatch) => {
   dispatch({ type: 'ADD_COLOR_ECO', payload: value })
}
export const setColorSupports = (value) => (dispatch) => {
   dispatch({ type: SET_COLORS_SUPPORTS, payload: value })
}

export const addType = (value) => (dispatch) => {
   dispatch({ type: 'ADD_TYPE', payload: value })
}
export const addTypeArti = (value) => (dispatch) => {
   dispatch({ type: 'ADD_TYPE_ARTIFICIAL', payload: value })
}
export const addMechanismType = (value) => (dispatch) => {
   dispatch({ type: ADD_MECHANISM_TYPE, payload: value })
}

export const newsSaleFurni = (value) => (dispatch) => {
   dispatch({ type: 'NEWS_SALE_FURNI', payload: value })
}
export const newsSaleArti = (value) => (dispatch) => {
   dispatch({ type: 'NEWS_SALE_ARTI', payload: value })
}
export const newsSaleNatural = (value) => (dispatch) => {
   dispatch({ type: 'NEWS_SALE_NATURAL', payload: value })
}

export const setFurnitureType = (value) => (dispatch) => {
   dispatch({ type: SET_FUNTITURE_TYPE, payload: value })
}
export const setImportedOrDomestic = (value) => (dispatch) => {
   dispatch({ type: SET_DOMESTIC_OR_IMPORTED, payload: value })
}
export const setMetallicOrPlastic = (value) => (dispatch) => {
   dispatch({ type: SET_METALLIC_OR_PLASTIC, payload: value })
}

export const addGlobalNewsGood = (good) => (dispatch) => {
   dispatch({ type: 'ADD_GLOBAL_NEWS_GOODS', payload: good })
}
export const addGlobalSaleGood = (good) => (dispatch) => {
   dispatch({ type: 'ADD_GLOBAL_SALE_GOODS', payload: good })
}

export const addTag = (value) => (dispatch) => { 
    dispatch({ type: ADD_TAG, payload: value })
}
