import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { staticStore } from '../../remoteServer'


export class CollectionProps extends Component {

    constructor (props) {
        super(props)
        this.toggleOpen = this.toggleOpen.bind(this)
        this.close = this.close.bind(this)
        this.outsideClose = this.outsideClose.bind(this)
        this.modals = []
    }

    componentDidMount() {
        document.body.addEventListener('click', this.outsideClose);
    }

    close (e) {
        while (!e.target.classList.contains('close')) e.target = e.target.parentNode
        e.target.parentNode.classList.remove('active')
    }

    outsideClose (e) {
        var target = e.target

        while (
           !((target.classList.contains('collection-prop-header')) && (target.classList.contains('description')) )
         && (!target.classList.contains('prop-descr-modal'))
         && (target !== document.body)
        ) {
            if (!target.parentNode) return
            target = target.parentNode
        }

        if (target === document.body && document.querySelector('.prop-descr-modal.active')) {
            document.querySelector('.prop-descr-modal.active').classList.remove('active')
        }
    }

    toggleOpen (e) {

        while (!e.target.classList.contains('collection-prop-header')) e.target = e.target.parentNode
        var currentModal = e.target.parentNode.querySelector('.prop-descr-modal')

        document.querySelectorAll('.prop-descr-modal.active:not([data-index$="' + currentModal.dataset.index + '"])').forEach((element) => {
            element.classList.remove('active')
        })

        var modalHeight = currentModal.offsetHeight
        var targetBottomYCoord = e.target.getBoundingClientRect().top + e.target.offsetHeight
        var bottom = window.innerHeight

        if (!currentModal.classList.contains('active')) {
            if (bottom - targetBottomYCoord < modalHeight) {
                currentModal.classList.add('at-top')
            } else {
                currentModal.classList.remove('at-top')
            }
        }

        currentModal.classList.toggle('active')
    }

    render () {
        return (
            <Row className="collection-props">
                {this.props.data.map((icon, index) =>
                    <Col xs="6" key={index}>
                        <div className={"collection-prop-header" + (icon.attributes.description ? ' description' : '')} onClick={icon.attributes.description ? this.toggleOpen:undefined}>
                            <img src={staticStore + icon.attributes.image} alt=""/>
                            <p><span>{icon.attributes.name}</span></p>
                        </div>

                        {icon.attributes.description && <div className={"prop-descr-modal" + (index % 2 === 1 ? ' right-col':'')} data-index={index}>
                            <div className="close" onClick={this.close}></div>
                            <div className="prop-modal-header">
                                <img src={staticStore + icon.attributes.image} alt=""/>
                                <h4>{icon.attributes.name}</h4>
                            </div>
                            <p>{icon.attributes.description}</p>
                        </div>}
                    </Col>
                )}
                <div className="collection-description">
                  <p>{this.props.description}</p></div>
            </Row>
        )
    }
}
