import React, { Component } from 'react';
import Item from '../GoodItem/Item'
import { ItemGlue } from '../GoodItem/ItemGlue'
import Loading from 'react-loading'
import { Row, Col } from 'reactstrap'
import RandomBlockOne from '../../components/RandomBlock/RandomBlockOne'
import RandomBlockTwo from '../../components/RandomBlock/RandomBlockTwo'
import RandomBlockThree from '../../components/RandomBlock/RandomBlockThree'
// import RandomBlockFour from '../../components/RandomBlock/RandomBlockFour'
// import RandomBlockFive from '../../components/RandomBlock/RandomBlockFive'

const randoms = [RandomBlockOne, RandomBlockTwo, RandomBlockThree];

// показать описание (в компоненте Glue)
function showText (e) {
  e.currentTarget.classList.toggle('active')
  e.currentTarget.nextElementSibling.classList.toggle('active')
}

export class ResultFilter extends Component {

  componentDidMount () {

    // скрывание лоадера
    document.querySelector('.result-filter-loader') && (document.querySelector('.result-filter-loader').style.width = '0')
  }

  render () {


    //TODO: duplicate code
    // маппинг коллекций для получения массива их id
    let idArr = [];
    this.props.testStore.favorites.collections.map((el) => {
      return idArr.push(el.id)
    })

    if (this.props.testStore.found !== 'NotFound') {
      return (
        <div>
          {this.props.testStore.found === 'Expectation' || this.props.testStore.found === undefined ?
            <div className="loader">
              {/*<Loading type='spinningBubbles' color='#d91c29'/>*/}
            </div> : <div></div>}
          {/*<div>loading true {this.props.box.length}</div> : <div>loading false {this.props.box.length}</div>*/}
          <div>
            {this.props.main !== 'Glue' ? (
              <Row className="catalog-block">
                {this.props.box && this.props.box.map((item) => {
                  if (item.type === 'advertising') {
                    const Random = randoms[item.idx];
                    return (
                      <Col className="item-c" xs="3" key={item.idx}>
                        <Random/>
                      </Col>
                    )
                  } else {
                    return <Item
                      item={item}
                      key={item.id}
                      favorite={idArr.indexOf(item.id) !== -1}
                      main={this.props.main}
                      {...this.props}/>
                  }
                })}
                <div style={{width: '100%'}}/>
                {/*{ showMoreButton ? <div className="arrow-button" onClick={this.props.moreItems}>показать ещё</div> : ""}*/}
              </Row>
            ) : (
              <Row className="catalog-block">
                {this.props.box.map((item) => {
                  return <ItemGlue key={item.id} item={item} {...this.props} click={showText}/>
                })}
              </Row>
            )}
          </div>
        </div>
      )
    } else if (this.props.testStore.found === 'Expectation' || this.props.testStore.found === undefined) {
      return <div className="loader"><Loading type='spinningBubbles' color='#d91c29'/></div>
      // return <div className='not-found-text'>Loading...</div>
    } else {
      return <div className='not-found-text'>Товары не найдены</div>
    }

    // if (this.props.testStore.found === 'NotFound') {
    //   return <div className='not-found-text'>Товары не найдены.</div>
    // }
    // else {
    //   // let showMoreButton = this.props.box.length !== 0 && this.props.box.length < this.props.count
    //   return (
    //     <div>
    //       {this.props.testStore.found === 'Expectation' || this.props.testStore.found === undefined ?
    //         <div className="loader"><Loading type='spinningBubbles' color='#d91c29'/></div> : <div></div>}
    //       <div>
    //         {this.props.main !== 'Glue' ? (
    //           <Row className="catalog-block">
    //             {this.props.box.map((item, index) => {
    //               if (item.type === 'advertising') {
    //                 const Random = randoms[item.idx];
    //                 return (
    //                   <Col className="item-c" xs="3" key={index}>
    //                     <Random/>
    //                   </Col>
    //                 )
    //               } else {
    //                 return <Item item={item} key={index} main={this.props.main}/>
    //               }
    //             })}
    //             <div style={{width: '100%'}}/>
    //             {/*{ showMoreButton ? <div className="arrow-button" onClick={this.props.moreItems}>показать ещё</div> : ""}*/}
    //           </Row>
    //         ) : (
    //           <Row className="catalog-block">
    //             {this.props.box.map((item, index) => {
    //               return <ItemGlue key={index} item={item} {...this.props} click={showText}/>
    //             })}
    //           </Row>
    //         )}
    //       </div>
    //     </div>
    //   )
    // }

  }
}